import { usePlacesWidget } from "react-google-autocomplete";

export default function useGoogleMapsAutocomplete(
  onPlaceSelected: (place: any) => void,
) {
  const handlePlaceSelected = (place: any) => {
    if (!place?.geometry) {
      // user pressed enter, thus we get {name: "text entered"}.
      // we want resolved place instances only
      return;
    }

    onPlaceSelected(place);
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAIl6aJDI8Mgyvg4bIIxgZh4ux2T-p6qfo",
    onPlaceSelected: handlePlaceSelected,
    options: {
      types: "(cities),address",
      componentRestrictions: { country: ["at", "de", "ch"] },
    },
  });

  return {
    ref,
  };
}
