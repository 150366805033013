import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const SearchArrowEnd = props => (
  <SvgIcon width={9.4} height={6.1} {...props}>
    <path data-name="Pfad 115" d="M4.7 6.1L0 1.4 1.4 0l3.3 3.3L8 0l1.4 1.4z" />
  </SvgIcon>
);

export default SearchArrowEnd;
