import { BcThemeOptions } from "../themeBase";

export const Borderherz: BcThemeOptions = {
  colors: {
    primary: "#c69f70",
    primaryLight: "#c69f70",
    primaryLightContrast: "#ffffff",
    primaryDark: "#c69f70",
    primaryContrast: "#fff",
    browserThemeColor: "#c69f70",
    bodyText: "#000000",
    indicatorProfile: "#DE5433",
    indicatorFavorite: "#DE5433",
    topBarBackground: "#c69f70",
    topBarContrast: "#ffffff",
    secondaryBackground: "#e4e4e4",
    background: "#ffffff",
    heroBackground: "#c69f70",
    heroText: "#ffffff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    banner: "/themes/borderherz.png",
    landing: "/themes/borderherz.png",
  },
  misc: {
    originUrl: "https://www.borderherz.de",
    landingPageIconHeight: 100,
    topBarIconHeight: 100,
    externalPrivacyLink: "https://www.borderherz.de/datenschutz/",
    externalImprintLink: "https://www.borderherz.de/impressum/",
    externalPartnerName: "Borderherz",
  },
};
