import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as EventActions from "../actions/events";
import { RootState } from "../reducers";

export default function useFavorites() {
  const favoritesState = useSelector((state: RootState) => {
    return state.favoritesState;
  });

  const favorites = favoritesState ? favoritesState.favorites : undefined;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!favorites && !favoritesState.loading && !favoritesState.failed) {
      dispatch(EventActions.getFavorites());
    }
  }, [favorites, dispatch, favoritesState.loading, favoritesState.failed]);

  return favorites;
}
