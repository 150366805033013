import React from "react";
import { Link } from "react-router-dom";

export const ThemeStyleOverrides = (themeProperties: any) => {
  return {
    overrides: overrides(themeProperties),
    props: defaultProps,
    typography: typography(themeProperties),
  };
};

const overrides = (themeProperties: any) => {
  return {
    MuiDialog: {
      root: {
        zIndex: "999 !important", // make sure the google autocomplete suggestions show on top of our dialogs (z-Index 1000)
      },
    },
    MuiLink: {
      root: {},
    },
    MuiCheckbox: {
      root: {
        color: themeProperties.palette.secondary.main,
        marginLeft: -10,
      },
      "&$checked": {
        color: themeProperties.palette.primary.main,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: themeProperties.palette.secondary.light,
        },
      },
    },
    MuiInput: {
      root: {
        backgroundColor: themeProperties.palette.secondary.light,
        color: themeProperties.palette.secondary.dark,
        fontSize: themeProperties.fontSizes.mediumFont,
        borderRadius: 4,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
        minHeight: 40,
        margin: 0,
      },
      input: {
        "&::placeholder": {
          fontSize: themeProperties.fontSizes.smallFont,
          color: "#c4c4c3",
        },
      },
    },
    MuiButton: {
      root: {
        height: 50,
        padding: "0 30px",
        borderRadius: 4,
        fontSize: themeProperties.fontSizes.mediumFont,
      },
      sizeSmall: {
        height: 30,
        fontSize: themeProperties.fontSizes.smallFont,
        paddingLeft: 7,
        paddingRight: 7,
        paddingTop: 6,
        paddingBottom: 6,
      },
      contained: {
        backgroundColor: themeProperties.palette.primary.main,
        color: themeProperties.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: themeProperties.palette.primary.dark,
          color: themeProperties.palette.primary.contrastText,
        },
        "&:disabled": {
          backgroundColor: themeProperties.palette.primary.light,
          color: themeProperties.palette.primary.contrastText,
        },
      },
      containedPrimary: {
        backgroundColor: themeProperties.palette.primary.main,
        color: themeProperties.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: themeProperties.palette.primary.dark,
          color: themeProperties.palette.primary.contrastText,
        },
        "&:disabled": {
          backgroundColor: themeProperties.palette.primary.light,
          color: themeProperties.palette.primary.contrastText,
        },
      },
      containedSecondary: {
        backgroundColor: themeProperties.palette.secondary.main,
        color: themeProperties.palette.secondary.contrastText,
        "&:hover": {
          backgroundColor: themeProperties.palette.secondary.light,
          color: themeProperties.palette.secondary.contrastText,
        },
      },
      outlined: {
        backgroundColor: "white",
        color: themeProperties.palette.primary.main,
        border: "1px solid",
        "&:hover": {
          backgroundColor: "transparent",
          color: themeProperties.palette.primary.dark,
        },
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: themeProperties.palette.secondary.light,
        boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",
      },
    },
    MuiDivider: {
      root: {
        color: themeProperties.separator,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#000000",
      },
    },
    MuiTab: {
      root: {
        textTransform: "uppercase",
        fontFamily: "Manrope",
        fontSize: themeProperties.fontSizes.smallFont,
        "&$selected": {
          fontWeight: "bold",
        },
      },
    },
  };
};

const typography = (themeProperties: any) => {
  return {
    fontSize: 16,
    fontFamily: "Manrope",
    button: {
      textTransform: "none",
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: themeProperties.fontSizes.mediumFont,
    },
    h1: {
      fontSize: "2.5rem", // 40px
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.875rem", // 30px
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.375rem", // 22px
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.125rem", // 18px
      fontWeight: "bold",
    },
    h5: {
      fontSize: "1rem", // 16px
      fontWeight: "bold",
    },
    h6: {
      fontSize: "0.875em", // 14px
      fontWeight: "bold",
    },
  };
};

const LinkBehavior = React.forwardRef<any, any>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <Link ref={ref} to={href} {...other} />;
});

const defaultProps = {
  MuiInput: {
    disableUnderline: true,
  },
  MuiButtonBase: {
    disableRipple: true,
    disableElevation: true,
    LinkComponent: LinkBehavior,
  },
  MuiButton: {
    variant: "contained",
    disableElevation: true,
    color: "primary",
  },
  MuiLink: {
    underline: "hover",
    component: LinkBehavior,
  },
  MuiCheckbox: {
    disableRipple: true,
  },
  MuiPopover: {
    elevation: 0,
  },
};
