import {
  Box,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import * as intl from "react-intl-universal";
import { RootState } from "../../reducers";
import * as EventActions from "../../actions/events";
import { Helmet } from "react-helmet";
import { getCanonicalUrl } from "../../utils/utils";
import HeroCarousel from "../../components/HeroCarousel";
import EventMediaCard from "../../components/EventMediaCard";
import { Event } from "../../model/event";
import EventTag from "../../components/EventTag";
import { useAppLocation } from "../../hooks/useAppLocation";
import { isMobile } from "react-device-detect";
import MobileToolbar from "../../components/MobileToolbar";
import { MuiBreakpoint, useMuiBreakpoint } from "../../hooks/useMuiBreakpoint";
import EventInfos from "../../components/eventdetails/eventInfos";
import useBrowserBack from "../../hooks/useBrowserBack";
import EventAge from "../../assets/icons/EventAge";
import EventDistance from "../../assets/icons/EventDistance";
import EventOpen from "../../assets/icons/EventOpen";
import EventDiscountCheckmark from "../../assets/icons/EventDiscountCheckmark";
import { useAppTheme } from "../../hooks/useAppTheme";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { isBot } from "../../hooks/useBotDetection";
import { AuthenticationHelper } from "../../utils/authenticationHelper";
import useAdSlots from "../../hooks/useMobileAdSlot";
import useMobileAdSlot from "../../hooks/useMobileAdSlot";
import useDesktopAdSlot from "../../hooks/useDesktopAdSlot";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: "#fff",
    },
    root: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 98,
      maxWidth: theme.maxWidth,
    },
    toolbar: {},
    backButton: {
      maxWidth: theme.maxWidth,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 50,
      paddingBottom: 29,
      paddingLeft: 20,
      paddingRight: 20,
      color: theme.bodyTextColor,
      fontSize: theme.fontSizes.smallFont,
      [theme.breakpoints.down("xs")]: {
        marginTop: 0,
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    content: {
      maxWidth: theme.maxWidth,
      marginLeft: "auto",
      marginRight: "auto",
      overflow: "hidden",
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    carouselContainer: {
      maxWidth: theme.maxWidth,
      paddingTop: isMobile ? 0 : 56,
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 0,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: isMobile ? 0 : 21,
        paddingBottom: 21,
      },
    },
    eventName: {
      paddingRight: 30,
    },
    eventSummary: {
      marginTop: 10,
      marginBottom: 29,
      paddingRight: 30,
      fontWeight: "normal",
    },
    eventDescription: {
      marginTop: 20,
      paddingRight: 85,
      paddingBottom: 20,
      fontSize: theme.fontSizes.mediumFont,
      [theme.breakpoints.down("sm")]: {
        paddingRight: 15,
      },
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
      },
    },
    infoBox: {
      fontSize: theme.fontSizes.smallFont,
      backgroundColor: theme.palette.secondary.light,
      paddingLeft: 21,
      paddingRight: 21,
      maxWidth: 280,
      minWidth: 280,
    },
    link: {
      color: theme.bodyTextColor,
      textDecoration: "underline",
      cursor: "pointer",
      maxWidth: "100%",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    tabBar: {
      borderBottom: theme.separator,
      marginTop: 20,
    },
  }),
);

export interface Props extends RouteComponentProps<void> {
  fromFavorites: Boolean;
}

export default function EventDetailsPage(props: Props) {
  const { match } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();

  const eventDetailState = useSelector((state: RootState) => {
    return state.eventDetailState;
  });

  const history = useHistory();
  const browserBack = useBrowserBack();
  const locationState = useAppLocation();
  const appTheme = useAppTheme();
  const event = eventDetailState.selectedEvent;

  const redirectToSlugUrlIfValid = () => {
    if (
      event == null ||
      event.company_slug === null ||
      event.company_slug === "" ||
      event.normalized_title === null ||
      event.normalized_title === ""
    ) {
      // invalid data, fallback to events page
      history.push(`/events` + appTheme.query);
    } else {
      history.replace(
        `/events/${event.company_slug}/${event.normalized_title}`,
      );
    }
  };

  useEffect(() => {
    const { params } = match;

    if (event && eventDetailState.shouldRedirect) {
      eventDetailState.shouldRedirect = false;
      redirectToSlugUrlIfValid();
      return;
    } else if (
      eventDetailState.failed === true &&
      eventDetailState.errorNotFound === true
    ) {
      // event not found, redirect to /events
      eventDetailState.errorNotFound = false;
      history.push(`/events` + appTheme.query);
      return;
    }

    /*if (!locationState.determined && locationState.permission != "prompt") {
      return;
    }*/

    if (event == null) {
      // @ts-ignore
      const eventIdOrSeo = params.id;
      // @ts-ignore
      const companySlug = params.company;
      // @ts-ignore
      const eventNormalizedTitle = params.title;

      if (eventIdOrSeo) {
        // legacy URL called or seo page
        dispatch(
          EventActions.loadEventById(
            eventIdOrSeo,
            history.location.pathname.indexOf("/favorites") !== -1,
          ),
        );
      } else if (companySlug && eventNormalizedTitle) {
        // new URL with comapny and normalized title
        if (eventDetailState.loading === false) {
          dispatch(
            EventActions.loadEventBySlugs(
              companySlug,
              eventNormalizedTitle,
              isBot(),
            ),
          );
        }
      } else {
        history.push(`/events` + appTheme.query);
      }
    } else {
      // @ts-ignore
      const eventIdOrSeo = params.id;
      // @ts-ignore
      const companySlug = params.company;
      // @ts-ignore
      const eventNormalizedTitle = params.title;

      if (
        companySlug &&
        eventNormalizedTitle &&
        event.company_slug === companySlug &&
        event.normalized_title === eventNormalizedTitle
      ) {
        // If the event matches, display data
      } else if (companySlug && eventNormalizedTitle) {
        if (eventDetailState.loading === false) {
          dispatch(
            EventActions.loadEventBySlugs(
              companySlug,
              eventNormalizedTitle,
              isBot(),
            ),
          );
        }
      } else if (eventIdOrSeo) {
        dispatch(
          EventActions.loadEventById(
            eventIdOrSeo,
            history.location.pathname.indexOf("/favorites") !== -1,
          ),
        );
      } else {
        history.push(`/events` + appTheme.query);
      }
    }
  }, [
    locationState.locationType,
    locationState.manualLocation,
    locationState.geoLocation,
    eventDetailState.selectedEvent,
    eventDetailState.shouldRedirect,
    eventDetailState.failed,
    eventDetailState.errorNotFound,
  ]);

  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });

  // Track page view
  React.useEffect(() => {
    if (event) {
      trackPageView({
        documentTitle: event.title,
        customDimensions: analyticsState.custom_dimensions,
      });
    }
  }, []);

  const onBackClicked = () => {
    browserBack("/events");
  };

  const muiBreakpoint = useMuiBreakpoint();
  const isSmall = muiBreakpoint <= MuiBreakpoint.sm;

  useDesktopAdSlot("div-ad-gds-4478-2");
  useDesktopAdSlot("div-ad-gds-4478-3");
  useMobileAdSlot("div-ad-gds-4479-1");

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <div className={classes.background}>
      {event && (
        <>
          <Helmet>
            <link rel="canonical" href={getCanonicalUrl()} />
            <title>
              {event.title +
                " | " +
                intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
            </title>
            <meta
              name="description"
              content={getMetaDescription(event)}
              data-react-helmet="true"
            />
          </Helmet>

          <Grid container className={classes.root} direction={"row"}>
            {isMobile && (
              <Grid item>
                <MobileToolbar
                  title={""}
                  onBackClicked={onBackClicked}
                  className={classes.toolbar}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.carouselContainer}>
                <ImageCarousel event={event} />
              </div>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} className={classes.backButton}>
                <div className={classes.link} onClick={onBackClicked}>
                  {intl.get("event.back_button")}
                </div>
              </Grid>
            )}

            {!isMobile && (
              <Grid item xs={12}>
                <div
                  id="div-ad-gds-4478-2"
                  style={{
                    marginTop: 16,
                    marginBottom: 16,
                    maxWidth: 728,
                    maxHeight: 90,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}></div>
              </Grid>
            )}

            <Grid item xs={12}>
              <Box
                display={{ sm: "flex", md: "none" }}
                flexDirection={"column"}
                className={classes.content}
                style={{ width: "100%" }}>
                <Typography variant={"h2"} className={classes.eventName}>
                  {event.title}
                </Typography>

                <Typography variant={"h3"} className={classes.eventSummary}>
                  {event.summary}
                </Typography>

                <EventTags event={event} />

                {isMobile && (
                  <Grid item xs={12}>
                    <div
                      id="div-ad-gds-4479-1"
                      style={{
                        marginTop: 16,
                        marginBottom: 16,
                        maxWidth: 300,
                        maxHeight: 600,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}></div>
                  </Grid>
                )}

                <Tabs
                  className={classes.tabBar}
                  orientation={"horizontal"}
                  variant={"fullWidth"}
                  value={selectedTabIndex}
                  style={{ flexGrow: 1 }}>
                  <Tab
                    label={intl.get("event.tab_description")}
                    value={0}
                    disabled={false}
                    selected={selectedTabIndex === 0}
                    onClick={() => setSelectedTabIndex(0)}
                  />
                  <Tab
                    label={intl.get("event.tab_info")}
                    value={1}
                    disabled={false}
                    selected={selectedTabIndex === 1}
                    onClick={() => setSelectedTabIndex(1)}
                  />
                </Tabs>

                <div hidden={selectedTabIndex !== 0} style={{ flexGrow: 1 }}>
                  <EventDescription event={event} classes={classes} />
                </div>
                <div hidden={selectedTabIndex !== 1} style={{ flexGrow: 1 }}>
                  <EventInfos event={event} use2ColumnLayout={isSmall} />
                </div>
              </Box>{" "}
            </Grid>

            <Grid item xs={12}>
              <Box
                display={{ xs: "none", sm: "none", md: "flex" }}
                className={classes.content}>
                <div style={{ flexGrow: 1 }}>
                  <Typography variant={"h2"} className={classes.eventName}>
                    {event.title}
                  </Typography>
                  <Typography variant={"h3"} className={classes.eventSummary}>
                    {event.summary}
                  </Typography>

                  <EventTags event={event} />

                  <EventDescription event={event} classes={classes} />
                </div>

                <div className={classes.infoBox}>
                  <EventInfos event={event} />
                </div>

                {!isMobile &&
                  !AuthenticationHelper.isRunningInBlogiFrame() &&
                  !AuthenticationHelper.isRunningInLegacyBlogiFrame() && (
                    <div
                      id="div-ad-gds-4478-3"
                      style={{
                        marginLeft: 64,
                        minWidth: 160,
                        maxWidth: 160,
                        maxHeight: 600,
                      }}></div>
                  )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

const EventTags = (props: any) => {
  const { event } = props;

  return (
    <Grid item xs={12}>
      <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
        {event.distance && (
          <EventTag
            text={Event.getDistanceString(event.distance)}
            image={EventDistance}
            imageStyle={{ marginTop: 2 }}
          />
        )}
        {event.is_discount_active && (
          <EventTag
            text={intl.get(`event.discount_available`)}
            image={EventDiscountCheckmark}
            imageStyle={{ marginTop: 2 }}
          />
        )}
        <EventTag
          text={Event.getAgeString(event)}
          image={EventAge}
          imageStyle={{ marginTop: 1 }}
        />
        {event.is_today_open && (
          <EventTag
            text={intl.get("event.status_open_today")}
            image={EventOpen}
          />
        )}
      </div>
    </Grid>
  );
};

const ImageCarousel = (props: any) => {
  const { event } = props;

  const mediaItems = event.media_items != null ? event.media_items : [];

  const muiBreakpoint = useMuiBreakpoint();
  const isSmall = muiBreakpoint <= MuiBreakpoint.md;

  if (mediaItems.length > 1) {
    return (
      <HeroCarousel
        items={mediaItems}
        autoPage
        numberOfItems={3}
        renderItem={(photo, isCenter) => (
          <EventMediaCard
            event={event}
            photo={{ ...photo, id: photo.public_id }}
            size={isSmall ? "fullWidth" : "standard"}
            style={isSmall ? { marginLeft: "auto", marginRight: "auto" } : {}}
            hideFavoriteIcon={!isCenter}
          />
        )}
      />
    );
  } else if (mediaItems.length === 1) {
    return (
      <Grid container justify={"center"}>
        <Grid item>
          <EventMediaCard
            event={event}
            photo={mediaItems[0]}
            size={isMobile ? "fullWidth" : "standard"}
            dropShadow
            style={{ marginLeft: "auto", marginRight: "auto" }}
          />
        </Grid>
      </Grid>
    );
  }
  return null;
};

const EventDescription = (props: any) => {
  const { classes, event } = props;

  return (
    <div className={classes.eventDescription}>
      {event.description.split("\n").map(function (item, idx) {
        return (
          <div key={"" + idx}>
            {item === "" && <br />}
            {item !== "" && <div>{item}</div>}
          </div>
        );
      })}
    </div>
  );
};

// returns a meta description string of max 160 characters length
function getMetaDescription(event: any): string {
  let maxSummaryLength = 160;
  let age: string;
  let price: string;
  let summary: string;

  // prepare string for age information
  if (event.age_from != null && event.age_to != null) {
    age = intl.get("seo.eventdetails.metadescription.age_template", event);
  } else if (event.age_from != null) {
    age = intl.get("seo.eventdetails.metadescription.age_from", event);
  } else {
    age = intl.get("seo.eventdetails.metadescription.age_to", event);
  }

  // prepare string for price information
  if (event.price_in_cents != null && event.price_in_cents > 0) {
    const priceComponent1 = Math.trunc(+event.price_in_cents / 100);
    let priceComponent2 = `${+event.price_in_cents - priceComponent1 * 100}`;
    if (priceComponent2.length === 1) {
      priceComponent2 = `0${priceComponent2}`;
    }
    price =
      intl.get("event.price_prefix") + priceComponent1 + "," + priceComponent2;
  } else {
    price = intl.get("seo.eventdetails.metadescription.free_event");
  }

  // add separators, update available character limit for summary
  if (age != null && age.length > 0) {
    age = " " + age;
    maxSummaryLength -= age.length;
  }

  if (price != null && price.length > 0) {
    price = " " + price;
    maxSummaryLength -= price.length;
  }

  // prepare character-limited summary string
  if (maxSummaryLength <= 1) {
    // no space to display summary at all
    // this shouldn't happen
    summary = "";
  } else if (
    event.summary != null &&
    maxSummaryLength > 1 &&
    event.summary.length > maxSummaryLength
  ) {
    // need to truncate the summary
    summary = event.summary.substring(0, maxSummaryLength - 1) + "…"; // -1 bc of ellipsis character
  } else {
    // we can display the full summary within our 160 character limt
    summary = event.summary;
  }

  return summary + age + price;
}
