import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";

import * as LocationActions from "../actions/location";
import useGeoLocationNew from "./useGeoLocationNew";
import { ManualLocation } from "../model/model";
import { useEffect, useRef } from "react";

export function useAppLocation() {
  const locationState = useSelector((state: RootState) => {
    return state.userLocationState;
  });

  const dispatch = useDispatch();
  const geoLocation = useGeoLocationNew();

  const hasValidLocation =
    locationState.selectedType &&
    ((locationState.selectedType === "manual" &&
      locationState.manualLocation &&
      locationState.manualLocation.longitude &&
      locationState.manualLocation.latitude) ||
      (locationState.selectedType === "geolocation" &&
        locationState.geoLocation &&
        locationState.geoLocation.longitude &&
        locationState.geoLocation.latitude));

  const hasHadValidLocation = useRef(hasValidLocation);
  hasHadValidLocation.current = hasHadValidLocation.current || hasValidLocation;

  useEffect(() => {
    if (
      locationState.selectedType === "geolocation" &&
      geoLocation.permission === "granted"
    ) {
      if (geoLocation.outdated) {
        geoLocation.fetchLocation(true, true);
      }
    }
  }, [
    geoLocation.permission,
    geoLocation.outdated,
    locationState.selectedType,
  ]);

  const setUseGeoLocation = () => {
    if (geoLocation.location) {
      dispatch(LocationActions.setGeoLocation(true, locationState.geoLocation));
    }

    // always refresh on button click
    // always force current (not cached location)
    geoLocation.fetchLocation(true, true);
  };

  const setManualLocation = (location: ManualLocation) => {
    dispatch(LocationActions.setManualLocation(true, location));
  };

  return {
    locationType: locationState.selectedType,
    location:
      locationState.selectedType === "geolocation"
        ? locationState.geoLocation
        : locationState.manualLocation,
    geoLocation,
    manualLocation: locationState.manualLocation,
    setManualLocation,
    setUseGeoLocation,
    hasValidLocation,
    hasHadValidLocation: hasHadValidLocation.current,
  };
}

/*
  useEffect(() => {
    if (isBot) {
      locationState.geolocationUnavailable = true;
      return;
    }

    let timeout = undefined;

    if (
      (locationState.determined && triggerRefresh === true) ||
      locationState.determined === false
    ) {
      dispatch(
        LocationActions.getUserLocation(
          locationState.determined,
          () => {
            timeout = setTimeout(() => {
              dispatch(LocationActions.failUserLocationDetermination());
            }, 50);
          },
          allowPermissionPrompt,
        ),
      );
    }
    return () => (timeout ? clearTimeout(timeout) : undefined);
  }, [dispatch, isBot]);
*/
