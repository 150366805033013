import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import React from "react";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import NavArrowBack from "../../assets/icons/nav_arrow_back.svg";
import { IEvent } from "../../model/event";
import { CategorySortType } from "../../model/section";
import * as EventActions from "../../actions/events";
import CategorySortSelector from "../../components/categorySortSelector";
import EventGrid from "../../components/EventGrid";
import useBrowserBack from "../../hooks/useBrowserBack";
import LoadingView from "../../components/LoadingView";
import { useAppTheme } from "../../hooks/useAppTheme";

interface Props {
  title: string;
  subtitle?: string;
  footer?: string;
  events: IEvent[];
  sort?: CategorySortType;
  onSortChanged?: (sort: CategorySortType) => void;
  hideSort?: boolean;
  onEventSelected?: (event: IEvent) => void;
  hideFavoriteIcon?: boolean;
  hideTags?: boolean;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 55,
      paddingRight: 55,
      paddingBottom: 50,
      paddingTop: 50,
      backgroundColor: "#fff",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 20,
        paddingBottom: 50,
      },
    },
    headerContainer: {
      paddingBottom: 0,
    },
    categoryTitle: {
      fontSize: "1.6667rem",
      fontWeight: "normal",
    },
    subtitle: {
      marginTop: 10,
      paddingRight: 25,
    },
    footer: {
      marginTop: 10,
      paddingRight: 25,
      textAlign: "center",
      fontSize: theme.fontSizes.mediumFont,
      lineHeight: "1.8rem",
    },
    backArrow: {
      paddingTop: 4,
      cursor: "pointer",
    },
    sortSection: {
      marginTop: 21,
    },
    eventSection: {
      marginTop: 50,
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        marginTop: 30,
        marginBottom: 10,
      },
    },
    filterLabel: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: 42,
      marginRight: 15,
      color: theme.bodyTextColor,
      textAlign: "center",
      fontSize: theme.fontSizes.smallFont,
    },
  }),
);

export default function BaseEventGridPage(props: Props) {
  const {
    title,
    subtitle,
    footer,
    events,
    onSortChanged,
    sort,
    hideSort,
    onEventSelected,
    hideFavoriteIcon,
    hideTags,
    loading,
  } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const appTheme = useAppTheme();

  const history = useHistory();
  const browserBack = useBrowserBack();
  const dispatch = useDispatch();

  const openEventDetails = (event: any) => {
    if (onEventSelected) {
      onEventSelected(event);
    } else {
      dispatch(EventActions.selectEvent(event));

      history.push(
        `/events/${event.company_slug}/${event.normalized_title}${appTheme.query}`,
      );
    }
  };

  const navigateBack = (event: any) => {
    browserBack("/events");
  };

  return (
    <>
      <Grid container direction={"column"} className={classes.root}>
        <Grid item className={classes.headerContainer}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: 42, marginTop: "auto", marginBottom: "auto" }}>
              <img
                src={NavArrowBack}
                className={classes.backArrow}
                onClick={navigateBack}
              />
            </div>
            <Typography variant={"h1"} className={classes.categoryTitle}>
              {title}
            </Typography>
          </div>
        </Grid>
        {subtitle && subtitle.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                flex: "0 0 42px",
                width: 42,
                marginTop: "auto",
                marginBottom: "auto",
              }}>
              &nbsp;
            </div>
            <Typography variant={"h4"} className={classes.subtitle}>
              {subtitle}
            </Typography>
          </div>
        )}
        {hideSort != true && (
          <Grid item className={classes.sortSection}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className={classes.filterLabel}>
                {intl.get("category.filter.label")}
              </div>
              <CategorySortSelector
                onItemSelected={onSortChanged}
                value={sort}
              />
            </div>
          </Grid>
        )}
        {loading && (
          <Grid
            item
            style={{ paddingTop: "25vh" }}
            alignItems={"center"}
            justify={"center"}>
            <LoadingView message={intl.get("events.loading")} />
          </Grid>
        )}
        {!loading && (
          <Grid item className={classes.eventSection}>
            <EventGrid
              events={events}
              onSelected={openEventDetails}
              hideFavoriteIcon={hideFavoriteIcon}
              hideTags={hideTags}
            />
          </Grid>
        )}
        {footer && footer.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                flex: "0 0 42px",
                width: 42,
                marginTop: "auto",
                marginBottom: "auto",
              }}>
              &nbsp;
            </div>
            <Typography
              variant={"h4"}
              className={classes.footer}
              style={{ flex: "1", fontWeight: "normal" }}>
              {footer}
            </Typography>
            <div
              style={{
                flex: "0 0 42px",
                width: 42,
                marginTop: "auto",
                marginBottom: "auto",
              }}>
              &nbsp;
            </div>
          </div>
        )}
      </Grid>
    </>
  );
}
