import createReducer from "./createReducer";
import { Action, ActionType, EventState } from "../model/model";

export const eventState = createReducer(new EventState(), {
  [ActionType.GET_EVENTS](state: EventState, action: Action<any>) {
    if (action.payload.result) {
      return {
        ...state,
        sections: action.payload.result,
        seo_page: action.payload.seo_page ? action.payload.seo_page : null,
        loading: false,
        failed: false,
        loaded: true,
        error: null,
        swipeCurrentEventIndex: 0,
        swipeEventToShow:
          action.payload.result.length > 0 ? action.payload.result[0] : null,
        swipeNextEventToTease:
          action.payload.result.length > 1 ? action.payload.result[1] : null,
      };
    }
    if (action.payload.loading) {
      return {
        ...state,
        loading: true,
        sections: state.sections, // keep previous events until we have new ones
        seo_page: null,
        loaded: false,
      };
    }
    if (action.payload.failed) {
      return {
        ...state,
        loading: false,
        failed: true,
        loaded: false,
        seo_page: null,
        error: action.payload.error,
      };
    }
    return { ...state };
  },
  [ActionType.RESET_EVENTS](state: EventState, action: Action<any>) {
    return {
      ...state,
      sections: [],
      seo_page: null,
      loading: false,
      failed: false,
      loaded: false,
      error: null,
      scrollPosition: 0,
    };
  },
  [ActionType.LOAD_SEO_PAGES](state: EventState, action: Action<any>) {
    if (action.payload.result) {
      return { ...state, seo_pages: action.payload.result.seo_pages };
    }
    if (action.payload.loading) {
      return { ...state, seo_pages: [] };
    }
    if (action.payload.failed) {
      return { ...state, seo_pages: [] };
    }
    return { ...state };
  },
});
