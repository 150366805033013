import {
  createStyles,
  Fade,
  makeStyles,
  Popover,
  Theme,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { ISearchProperties } from "../model/searchProperties";
import SearchForm from "./SearchForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverRoot: {
      marginTop: 33, //TODO: handle this via anchor element
    },
  }),
);

interface Props {
  anchorElement: any;
  onClose: () => void;
  onChange?: (value: ISearchProperties) => void;
}

export default function SearchPopover(props: Props) {
  const { anchorElement, onClose } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Popover
      className={classes.popoverRoot}
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      keepMounted
      onClose={onClose}
      TransitionComponent={Fade} // disable animation
      TransitionProps={{ timeout: 0 }} // disable animation
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}>
      <SearchForm
        onNavigateAway={onClose}
        backgroundColor={theme.searchPopOver.background}
        maxWidth={theme.searchPopOver.width}
      />
    </Popover>
  );
}
