import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const SearchKeywords = props => (
  <SvgIcon width={17.23} height={17.23} {...props}>
    <path
      data-name="Pfad 2"
      d="M2.154 7.538a5.332 5.332 0 015.384-5.384 5.332 5.332 0 015.385 5.385 5.332 5.332 0 01-5.385 5.385 5.332 5.332 0 01-5.384-5.386zM15.4 16.907a1.066 1.066 0 101.507-1.507l-3.338-3.338a7.373 7.373 0 001.508-4.523A7.492 7.492 0 007.538 0 7.492 7.492 0 000 7.538a7.492 7.492 0 007.538 7.538 7.373 7.373 0 004.523-1.508z"
    />
  </SvgIcon>
);

export default SearchKeywords;
