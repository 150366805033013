import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../model/model";
import { CategorySortType } from "../model/section";
import { ISearchProperties } from "../model/searchProperties";

export const getEvents = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionType.GET_EVENTS, payload: { loading: true } });

  try {
    const res = await axios.get(`/api/b2c/private/events/home`, {
      withCredentials: true,
      params: {
        withLocation: true,
      },
    });
    dispatch({
      type: ActionType.GET_EVENTS,
      payload: { result: res.data.sections },
    });
  } catch (error) {
    dispatch({
      type: ActionType.GET_EVENTS,
      payload: { failed: true, error },
    });
  }
};

export const getFavorites = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionType.GET_FAVORITES, payload: { loading: true } });

  try {
    const res = await axios.get(`/api/b2c/private/user/favorites`, {
      withCredentials: true,
      params: {
        withLocation: true,
      },
    });
    dispatch({
      type: ActionType.GET_FAVORITES,
      payload: { result: res.data.favorites, loading: false, failed: false },
    });
  } catch (error) {
    dispatch({
      type: ActionType.GET_FAVORITES,
      payload: { failed: true, error, loading: false },
    });
  }
};

export const getCategoryEvents =
  (category_id: string, sort: CategorySortType) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.GET_CATEGORY_EVENTS,
      payload: { loading: true },
    });

    try {
      const res = await axios.get(
        `/api/b2c/private/events/category/${category_id}`,
        {
          withCredentials: true,
          params: {
            withLocation: true,
            sort: sort,
          },
        },
      );
      dispatch({
        type: ActionType.GET_CATEGORY_EVENTS,
        payload: { result: res.data },
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_CATEGORY_EVENTS,
        payload: { failed: true, error },
      });
    }
  };

export const searchEvents =
  (properties: ISearchProperties, sort: CategorySortType) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.SEARCH_EVENTS,
      payload: { loading: true },
    });

    try {
      const res = await axios.post(
        `/api/b2c/private/events/search`,
        {
          ...properties,
        },
        {
          withCredentials: true,
          params: {
            withLocation: true,
            sort,
          },
        },
      );
      dispatch({
        type: ActionType.SEARCH_EVENTS,
        payload: { result: res.data },
      });
    } catch (error) {
      dispatch({
        type: ActionType.SEARCH_EVENTS,
        payload: { failed: true, error },
      });
    }
  };

export const getSeoEvents =
  (seopageName: string, sort: CategorySortType) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionType.GET_SEO_EVENTS, payload: { loading: true } });
    try {
      const res = await axios.get(
        `/api/b2c/private/events/seo/${seopageName}`,
        {
          withCredentials: true,
          params: {
            sort,
            withLocation: true,
          },
        },
      );
      dispatch({
        type: ActionType.GET_SEO_EVENTS,
        payload: { result: res.data.events, seo_page: res.data.seo_page },
      });
    } catch (error) {
      dispatch({
        type: ActionType.GET_SEO_EVENTS,
        payload: { failed: true, error, isSeoPage: true },
      });
    }
  };

export const resetEvents = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionType.RESET_EVENTS });
};

export const loadEventById =
  (eventId: any, fromFavorite: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionType.LOAD_EVENT, payload: { loading: true } });
    try {
      const res = fromFavorite
        ? await axios.get(`/api/events/${eventId}?checkFavorite=true`, {
            withCredentials: true,
            params: {
              withLocation: true,
            },
          })
        : await axios.get(`/api/events/${eventId}`, {
            withCredentials: true,
            params: {
              withLocation: true,
            },
          });
      // TODO: In the future this could either match an individual event ID or a SEO path?
      // In case of SEO we need to redirect to the SEO list page

      // redirect if we are looking up an /events/{id} url or if the id from the /favorites/{id} url is not a favorite of the logged in user
      dispatch({
        type: ActionType.LOAD_EVENT,
        payload: {
          result: res.data,
          shouldRedirect:
            res.data.is_favorite === false || fromFavorite === false,
        },
      });
    } catch (error) {
      dispatch({
        type: ActionType.LOAD_EVENT,
        payload: {
          failed: true,
          errorNotFound: (error as any).response.status === 404,
          shouldRedirect: fromFavorite === false,
        },
      });
    }
  };

export const loadEventBySlugs =
  (companySlug: string, eventNormalizedTitle: string, isBot: boolean) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionType.LOAD_EVENT, payload: { loading: true } });
    try {
      const res = await axios.get(
        `/api/events/find?company=${companySlug}&title=${eventNormalizedTitle}&checkFavorite=true`,
        {
          withCredentials: true,
          params: {
            withLocation: true,
          },
        },
      );

      dispatch({
        type: ActionType.LOAD_EVENT,
        payload: { result: res.data, shouldRedirect: false },
      });
    } catch (error) {
      if (error && (error as any).response && (error as any).response.status) {
        const res = (error as any).response;
        if (res.status === 301) {
          const fullURL = window.location.href;
          const eventStartIndex = fullURL.indexOf("/events/");
          const queryStart = fullURL.indexOf("?");
          const urlStart = fullURL.slice(0, eventStartIndex);
          let targetURL = `${urlStart}/events/${res.data.company_slug}/${res.data.normalized_title}`;
          if (queryStart !== -1) {
            targetURL = `${urlStart}/events/${res.data.company_slug}/${
              res.data.normalized_title
            }${fullURL.slice(queryStart)}`;
          }
          window.location.replace(
            `${process.env.REACT_APP_API_URL}/v2/api/events/redirect?target_url=${targetURL}`,
          );
        } else if (res.status === 404 && isBot) {
          window.location.replace(
            `${process.env.REACT_APP_API_URL}/v2/api/events/${companySlug}/${eventNormalizedTitle}`,
          );
        } else {
          dispatch({
            type: ActionType.LOAD_EVENT,
            payload: {
              failed: true,
              errorNotFound: (error as any).response.status === 404,
            },
          });
        }
      }
    }
  };

export const getSeoPages = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionType.LOAD_SEO_PAGES, payload: { loading: true } });
  try {
    const res = await axios.get(`/api/b2c/private/events/seo`, {
      withCredentials: true,
      params: {
        withLocation: true,
      },
    });
    dispatch({
      type: ActionType.LOAD_SEO_PAGES,
      payload: { result: res.data },
    });
  } catch (error) {
    dispatch({ type: ActionType.LOAD_SEO_PAGES, payload: { failed: true } });
  }
};

export const selectEvent = (event: any) => {
  return {
    type: ActionType.SELECT_EVENT,
    payload: event,
  };
};

export const addToFavorites =
  (eventId: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: ActionType.ADD_TO_FAVORITES,
        payload: { rejected: false, accepted: false, performing: true },
      });
      const res = await axios.post(
        `/api/b2c/private/user/favorites/${eventId}`,
        null,
        {
          withCredentials: true,
        },
      );
      dispatch({
        type: ActionType.ADD_TO_FAVORITES,
        payload: {
          result: res.data.favorites,
          failed: false,
        },
      });
    } catch (error) {
      dispatch({
        type: ActionType.ADD_TO_FAVORITES,
        payload: {
          failed: true,
        },
      });
    }
  };

export const removeFromFavorites =
  (eventId: any) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: ActionType.REMOVE_FROM_FAVORITES,
        payload: { rejected: false, accepted: false, performing: true },
      });
      const res = await axios.delete(
        `/api/b2c/private/user/favorites/${eventId}`,
        {
          withCredentials: true,
        },
      );
      dispatch({
        type: ActionType.REMOVE_FROM_FAVORITES,
        payload: {
          result: res.data.favorites,
          failed: false,
        },
      });
    } catch (error) {
      dispatch({
        type: ActionType.REMOVE_FROM_FAVORITES,
        payload: {
          failed: true,
        },
      });
    }
  };
