import * as BCTheme from "../theme";
import useQueryParams from "./useQueryParams";

export function useAppTheme() {
  const queryParams = useQueryParams();

  const configurationParameter = queryParams.get("configuration");
  if (configurationParameter) {
    return {
      theme: configurationParameter,
      is3rdParty: true,
      query: `?configuration=${configurationParameter}`,
    };
  } else {
    const theme = queryParams.get("theme");
    const is3rdParty = theme && BCTheme.themes[theme];

    return {
      theme,
      is3rdParty,
      query: is3rdParty ? `?theme=${theme}` : "",
    };
  }
}
