import { Redirect, RouteComponentProps } from "react-router";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Authentication } from "../model/model";
import { RootState } from "../reducers";
import * as AuthActions from "../actions/auth";
import { Progress } from "../components";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "stretch",
      marginTop: "auto",
      marginBottom: "auto",
      flexGrow: 1,
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
    },
    topImage: {
      marginTop: 0,
      marginBottom: 30,
      maxWidth: 300,
    },
    or: {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
      paddingTop: 20,
      paddingBottom: 20,
    },
    guest_cta_text: {
      paddingTop: "10px",
      fontWeight: 200,
      fontSize: "0.8em",
      color: theme.landingPage.titleColor,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {
  authentication: Authentication;
  theme: any;
  actions: typeof AuthActions;
}

class LandingPage extends React.Component<Props> {
  render() {
    if (
      this.props.authentication.authenticated &&
      this.props.authentication.user !== null
    ) {
      return (
        <Redirect
          to={{ pathname: "/events", search: this.props.location.search }}
        />
      );
    }
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.props.theme!.landingPage.topImage && (
          <img
            src={this.props.theme!.landingPage.topImage}
            alt=""
            className={classes.topImage}
            style={{ marginBottom: 10 }}
          />
        )}

        <Progress marginTop={20} />
      </div>
    );
  }
}

function authMapStateToProps(state: RootState) {
  return {
    authentication: state.authentication,
  };
}

function authMapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default withStyles(styles, { withTheme: true })(
  connect(authMapStateToProps, authMapDispatchToProps)(LandingPage),
);
