import { createStyles, makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { useAppTheme } from "../hooks/useAppTheme";
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import TopBarContainer from "./TopBarContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    fixedToolbar: {
      paddingLeft: `env(safe-area-inset-left)`,
      paddingRight: `env(safe-area-inset-right)`,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 99,
    },
  }),
);

export default function Header() {
  const classes = useStyles();
  const appTheme = useAppTheme();

  return (
    <>
      <div className={classes.fixedToolbar}>
        <TopBar size={appTheme.is3rdParty ? "small" : "default"} />
      </div>
      <div className={classes.root}>
        <TopBarContainer
          size={appTheme.is3rdParty ? "small" : "default"}
          style={{ boxShadow: "none" }}
        />
        {appTheme.is3rdParty && <TopBanner />}
      </div>
    </>
  );
}
