import { useHistory } from "react-router";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import * as EventActions from "../actions/events";
import * as React from "react";
import { RootState } from "../reducers";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import intl from "react-intl-universal";
import { getCanonicalUrl } from "../utils/utils";
import { IEvent } from "../model/event";
import { CategorySortType } from "../model/section";
import { useEffect } from "react";
import BaseEventGridPage from "./base/BaseEventGridPage";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

interface Props {
  title: string;
  events: IEvent[];
  sort: CategorySortType;
  onSortChanged: (sort: CategorySortType) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

export default function SeoPagesOverviewPage(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(EventActions.getSeoPages());
  }, []);

  const { trackPageView } = useMatomo();

  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });

  // Track page view
  React.useEffect(() => {
    trackPageView({
      customDimensions: analyticsState.custom_dimensions,
    });
  }, []);

  const history = useHistory();

  const openSeoPage = (seoPage: any) => {
    history.push(`/${seoPage.url}`);
  };

  const seoState = useSelector((state: RootState) => {
    return state.seoState;
  });

  return (
    <div className={classes.root}>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl()} />
        <title>
          {intl.get(`seo.event-overview.title`) +
            " | " +
            intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
        </title>
      </Helmet>

      <BaseEventGridPage
        title={intl.get("event_overview.title")}
        subtitle={intl.get("event_overview.description")}
        hideSort
        hideFavoriteIcon
        hideTags
        onEventSelected={openSeoPage}
        events={seoState.seo_pages.map((page) => {
          return {
            title: page.title,
            summary: page.description,
            main_media_url: page.main_media_url,
            url: page.url,
          } as any;
        })}
      />
    </div>
  );
}
