import { CategorySortType } from "../model/section";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import intl from "react-intl-universal";
import React from "react";
import { useAppLocation } from "../hooks/useAppLocation";
import clsx from "clsx";

interface Props {
  onItemSelected: (selectedItem: CategorySortType) => void;
  value: CategorySortType;
}

function addAlpha(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: addAlpha(theme.palette.primary.main, 0.2),
      borderRadius: 4,
      width: 188,
      minHeight: 26,
      display: "flex",
      "&.disabled": {
        backgroundColor: addAlpha(theme.palette.primary.light, 0.2),
      },
    },
    item: {
      textAlign: "center",
      padding: 4.5,
      fontSize: theme.fontSizes.smallFont,
      width: 94,
      color: theme.palette.primary.main,
      cursor: "pointer",
      transition: "background 200ms ease, color 200ms ease",
      "&.selected": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
      },
      "&.disabled": {
        color: addAlpha(theme.palette.primary.main, 0.5),
        cursor: "default",
      },
    },
  }),
);

export default function CategorySortSelector(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { value, onItemSelected } = props;

  const locationState = useAppLocation();

  const distanceEnabled = locationState.hasValidLocation;

  return (
    <Box
      display={"inlineBlock"}
      flexDirection={"row"}
      className={
        distanceEnabled ? classes.root : clsx(classes.root, "disabled")
      }>
      <div
        className={
          value == "relevance" ? clsx(classes.item, "selected") : classes.item
        }
        onClick={() => {
          onItemSelected("relevance");
        }}>
        {intl.get("category.filter.relevance")}
      </div>
      <div
        className={clsx(
          distanceEnabled ? "" : "disabled",
          value == "distance" ? "selected" : "",
          classes.item,
        )}
        onClick={() => {
          if (distanceEnabled) {
            onItemSelected("distance");
          }
        }}>
        {intl.get("category.filter.distance")}
      </div>
    </Box>
  );
}
