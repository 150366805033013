import {
  Button,
  Collapse,
  createStyles,
  Divider,
  Input,
  InputAdornment,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import intl from "react-intl-universal";
import { DateRange } from "react-date-range";
import React, { useState } from "react";
import { useLocale } from "../hooks/useLocale";
import { addDays, isBefore, setDay, startOfDay } from "date-fns";
import { ColorSvg } from "./ColorSvg";
import SearchDatetime from "../assets/icons/SearchDatetime";
import SearchArrowEnd from "../assets/icons/SearchArrowEnd";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      borderRadius: 4,
    },
    input: {
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: "transparent",
    },
    placeholderStyleOverlay: {
      fontSize: theme.fontSizes.smallFont,
      color: `${theme.palette.primary.main} !important`,
      "-webkit-text-fill-color": `${theme.palette.primary.main} !important`,
      opacity: 1,
      fontWeight: 400,
      "&::placeholder": {
        opacity: 1,
        color: theme.palette.primary.light,
      },
    },
    iconLeft: {
      paddingRight: 4,
    },
    iconSvg: {
      width: 16,
      height: 16,
    },
    arrowSvg: {
      width: 12,
      height: 12,
    },
    button: {
      marginLeft: 5,
      marginRight: 5,
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#fff",
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "center",
      paddingTop: 20,
      paddingBottom: 20,
    },
    divider: {
      marginLeft: 10,
      marginRight: 10,
    },
    datePicker: {
      backgroundColor: "transparent",
    },
  }),
);

export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
}

interface Props {
  value: IDateRange;
  onChange: (range: IDateRange) => void;
  collapseOnSelect?: boolean;
}

export default function CollapsibleDateRangePicker(props: Props) {
  const { value, onChange, collapseOnSelect } = props;
  const theme = useTheme();
  const classes = useStyles();
  const locale = useLocale();

  const [showDatePicker, setShowDatePicker] = useState(false);

  const getFormattedDate = (date: Date) => {
    return locale.formatters.dateTime.format(date);
  };

  const getFormattedDateRangeString = () => {
    const startDate = getFormattedDate(value.startDate);
    if (value.startDate.toISOString() == value.endDate.toISOString()) {
      return startDate;
    }
    return startDate + " - " + getFormattedDate(value.endDate);
  };

  const onTodayButtonClicked = () => {
    const date = startOfDay(new Date());
    onChange({
      startDate: date,
      endDate: date,
    });
  };

  const onTomorrowButtonClicked = () => {
    const date = addDays(startOfDay(new Date()), 1);
    onChange({
      startDate: date,
      endDate: date,
    });
  };

  const onWeekendButtonClicked = () => {
    const today = startOfDay(new Date());
    const friday = setDay(today, 5);

    // if we're already in the weekend, select the rest of the weekend
    const startDate = isBefore(friday, today) ? today : friday;
    const sunday = addDays(friday, 2);

    onChange({
      startDate: startDate,
      endDate: sunday,
    });
  };

  return (
    <div className={classes.root}>
      <Input
        fullWidth
        classes={{
          input: classes.placeholderStyleOverlay,
        }}
        className={classes.input}
        id="datetime"
        placeholder={intl.get("searchPopover.date_hint")}
        startAdornment={
          <InputAdornment position="start" className={classes.iconLeft}>
            <ColorSvg
              svg={SearchDatetime}
              className={classes.iconSvg}
              color={theme.palette.primary.main}
            />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="start" className={classes.iconLeft}>
            <ColorSvg
              className={classes.arrowSvg}
              svg={SearchArrowEnd}
              color={theme.palette.primary.main}
              style={{
                transform: showDatePicker ? "rotate(180deg)" : undefined,
                transition: "250ms ease-in-out",
              }}
            />
          </InputAdornment>
        }
        disabled={true}
        onClick={() => {
          setShowDatePicker(!showDatePicker);
        }}
        value={getFormattedDateRangeString()}
      />

      <Collapse in={showDatePicker}>
        <Divider className={classes.divider} />
        <div className={classes.buttonContainer}>
          <Button
            color={"secondary"}
            size="small"
            className={classes.button}
            onClick={onTodayButtonClicked}>
            {intl.get("search.date.button_today")}
          </Button>
          <Button
            size="small"
            color={"secondary"}
            className={classes.button}
            onClick={onTomorrowButtonClicked}>
            {intl.get("search.date.button_tomorrow")}
          </Button>
          <Button
            color={"secondary"}
            size="small"
            className={classes.button}
            onClick={onWeekendButtonClicked}>
            {intl.get("search.date.button_weekend")}
          </Button>
        </div>
        <Divider className={classes.divider} />
        <DateRange
          className={classes.datePicker}
          ranges={[{ ...value, key: "selection" }]}
          showDateDisplay={false}
          locale={locale.dateFns}
          rangeColors={[theme.palette.primary.main]}
          onChange={(newRange) => {
            if (collapseOnSelect) {
              setShowDatePicker(false);
            }
            onChange(newRange.selection);
          }}
        />
      </Collapse>
    </div>
  );
}
