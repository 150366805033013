import React, { useEffect, useState } from "react";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import PagingDots from "./pagingDots";
import useFavorites from "../hooks/useFavorites";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { MuiBreakpoint, useMuiBreakpoint } from "../hooks/useMuiBreakpoint";
import clsx from "clsx";
import useVisibility from "../hooks/useVisibility";
import { ColorSvg } from "./ColorSvg";
import ScrollArrowActive from "../assets/icons/ScrollArrowActive";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
    },
    arrow: {
      marginTop: "auto",
      marginBottom: "auto",
      width: 50,
      height: 50,
      pointerEvents: "all",
    },
    arrowSvg: {
      width: 18,
      height: 18,
    },
    arrowContainer: {
      gridRow: 1,
      gridColumn: 1,
      zIndex: 10,
      display: "flex",
      width: 50,
      height: 420,
      pointerEvents: "none",
      marginLeft: theme.carousel.horizontalContentPadding - (50 - 8) / 2,
      marginRight: theme.carousel.horizontalContentPadding - (50 - 8) / 2,
      "&.arrowRight": {
        marginLeft: "auto",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: 15,
        marginRight: 15,
      },
      [theme.breakpoints.down("xs")]: {
        marginLeft: 5,
        marginRight: 5,
      },
    },
    carouselContainer: {
      gridRow: 1,
      gridColumn: 1,
      zIndex: 5,
      maxWidth: "100vw",
    },
    carouselItem: {
      gridRow: 1,
      gridColumn: 1,
      marginTop: "auto",
      marginBottom: "auto",
      paddingBottom: 26,
      opacity: 0,
      maxWidth: "calc(100vw - 10px);",
      transform: "translate(0px, 0px) scale(0.3);",
      zIndex: 0,
      "&.centerItem": {
        opacity: 1,
        zIndex: 10,
        transform: "translate(0%, 0%) scale(1.0);",
      },
      "&.leftItem": {
        opacity: 0.4,
        zIndex: 1,
        transform: "translate(-90%, 0px) scale(0.85);",
      },
      "&.rightItem": {
        opacity: 0.4,
        zIndex: 1,
        transform: "translate(90%, 0px) scale(0.85);",
      },
      "&.inItem": {
        opacity: 0,
        zIndex: 0,
        transform: "translate(60%, 0px) scale(0.6);",
      },
      "&.outItem": {
        opacity: 0,
        zIndex: 0,
        transform: "translate(-60%, 0px) scale(0.6);",
      },
      transformStyle: "preserve-3d",
      transition: "transform 500ms ease-out, opacity 300ms ease-out",
    },
    carouselItemMobile: {
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      paddingBottom: 20,
    },
    dots: {
      gridRow: 2,
      gridColumn: 1,
    },
    autoMarginLeft: {
      marginLeft: "auto",
    },
    autoMarginRight: {
      marginRight: "auto",
    },
  }),
);

const AUTO_PAGING_INTERVAL_MILLIS = 4 * 1000;

interface Props {
  items: any[];
  autoPage?: boolean;
  autoPageInterval?: number;
  renderItem: (item: any, isCenter: Boolean) => any;
  numberOfItems?: 1 | 3;
}

export default function HeroCarousel(props: Props) {
  const { items, autoPage, autoPageInterval, renderItem, numberOfItems } =
    props;

  const [centerItemIndex, setCenterItemIndex] = useState(0);

  const theme = useTheme();
  const classes = useStyles(theme);

  let carouselItems = items;
  // add items x more times until length > 5
  while (items.length > 0 && carouselItems.length < 5) {
    carouselItems = [...carouselItems, ...items];
  }

  const rightItemIndex = (centerItemIndex + 1) % carouselItems.length;
  const leftItemIndex =
    (centerItemIndex + carouselItems.length - 1) % carouselItems.length;
  const outItemIndex = (centerItemIndex + 2) % carouselItems.length;
  const inItemIndex =
    (centerItemIndex + carouselItems.length - 2) % carouselItems.length;

  const scrollRight = () => {
    setCenterItemIndex(rightItemIndex);
  };

  const scrollLeft = () => {
    setCenterItemIndex(leftItemIndex);
  };

  // used to reset autoPage timer when favorites change
  const favorites = useFavorites();

  const muiWidthBreakpoint = useMuiBreakpoint();
  const shouldUseSingleImageSlider =
    numberOfItems == 1 || muiWidthBreakpoint < MuiBreakpoint.md;
  const shouldShowArrows = !shouldUseSingleImageSlider;

  const visibleToUser = useVisibility();

  useEffect(() => {
    if (!shouldUseSingleImageSlider && autoPage && visibleToUser) {
      const interval = setInterval(() => {
        scrollRight();
      }, autoPageInterval || AUTO_PAGING_INTERVAL_MILLIS);

      return () => clearInterval(interval);
    }
  }, [
    shouldUseSingleImageSlider,
    centerItemIndex,
    favorites, // restart timer when favorites change (e.g. like button clicked)
    autoPage,
    autoPageInterval,
    scrollRight,
    visibleToUser,
  ]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction={"row"}
        justify={"center"}
        wrap="nowrap"
        className={classes.carouselContainer}
        style={shouldUseSingleImageSlider ? {} : { display: "grid" }}>
        {shouldUseSingleImageSlider && (
          <div style={{ width: "100%", gridRow: 1, gridColumn: 1 }}>
            <Swiper
              direction={"horizontal"}
              autoplay={
                autoPage === true
                  ? {
                      disableOnInteraction: false,
                    }
                  : undefined
              }
              loop={true}
              slidesPerView={1}
              onSlideChange={(swiper) => {
                setCenterItemIndex(swiper.realIndex % items.length);
              }}>
              {items.map((item, index) => {
                return (
                  <SwiperSlide
                    className={classes.carouselItemMobile}
                    key={"swiper_slide_" + index}>
                    {renderItem(item, true)}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
        {!shouldUseSingleImageSlider &&
          carouselItems.map((item, index) => {
            return (
              <Grid
                item
                key={item.id + "" + index}
                className={clsx(
                  classes.carouselItem,
                  index === centerItemIndex ? "centerItem" : undefined,
                  index === leftItemIndex ? "leftItem" : undefined,
                  index === rightItemIndex ? "rightItem" : undefined,
                  index === inItemIndex ? "outItem" : undefined,
                  index === outItemIndex ? "inItem" : undefined,
                )}>
                {renderItem(item, index === centerItemIndex)}
              </Grid>
            );
          })}
      </Grid>
      {shouldShowArrows && (
        <div className={[classes.arrowContainer, "arrowLeft"].join(" ")}>
          <IconButton
            onClick={scrollLeft}
            color={"primary"}
            className={[classes.arrow, "arrowLeft"].join(" ")}>
            <ColorSvg
              svg={ScrollArrowActive}
              color={theme.palette.primary.main}
              style={{ transform: "rotate(180deg)" }}
              className={classes.arrowSvg}
            />
          </IconButton>
        </div>
      )}
      {shouldShowArrows && (
        <div className={[classes.arrowContainer, "arrowRight"].join(" ")}>
          <IconButton
            onClick={scrollRight}
            color={"primary"}
            className={[classes.arrow, "arrowRight"].join(" ")}>
            <ColorSvg
              svg={ScrollArrowActive}
              color={theme.palette.primary.main}
              className={classes.arrowSvg}
            />
          </IconButton>
        </div>
      )}

      <div
        className={[
          classes.dots,
          classes.autoMarginRight,
          classes.autoMarginLeft,
        ].join(" ")}>
        <PagingDots
          index={centerItemIndex % items.length}
          count={items.length}
        />
      </div>
    </div>
  );
}
