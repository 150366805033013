import { EventType } from "../../model/model";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Event, IEvent } from "../../model/event";
import * as React from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { addDays, format, startOfWeek } from "date-fns";
import { useLocale } from "../../hooks/useLocale";
import intl from "react-intl-universal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    openingHourTable: {},
    openingHourRow: {
      height: 0,
    },
    openingDayCell: {
      textAlign: "left",
      borderBottom: 0,
      padding: 0,
      minWidth: 30,
    },
    openingHourCell: {
      textAlign: "left",
      borderBottom: 0,
      padding: 0,
      minWidth: 30,
      fontSize: theme.fontSizes.smallFont,
    },
    openingHourLabelCell: {
      verticalAlign: "top",
      textAlign: "left",
      borderBottom: 0,
      padding: 0,
      paddingRight: 4,
      minWidth: 30,
      fontSize: theme.fontSizes.smallFont,
    },
  }),
);

interface Props {
  event: IEvent;
  useShortDayNames?: boolean;
}

export default function EventOpeningHours(props: Props) {
  const { event, useShortDayNames } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const locale = useLocale();

  const getWeekdayName = (weekday: number) => {
    return format(
      addDays(startOfWeek(new Date()), weekday + 1),
      useShortDayNames ? "EE" : "EEEE",
      { locale: locale.dateFns },
    ).replace(".", "");
  };

  if (event.type !== EventType.Recurring) {
    return null;
  }

  if (Event.hasActiveOpeningHourRange(event) === false) {
    return (
      <Grid item xs>
        <Table className={classes.openingHourTable}>
          <TableBody>
            <TableRow className={classes.openingHourRow}>
              <TableCell
                align="right"
                className={classes.openingHourLabelCell}
                padding="none">
                {intl.get("event.no_active_opening_hour_range")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    );
  }

  return (
    <Grid item xs>
      <Table className={classes.openingHourTable}>
        <TableBody>
          <TableRow className={classes.openingHourRow}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {getWeekdayName(0)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "mon",
                ),
              }}
            />
          </TableRow>
          <TableRow className={classes.openingHourRow}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {getWeekdayName(1)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "tue",
                ),
              }}
            />
          </TableRow>
          <TableRow className={classes.openingHourRow}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {getWeekdayName(2)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "wed",
                ),
              }}
            />
          </TableRow>
          <TableRow className={classes.openingHourRow}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {getWeekdayName(3)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "thu",
                ),
              }}
            />
          </TableRow>
          <TableRow className={classes.openingDayCell}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {getWeekdayName(4)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "fri",
                ),
              }}
            />
          </TableRow>
          <TableRow className={classes.openingDayCell}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {getWeekdayName(5)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "sat",
                ),
              }}
            />
          </TableRow>
          <TableRow className={classes.openingDayCell}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {getWeekdayName(6)}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "sun",
                ),
              }}
            />
          </TableRow>
          <TableRow className={classes.openingDayCell}>
            <TableCell
              align="right"
              className={classes.openingHourLabelCell}
              padding="none">
              {intl.get("event.holiday")}
            </TableCell>
            <TableCell
              align="right"
              className={classes.openingHourCell}
              padding="none"
              dangerouslySetInnerHTML={{
                __html: Event.getOpeningHoursStringForCurrentRange(
                  event,
                  "hol",
                ),
              }}
            />
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
}
