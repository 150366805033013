import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const NavigationProfile = props => (
  <SvgIcon width={22} height={23} {...props}>
    <path
      data-name="Vereinigungsmenge 9"
      d="M1 22v-2.5c0-2.75 4.5-5 10-5s10 2.25 10 5V22zM6 6a5 5 0 115 5 5 5 0 01-5-5z"
    />
  </SvgIcon>
);

export default NavigationProfile;
