import React, { useState } from "react";
import {
  normalizedImageURL,
  resizedImageURLAsWebp,
  videoPreviewImageUrl,
} from "../model/model";
import { makeStyles } from "@material-ui/core/styles";
import { IEvent } from "../model/event";
import clsx from "clsx";
import EventFavoriteButton from "./EventFavoriteButton";
import { MuiBreakpoint, useMuiBreakpoint } from "../hooks/useMuiBreakpoint";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    backgroundColor: "transparent",
    borderRadius: 10,
    display: "grid",
  },
  shadow: {
    boxShadow: "0px 5px 10px rgba(0,0,0,0.16)",
  },
  eventImageBox: {
    gridRow: 1,
    gridColumn: 1,
  },
  eventImage: {
    borderRadius: 10,
    objectFit: "cover",
  },
  likeIcon: {
    gridRow: 1,
    gridColumn: 1,
    marginLeft: "auto",
    marginTop: 20,
    marginRight: 20,
    zIndex: 99,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      marginRight: 10,
    },
  },
}));

interface Props {
  event: IEvent;
  photo: any;
  size: "standard" | "small" | "fullWidth";
  dropShadow?: boolean;
  style?: any;
  hideFavoriteIcon?: boolean;
}

const EventCard = (props: Props) => {
  const classes = useStyles();

  const { event, dropShadow, style, hideFavoriteIcon, photo, size } = props;

  const muiBreakpoint = useMuiBreakpoint();
  const isSmall = muiBreakpoint <= MuiBreakpoint.sm;

  const cardSizeMap = {
    standard: { width: isSmall ? 165 : 380, height: isSmall ? 165 : 380 },
    small: { width: isSmall ? 165 : 352, height: isSmall ? 165 : 352 },
    fullWidth: { width: "100%", height: 229 },
  };

  const cardWidth = cardSizeMap[size].width;
  const cardHeight = cardSizeMap[size].height;

  const rootStyle = {
    width: cardWidth,
    maxWidth: "90vw",
  };

  const cardSizeStyle = {
    width: "100%",
    height: cardHeight,
    maxHeight: "90vw",
  };

  const webpImageURL = resizedImageURLAsWebp(
    photo.large_preview_url,
    !isMobile,
    window.devicePixelRatio,
    true,
  );

  const [mediaLoading, setMediaLoading] = useState(true);

  return (
    <div
      style={{ ...style, ...rootStyle }}
      className={
        dropShadow == true ? clsx(classes.root, classes.shadow) : classes.root
      }>
      <div className={classes.eventImageBox} style={cardSizeStyle}>
        {photo.type !== "video" && (
          <picture
            draggable="false"
            style={{ maxWidth: cardWidth, maxHeight: cardHeight }}>
            {webpImageURL != null && (
              <source
                type="image/webp"
                srcSet={photo.large_preview_url
                  .replaceAll(",", "%2C")
                  .replaceAll(".jpg", ".webp")
                  .replaceAll(".png", ".webp")}
              />
            )}
            {mediaLoading && (
              <img
                className={classes.eventImage}
                style={{
                  ...cardSizeStyle,
                  objectFit: "cover",
                  transition: "opacity 200ms",
                }}
                draggable="false"
                src={"/image_loading_placeholder.svg"}
                alt=""
              />
            )}
            <img
              className={classes.eventImage}
              style={
                mediaLoading
                  ? {
                      ...cardSizeStyle,
                      objectFit: "cover",
                      height: 0,
                      minHeight: 0,
                    }
                  : { ...cardSizeStyle, objectFit: "cover" }
              }
              srcSet={`${normalizedImageURL(
                photo.mini_thumbnail_url,
              ).replaceAll(",", "%2C")} 500w,
                ${normalizedImageURL(photo.thumbnail_url).replaceAll(
                  ",",
                  "%2C",
                )} 800w,
                ${normalizedImageURL(photo.large_preview_url).replaceAll(
                  ",",
                  "%2C",
                )} 1024w`}
              key={normalizedImageURL(photo.large_preview_url)}
              src={normalizedImageURL(photo.large_preview_url)}
              alt="Event images"
              onLoad={() => {
                setMediaLoading(false);
              }}
            />
          </picture>
        )}
        {photo.type == "video" && (
          <video
            loop
            autoPlay
            muted
            playsInline
            onLoad={() => {
              setMediaLoading(false);
            }}
            width={"100%"}
            height={"100%"}
            className={classes.eventImage}
            key={photo.large_preview_url}
            poster={videoPreviewImageUrl(photo.large_preview_url)}>
            <source src={photo.large_preview_url} />
          </video>
        )}
      </div>

      {hideFavoriteIcon != true && (
        <div className={classes.likeIcon}>
          <EventFavoriteButton event={event} />
        </div>
      )}
    </div>
  );
};

export default EventCard;
