import { isSameDay } from "date-fns";
import { Base64 } from "js-base64";

const moment = require("moment");

export function getCanonicalUrl() {
  const loc = window.location;
  return loc.protocol + "//" + loc.hostname + loc.pathname;
}

export function getParamValueInString(searchString: string, paramName: any) {
  if (searchString.indexOf("?") !== -1) {
    searchString = searchString.substring(searchString.indexOf("?") + 1);
  }
  const qArray = searchString.split("&"); // get key-value pairs
  for (let i = 0; i < qArray.length; i++) {
    const pArr = qArray[i].split("="); // split key and value
    if (pArr[0] === paramName) {
      return pArr[1];
    }
  }
  return null;
}

export function getParamValue(paramName: any) {
  return getParamValueInString(window.location.search.substring(1), paramName);
}

export interface ThemeConfigurationParameter {
  parsedConfiguration: ThemeConfiguration;
  rawConfiguration: string;
}

export interface ThemeConfiguration {
  configuration_variation: string;
  primaryColor: string;
  primaryContrastColor: string;
  primaryLightColor: string;
  navigationBarColor?: string;
  highlightColor?: string;
  cherriesLogoVariant: string;
  logoURL: string;
  imprintURL?: string;
  privacyURL?: string;
  blogURL: string;
  blogName: string;
  apiKey: string;

  // Only available for full variant
  primaryLightContrastColor?: string;
  primaryDarkColor?: string;
  browserThemeColor?: string;
  bodyTextColor?: string;
  indicatorProfileColor?: string;
  indicatorFavoriteColor?: string;
  topBarBackgroundColor?: string;
  topBarContrastColor?: string;
  secondaryBackgroundColor?: string;
  backgroundColor?: string;
}

export function getThemeConfigurationParam(): ThemeConfigurationParameter | null {
  const configuration = getParamValue("configuration");
  if (configuration) {
    const decodedConfiguration = Base64.decode(configuration);
    if (decodedConfiguration) {
      const parsedConfiguration = JSON.parse(decodedConfiguration);
      if (
        parsedConfiguration &&
        parsedConfiguration.configuration_variation &&
        (parsedConfiguration.configuration_variation === "v1_simple" ||
          parsedConfiguration.configuration_variation === "v1_full")
      ) {
        return {
          rawConfiguration: configuration,
          parsedConfiguration: parsedConfiguration,
        };
      }
    }
  }
  return null;
}

export function parseQueryStringToDictionary(queryString: string): any {
  const dictionary = {};

  if (queryString.indexOf("?") === 0) {
    queryString = queryString.substr(1);
  }

  // separate out each key/value pair
  const parts = queryString.split("&");

  for (let i = 0; i < parts.length; i++) {
    const p = parts[i];
    // Step 2: Split Key/Value pair
    const keyValuePair = p.split("=");

    // Add Key/Value pair to Dictionary object
    const key = keyValuePair[0];
    let value = keyValuePair[1];

    // decode URI encoded string
    value = decodeURIComponent(value);
    value = value.replace(/\+/g, " ");

    dictionary[key] = value;
  }

  return dictionary;
}

const myStaticRangeHandler = {
  range: {},
  isSelected(range: any) {
    // @ts-ignore
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges1(ranges: any) {
  // @ts-ignore
  return ranges.map((range) => ({ ...myStaticRangeHandler, ...range }));
}

export function allRange(): any {
  return {
    label: "Alle",
    range: () => ({
      startDate: moment().startOf("day").toDate(),
      endDate: moment("2100-12-31").toDate(),
    }),
  };
}

export function todayRange(): any {
  return {
    label: "Heute",
    range: () => ({
      startDate: moment().startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
    }),
  };
}

export function tomorrowRange(): any {
  return {
    label: "Morgen",
    range: () => ({
      startDate: moment().add(1, "day").startOf("day").toDate(),
      endDate: moment().add(1, "day").endOf("day").toDate(),
    }),
  };
}

export function thisWeekRange(): any {
  return {
    label: "Diese Woche",
    range: () => ({
      startDate: moment().startOf("week").toDate(),
      endDate: moment().endOf("week").toDate(),
    }),
  };
}

export function nextWeekRange(): any {
  return {
    label: "Nächste Woche",
    range: () => ({
      startDate: moment().add(1, "week").startOf("week").toDate(),
      endDate: moment().add(1, "week").endOf("week").toDate(),
    }),
  };
}

export function thisMonthRange(): any {
  return {
    label: "Dieses Monat",
    range: () => ({
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
    }),
  };
}

export function nextMonthRange(): any {
  return {
    label: "Nächstes Monat",
    range: () => ({
      startDate: moment().add(1, "month").startOf("week").toDate(),
      endDate: moment().add(1, "month").endOf("week").toDate(),
    }),
  };
}

export function next7DaysRange(): any {
  return {
    label: "Nächsten 7 Tage",
    range: () => ({
      startDate: moment().toDate(),
      endDate: moment().add(7, "day").toDate(),
    }),
  };
}

export function next14DaysRange(): any {
  return {
    label: "Nächsten 14 Tage",
    range: () => ({
      startDate: moment().toDate(),
      endDate: moment().add(14, "day").toDate(),
    }),
  };
}

export function formattedFilterRange(start: Date, end: Date): string {
  const ranges = defaultFilterRanges;
  for (const r of ranges) {
    if (r.isSelected({ startDate: start, endDate: end })) {
      return r.label;
    }
  }
  return `${moment(start).format("dd.MM.yyyy")} - ${moment(end).format(
    "dd.MM.YYYY",
  )}`;
}

export function commaAddressLineSplit(
  text: string,
  maxLineLength: number,
  forceFirstLine: boolean,
) {
  if (!text) {
    return [""];
  }

  if (text.indexOf(",") < 0) {
    return [text.trim()];
  }

  const split = text.split(",");

  const lines = [];

  split.forEach((line, index) => {
    if (
      lines.length > (forceFirstLine ? 1 : 0) &&
      lines[lines.length - 1].length + line.length < maxLineLength
    ) {
      lines[lines.length - 1] = lines[lines.length - 1] + line;
    } else {
      if (line.trim().length > 0) {
        lines.push(line);
      }
    }

    if (line.trim().length > 0 && index < split.length - 1) {
      lines[lines.length - 1] = lines[lines.length - 1] + ",";
    }
  });

  return lines.map((line) => line.trim());
}

export function addAlpha(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export const defaultFilterRanges = createStaticRanges1([
  allRange(),
  todayRange(),
  tomorrowRange(),
  thisWeekRange(),
  nextWeekRange(),
  thisMonthRange(),
  nextMonthRange(),
  next7DaysRange(),
  next14DaysRange(),
]);

export function supportsStorage(): boolean {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ignoredLocalStorage = window.localStorage;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const ignoredSessionStorage = window.sessionStorage;
    return true;
  } catch (e) {
    return false;
  }
}
