import { CategorySortType } from "../model/section";
import { useHistory } from "react-router";
import useQueryParams from "./useQueryParams";

export default function useSortQuery(): [
  CategorySortType,
  (CategorySortType) => void,
] {
  const history = useHistory();
  const queryParams = useQueryParams();

  const sort =
    queryParams.get("sort") === "distance" ? "distance" : "relevance";

  const setSort = (newSort: CategorySortType) => {
    queryParams.set("sort", newSort);
    history.replace({
      pathname: history.location.pathname,
      search: queryParams.toString(),
    });
  };

  return [sort, setSort];
}
