import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de-AT";

const germanLocalizations = require("../translations/de.json");
// @ts-ignore
import locale2 from "locale2";
import { useEffect, useState } from "react";

const datePickerLocales = {
  en: enLocale,
  de: deLocale,
};

const locales = {
  en: germanLocalizations,
  de: germanLocalizations,
};

const determineLocale = () => {
  let mLocaleString: string = locale2;
  if (locales[mLocaleString]) {
    return mLocaleString;
  }
  if (mLocaleString.indexOf("-") !== -1) {
    mLocaleString = mLocaleString.substring(0, mLocaleString.indexOf("-"));
    if (locales[mLocaleString]) {
      return mLocaleString;
    }
    return "en";
  }
  return "en";
};

export function useLocale() {
  const [localeString, setLocaleString] = useState("de");

  useEffect(() => {
    setLocaleString(determineLocale());
  }, []);

  return {
    localeString,
    dateFns: localeString == "de" ? datePickerLocales.de : datePickerLocales.en,
    app: localeString == "de" ? locales.de : locales.en,
    formatters: {
      dateTime: Intl.DateTimeFormat(localeString, {
        dateStyle: "long",
      }),
    },
  };
}
