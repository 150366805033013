import axios from "axios";
import { Dispatch } from "redux";
import { ActionType, Error } from "../model/model";
import { getParamValue } from "../utils/utils";

export const exchangeAuthorizationCode =
  (
    authorization_code: string,
    redirectUri: string,
    code_verifier: string,
    onCompleted: (token: string | null) => void,
    onError: (error: Error | null) => void,
  ) =>
  async (dispatch: Dispatch<any>) => {
    try {
      let requestCookiesInResponse = true;
      if (
        (getParamValue("theme") !== null ||
          getParamValue("configuration") !== null) &&
        getParamValue("iframe") === null
      ) {
        // We have been passed a theme (most likely in blog) but not the new parameter iframe (which indicates the new wordpress plugin)
        requestCookiesInResponse = false;
      }
      const res = await axios.post(
        "/api/auth2/b2c/token",
        {
          authorization_code: authorization_code,
          code_verifier: code_verifier,
          redirect_uri: redirectUri,
          cookies: requestCookiesInResponse ? undefined : "false",
        },
        { withCredentials: true },
      );

      try {
        window.sessionStorage.setItem("validated", "true");
      } catch (error) {}

      dispatch({ type: ActionType.FETCH_USER, payload: res.data.user });
      onCompleted(requestCookiesInResponse ? null : res.data.token);
    } catch (myError) {
      try {
        window.localStorage.removeItem("code_verifier");
        window.sessionStorage.removeItem("validated");
      } catch (error) {}

      onError(myError as Error);
    }
  };

export const fetchUser =
  (onCompleted: () => void) => async (dispatch: Dispatch<any>) => {
    try {
      const res = await axios.get("/api/private/user", {
        withCredentials: true,
        headers: { "Cache-Control": "no-cache" },
      });
      try {
        window.sessionStorage.setItem("validated", "true");
      } catch (error) {}
      dispatch({ type: ActionType.FETCH_USER, payload: res.data });
      onCompleted();
    } catch (error) {
      //window.sessionStorage.removeItem("validated");
    }
  };
