import { useTheme } from "@material-ui/core";
import useWindowSize from "./useWindowSize";

export enum MuiBreakpoint {
  xs = 0,
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4,
}

export function useMuiBreakpoint() {
  const theme = useTheme();

  const windowSize = useWindowSize();

  if (windowSize.width <= theme.breakpoints.values.xs) {
    return MuiBreakpoint.xs;
  }
  if (windowSize.width <= theme.breakpoints.values.sm) {
    return MuiBreakpoint.sm;
  }
  if (windowSize.width <= theme.breakpoints.values.md) {
    return MuiBreakpoint.md;
  }
  if (windowSize.width <= theme.breakpoints.values.lg) {
    return MuiBreakpoint.lg;
  }
  return MuiBreakpoint.xl;
}
