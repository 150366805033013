import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  Input,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import intl from "react-intl-universal";
import useStorage from "../hooks/useStorage";
import { ManualLocation } from "../model/model";
import { useAppLocation } from "../hooks/useAppLocation";
import useGoogleMapsAutocomplete from "../hooks/useGoogleMapsAutocomplete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      textAlign: "center",
      flexGrow: 1,
      paddingLeft: theme.eventPage.horizontalContentPadding,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    root: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 8,
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 16,
      paddingRight: 16,
      marginBottom: 16,
    },
    innerContent: {
      gap: 8,
      flexWrap: "nowrap",
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap-reverse",
      },
    },
    button: {
      flexShrink: 0,
      flexGrow: 0,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
      },
    },
    textfield: {
      fontSize: theme.fontSizes.mediumFont,
      color: theme.palette.primary.main,
      opacity: 1,
      fontWeight: 600,
      "&::placeholder": {
        opacity: 0.5,
        fontSize: theme.fontSizes.mediumFont,
        color: theme.palette.secondary.contrastText,
        fontWeight: 600,
      },
      height: 40,
      minHeight: 40,
      maxHeight: 40,
    },
    input: {
      flexGrow: 4,
      height: 50,
      marginTop: 10,
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: 10,
      maxWidth: 500,
    },
    error: {
      marginTop: -12,
      [theme.breakpoints.down("xs")]: {
        marginTop: 0,
      },
    },
  }),
);

interface Props {
  style?: any;
  onLoadingLocationChanged?: (loading: boolean) => void;
  showLoadingProgress?: boolean;
  hideTitle?: boolean;
}

type ViewState = "awaiting_permission" | "geolocation" | "custom_location";

export default function LocationBar(props: Props) {
  const { style, onLoadingLocationChanged, showLoadingProgress, hideTitle } =
    props;

  const classes = useStyles();
  const storage = useStorage();

  const appLocation = useAppLocation();
  const geoLocation = appLocation.geoLocation;

  const hasManuallySetLocation = appLocation.locationType === "manual";

  const [viewState, mSetViewState] = useState<ViewState>(
    appLocation.locationType === "geolocation"
      ? "awaiting_permission"
      : "custom_location",
  );

  const [showGeolocationError, setShowGeolocationError] =
    useState<string>(null);

  const setViewState = (newState: ViewState) => {
    if (newState === viewState) {
      return;
    }

    storage.setItem("locationBarViewState", newState, "local");

    mSetViewState(newState);

    if (
      viewState === "awaiting_permission" ||
      newState === "awaiting_permission"
    ) {
      if (onLoadingLocationChanged) {
        onLoadingLocationChanged(newState === "awaiting_permission");
      }
    }

    if (newState === "awaiting_permission" || newState === "geolocation") {
      // @ts-ignore
      ref.current.value = "";
    }
  };

  const handlePlaceSelected = (place: any) => {
    setManualLocation(place);
    if (place) {
      setViewState("custom_location");
    }
  };

  const { ref } = useGoogleMapsAutocomplete(handlePlaceSelected);

  useEffect(() => {
    const handleError = (message: string) => {
      setShowGeolocationError(message);
      setViewState("custom_location");
      if (appLocation?.locationType === "manual") {
        // @ts-ignore
        ref.current.value = appLocation.location.name;
      }
      if (onLoadingLocationChanged) {
        onLoadingLocationChanged(false);
      }
    };

    if (geoLocation.state === "determined") {
      setShowGeolocationError(null);
    }

    if (viewState === "awaiting_permission" || viewState === "geolocation") {
      if (geoLocation.state === "determined") {
        setViewState("geolocation");
      } else if (geoLocation.permission === "denied") {
        handleError(intl.get("locationbar.error_permission"));
      } else if (geoLocation.state === "no_permission") {
        handleError(intl.get("locationbar.error_permission"));
      } else if (geoLocation.state == "not_available") {
        handleError(intl.get("locationbar.error_geolocation"));
      }
    }
  }, [
    geoLocation.location.latitude,
    geoLocation.location.longitude,
    geoLocation.state,
    viewState,
    geoLocation.permission,
    onLoadingLocationChanged,
    ref,
    setViewState,
    appLocation.locationType,
    //@ts-ignore
    appLocation.location?.name,
  ]);

  const setManualLocation = (place: any) => {
    if (place) {
      const newLocation: ManualLocation = {
        name: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      };

      appLocation.setManualLocation(newLocation);
      storage.setItem("manualLocation", JSON.stringify(newLocation), "local");
      setShowGeolocationError(null);
    } else {
      storage.removeItem("manualLocation", "local");
    }
  };

  const handleUseMyLocationClicked = () => {
    setShowGeolocationError(null);

    if (geoLocation.location) {
      setViewState("geolocation");
    } else {
      setViewState("awaiting_permission");
    }

    window.setTimeout(() => {
      appLocation.setUseGeoLocation();
      // @ts-ignore
      ref.current.value = "";
    }, 100);
  };

  const getPlaceholderString = useCallback(() => {
    switch (viewState) {
      case "custom_location":
        return intl.get("locationbar.state_custom_location");
      case "geolocation":
        return intl.get("locationbar.state_geolocation");
      case "awaiting_permission":
        return intl.get("locationbar.state_awaiting_permission");
    }
  }, [viewState]);

  useEffect(() => {
    const onInputFocusGained = (event) => {
      if (viewState === "geolocation") {
        event.target.placeholder = intl.get(
          "locationbar.state_custom_location",
        );
      }
    };

    const onInputFocusLost = (event) => {
      event.target.placeholder = getPlaceholderString();
    };

    const savedRef = ref.current;
    // @ts-ignore
    savedRef.addEventListener("focus", onInputFocusGained);
    // @ts-ignore
    savedRef.addEventListener("blur", onInputFocusLost);

    return () => {
      // @ts-ignore
      savedRef.removeEventListener("focus", onInputFocusGained);
      // @ts-ignore
      savedRef.removeEventListener("blur", onInputFocusLost);
    };
  }, [
    ref,
    geoLocation.permission,
    hasManuallySetLocation,
    viewState,
    getPlaceholderString,
  ]);

  const showSpinner =
    showLoadingProgress &&
    (viewState === "awaiting_permission" ||
      appLocation.geoLocation.location.locationState === "requested");

  return (
    <>
      <Box style={style} className={classes.root} justifyContent={"center"}>
        {!hideTitle && (
          <Typography className={classes.sectionTitle} variant={"h3"}>
            Wo willst du suchen?
          </Typography>
        )}
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="row"
          className={classes.innerContent}>
          <Button
            className={classes.button}
            color={"primary"}
            onClick={() => {
              handleUseMyLocationClicked();
            }}>
            {intl.get("locationbar.btn_use_location")}
          </Button>

          <Input
            inputRef={ref}
            defaultValue={
              appLocation.locationType === "manual"
                ? appLocation.manualLocation.name
                : undefined
            }
            endAdornment={
              showSpinner ? (
                <CircularProgress size={20} style={{ marginRight: 8 }} />
              ) : undefined
            }
            classes={{
              input: classes.textfield,
            }}
            className={classes.input}
            placeholder={getPlaceholderString()}
          />
        </Grid>
        {showGeolocationError != null && (
          <Grid item className={classes.error}>
            <Typography variant={"caption"} color={"error"}>
              {showGeolocationError}
            </Typography>
          </Grid>
        )}
      </Box>
    </>
  );
}
