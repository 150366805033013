import {
  Button,
  createStyles,
  Input,
  InputAdornment,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import intl from "react-intl-universal";
import { useHistory } from "react-router";
import { parseQueryStringToDictionary } from "../utils/utils";
import CollapsibleDateRangePicker, {
  IDateRange,
} from "./CollapsibleDateRangePicker";
import { ColorSvg } from "./ColorSvg";
import SearchKeywords from "../assets/icons/SearchKeywords";
import SearchLocation from "../assets/icons/SearchLocation";
import { useAppTheme } from "../hooks/useAppTheme";
import useStorage from "../hooks/useStorage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentRoot: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 20,
      paddingTop: 20,
    },
    input: {
      paddingLeft: 12,
      paddingRight: 12,
      backgroundColor: "#fff",
      marginBottom: 10,
    },
    placeholderStyleOverlay: {
      fontSize: theme.fontSizes.smallFont,
      color: theme.palette.primary.dark,
      opacity: 1,
      fontWeight: 400,
      "&::placeholder": {
        opacity: 1,
        color: theme.palette.primary.main,
      },
    },
    iconLeft: {
      paddingRight: 4,
    },
    iconSvg: {
      width: 16,
      height: 16,
    },
    button: {
      marginTop: 10,
    },
  }),
);

interface Props {
  onNavigateAway?: () => void;
  backgroundColor?: any;
  maxWidth?: number;
}

export default function SearchForm(props: Props) {
  const { onNavigateAway, backgroundColor, maxWidth } = props;
  const history = useHistory();

  const query =
    history.location.pathname.indexOf("/events/search") >= 0
      ? parseQueryStringToDictionary(history.location.search)
      : {};

  const theme = useTheme();
  const appTheme = useAppTheme();
  const classes = useStyles(theme);

  const storage = useStorage();

  const getInitFormData = () => {
    const hasQuery =
      (query.keywords && query.keywords.length > 0) ||
      (query.location && query.location.length > 0) ||
      (query.startDate && query.startDate.length > 0) ||
      (query.endDate && query.endDate.length > 0);

    if (hasQuery) {
      return {
        keywords: query.keywords,
        location: query.location,
        dateRange: {
          startDate: new Date(query.startDate),
          endDate: new Date(query.endDate),
        },
      };
    }

    const searchDataString = storage.getItem("search_data", "session");
    if (searchDataString) {
      const parsed = JSON.parse(searchDataString);
      return {
        ...parsed,
        dateRange: {
          startDate: new Date(parsed.dateRange.startDate),
          endDate: new Date(parsed.dateRange.endDate),
        },
      };
    }

    return {
      keywords: "",
      location: "",
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
  };

  const saveToStorage = () => {
    storage.setItem(
      "search_data",
      JSON.stringify({
        keywords,
        location,
        dateRange: {
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        },
      }),
      "session",
    );
  };

  const initData = getInitFormData();
  const [keywords, setKeywords] = useState(initData.keywords);
  const [location, setLocation] = useState(initData.location);
  const [dateRange, setDateRange] = useState<IDateRange>(initData.dateRange);

  const dataValid =
    (keywords && keywords.length > 0) ||
    dateRange ||
    (location && location.length > 0);

  const enterKeyListener = (event: any) => {
    if (event.nativeEvent.key === "Enter") {
      goToSearchPage();
    }
  };

  const goToSearchPage = () => {
    saveToStorage();

    if (onNavigateAway) {
      onNavigateAway();
    }

    if (dataValid) {
      // @ts-ignore
      history.push(
        `/events/search?keywords=${keywords || ""}&location=${
          location || ""
        }&startDate=${dateRange?.startDate.toISOString() || ""}&endDate=${
          dateRange?.endDate.toISOString() || ""
        }${appTheme.is3rdParty ? "&theme=" + appTheme.theme : ""}`,
      );
    }
  };

  return (
    <div
      className={classes.contentRoot}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
        maxWidth: maxWidth ? maxWidth : undefined,
      }}>
      <Input
        fullWidth
        defaultValue={keywords}
        classes={{
          input: classes.placeholderStyleOverlay,
        }}
        className={classes.input}
        id="keywords"
        placeholder={intl.get("searchPopover.keywords_hint")}
        onChange={(event) => {
          setKeywords(event.target.value);
        }}
        onKeyDown={enterKeyListener}
        startAdornment={
          <InputAdornment position="start" className={classes.iconLeft}>
            <ColorSvg
              svg={SearchKeywords}
              className={classes.iconSvg}
              color={theme.palette.primary.main}
            />
          </InputAdornment>
        }
      />
      <Input
        fullWidth
        defaultValue={location}
        classes={{
          input: classes.placeholderStyleOverlay,
        }}
        className={classes.input}
        id="location"
        placeholder={intl.get("searchPopover.location_hint")}
        onChange={(event) => {
          setLocation(event.target.value);
        }}
        onKeyDown={enterKeyListener}
        startAdornment={
          <InputAdornment position="start" className={classes.iconLeft}>
            <ColorSvg
              svg={SearchLocation}
              className={classes.iconSvg}
              color={theme.palette.primary.main}
            />
          </InputAdornment>
        }
      />

      <CollapsibleDateRangePicker value={dateRange} onChange={setDateRange} />

      <Button className={classes.button} fullWidth onClick={goToSearchPage}>
        {intl.get("searchPopover.button")}
      </Button>
    </div>
  );
}
