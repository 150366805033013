import { Typography, TypographyVariant } from "@material-ui/core";
import TitleDecor from "../assets/images/login-title-decor.svg";
import React from "react";

interface Props {
  text: string;
  typographyVariant: TypographyVariant;
  style?: any;
  decoratorSize?: "small" | "default";
  className?: any;
}

export default function DecoratedTypography({
  text,
  typographyVariant,
  style,
  decoratorSize,
  className,
}: Props) {
  return (
    <div style={{ ...style, display: "inline-block" }} className={className}>
      <Typography
        variant={typographyVariant}
        style={{ marginTop: 10, zIndex: 10, position: "relative" }}>
        {text}
      </Typography>
      <div
        style={{
          width: "100%",
          textAlign: "right",
          marginTop: -10,
          zIndex: 9,
          position: "relative",
        }}>
        <img
          src={TitleDecor}
          style={
            decoratorSize === "small"
              ? {
                  width: 88,
                  height: 19.39,
                  marginTop: -1,
                  marginRight: -8,
                  marginBottom: 24 - 19.39,
                  maxWidth: "100%",
                }
              : {
                  width: 118,
                  height: 26,
                  marginTop: -4,
                  marginRight: -8,
                  marginBottom: 24 - 19.39,
                  maxWidth: "100%",
                }
          }
        />
      </div>
    </div>
  );
}
