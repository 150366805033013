import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const EventOpen = props => (
  <SvgIcon data-name="Komponente 55 – 3" width={16} height={16} {...props}>
    <circle data-name="Ellipse 239" cx={8} cy={8} r={8} fill="currentColor" />
    <path
      d="M6.878 11.233L3.203 7.557l.872-.877 2.8 2.741 5.049-5.041.872.872z"
      fill="#fff"
      stroke="#fff"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default EventOpen;
