import { useEffect, useState } from "react";

export default function useVisibility() {
  const [visible, setVisible] = useState(true);

  const handleVisibilityChanged = () => {
    setVisible(document.visibilityState == "visible");
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChanged);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChanged);
    };
  }, []);

  return visible;
}
