export function sanitizeLinkUrl(url: string) {
  if (!url) {
    return url;
  }

  if (
    RegExp("(^[a-z]+://)").test(url) !== true &&
    url.startsWith("mailto:") !== true &&
    url.startsWith("tel:") !== true
  ) {
    if (url.indexOf("@") >= 0) {
      console.log("adding mailto");
      return "mailto:" + url;
    }
    return "https://" + url;
  }
  return url;
}
