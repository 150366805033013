import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

export const PageTracker = ({}) => {
  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });
  useEffect(() => {
    trackPageView({ customDimensions: analyticsState.custom_dimensions });
  }, []);
  return null;
};
