import { useEffect } from "react";
import useBotDetection from "./useBotDetection";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import * as LocationActions from "../actions/location";
import { GeoLocationPermissionState, GeoLocationState } from "../model/model";
import { getDistance } from "geolib";
import { now } from "moment";

const LOCATION_MAX_AGE_IN_MILLIS = 5 * 60 * 1000; // 5 minutes
const FETCH_LOCATION_TIMEOUT_IN_MILLIS = 4 * 1000; // 4 seconds

export default function useGeoLocationNew() {
  const isBot = useBotDetection();
  const dispatch = useDispatch();

  // safari permissions api never returns "granted" state, so we don't use it for now
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const supportsPermissionApi = navigator.permissions != null && !isSafari;

  const { reduxLocationState, authenticated } = useSelector(
    (state: RootState) => {
      return {
        reduxLocationState: state.userLocationState,
        authenticated: state.authentication.authenticated,
      };
    },
  );

  const outdated =
    now() - reduxLocationState.geoLocation.timestamp >
    LOCATION_MAX_AGE_IN_MILLIS;

  const { latitude, longitude, permission, locationState } =
    reduxLocationState?.geoLocation || {};

  const setLocation = (
    newLocation: GeolocationCoordinates,
    setType = false,
  ) => {
    if (
      longitude &&
      latitude &&
      newLocation.longitude &&
      newLocation.latitude
    ) {
      const distanceToPrevious = getDistance(
        { latitude: newLocation.latitude, longitude: newLocation.longitude },
        { latitude: latitude, longitude: longitude },
      );

      // skip if our location didn't change by at least 100 meters
      if (distanceToPrevious && distanceToPrevious < 100) {
        return;
      }
    }

    dispatch(
      LocationActions.setGeoLocation(setType, {
        latitude: newLocation.latitude,
        longitude: newLocation.longitude,
        timestamp: now(),
        locationState: "determined",
      }),
    );
  };

  const setLocationState = (newState: GeoLocationState) => {
    dispatch(LocationActions.updateGeoLocation({ locationState: newState }));
  };

  const setPermission = (newPermission: GeoLocationPermissionState) => {
    dispatch(LocationActions.updateGeoLocation({ permission: newPermission }));
  };

  const fetchLocation = (
    allowPermissionPrompt: boolean,
    forceRefresh = false,
  ) => {
    if (!allowPermissionPrompt && permission !== "granted") {
      if (supportsPermissionApi) {
        queryPermission(allowPermissionPrompt);
      }
      return;
    }

    const { geolocation } = navigator;

    if (!geolocation) {
      return;
    }

    if (forceRefresh || locationState === "init") {
      setLocationState("requested");
    }

    geolocation.getCurrentPosition(
      (position) => {
        setLocationState("determined");
        setLocation(position.coords, forceRefresh);
      },
      (error) => {
        if (
          reduxLocationState.geoLocation.locationState === "determined" &&
          !outdated
        ) {
          // don't set error state if we have a valid location
          return;
        }
        if (error.code === error.PERMISSION_DENIED) {
          setLocationState("no_permission");
        } else {
          setLocationState("not_available");
        }
      },
      {
        enableHighAccuracy: false,
        maximumAge: forceRefresh ? 0 : LOCATION_MAX_AGE_IN_MILLIS,
        timeout: FETCH_LOCATION_TIMEOUT_IN_MILLIS,
      },
    );
  };

  const queryPermission = (allowPermissionPrompt = false) => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        setPermission(result.state);

        if (
          (!reduxLocationState.geoLocation.longitude ||
            !reduxLocationState.geoLocation.latitude) &&
          locationState === "init"
        ) {
          fetchLocation(allowPermissionPrompt, false);
        }

        result.onchange = function () {
          setPermission(result.state);
        };
      });
  };

  // query permissions on mount
  useEffect(() => {
    if (isBot || reduxLocationState.selectedType !== "geolocation") {
      return;
    }

    if (supportsPermissionApi) {
      if (
        locationState === "init" ||
        !locationState ||
        reduxLocationState.geoLocation.permission !== "granted"
      ) {
        queryPermission(false);
      }
    } else {
      setPermission("not_available");
      // allowing permission prompt here shows it immediately after returning to the page from e.g. background
      // if we previously had geolocation selected
      fetchLocation(authenticated === true, false);
    }
  }, [isBot, supportsPermissionApi]);

  if (isBot) {
    return {
      location: { longitude: 0, latitude: 0, locationState: "not_available" },
      permission: "not_available",
      state: "not_available",
      fetchLocation: () => {},
    };
  }

  return {
    location: reduxLocationState.geoLocation,
    permission,
    fetchLocation,
    state: locationState,
    supportsPermissionApi,
    outdated,
  };
}
