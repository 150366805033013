import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const Search = props => (
  <SvgIcon width={23.673} height={23.673} {...props}>
    <g
      transform="translate(1.75 1.75)"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={3.5}>
      <circle data-name="Ellipse 234" cx={8.509} cy={8.509} r={8.509} />
      <path
        data-name="Linie 27"
        strokeLinecap="round"
        d="M19.448 19.448l-4.862-4.862"
      />
    </g>
  </SvgIcon>
);

export default Search;
