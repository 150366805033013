import createReducer from "./createReducer";
import { Action, ActionType, EditUserState } from "../model/model";

export const editUserState = createReducer(new EditUserState(), {
  [ActionType.GET_CATEGORIES](state: EditUserState, action: Action<any>) {
    if (action.payload.data === true) {
      const { categories } = action.payload;
      return { ...state, categories, loading: false, failed: false };
    }
    if (action.payload.loading) {
      return { ...state, loading: true };
    }
    if (action.payload.failed) {
      return {
        ...state,
        loading: false,
        failed: true,
        error: action.payload.error,
      };
    }
    return { ...state };
  },
  [ActionType.UPDATE_USER](state: EditUserState, action: Action<any>) {
    if (action.payload.error) {
      return {
        ...state,
        failed: true,
        loading: false,
        saving: false,
        error: action.payload.error,
      };
    }
    if (action.payload.saving) {
      return { ...state, saving: true, loading: true };
    }
    return {
      ...state,
      failed: false,
      loading: false,
      saving: false,
      error: null,
    };
  },
  [ActionType.UPDATE_PASSWORD](state: EditUserState, action: Action<any>) {
    if (action.payload.error) {
      return {
        ...state,
        passwordChanged: true,
        failed: true,
        loading: false,
        saving: false,
        updatePasswordError: action.payload.error,
      };
    }
    if (action.payload.saving) {
      return { ...state, saving: true, passwordChanged: false };
    }
    return {
      ...state,
      failed: false,
      loading: false,
      saving: false,
      updatePasswordError: null,
      passwordChanged: true,
    };
  },
  [ActionType.SETUP_NEEDED](state: EditUserState, action: Action<any>) {
    return { ...state, setupNeeded: true, user: action.payload };
  },
  [ActionType.RESET_UPDATE_PASSWORD](
    state: EditUserState,
    action: Action<any>,
  ) {
    return {
      ...state,
      failed: false,
      loading: false,
      saving: false,
      updatePasswordError: null,
      passwordChanged: false,
    };
  },
  [ActionType.SETUP_FINISHED](state: EditUserState, action: Action<any>) {
    return { ...state, setupNeeded: false };
  },
  [ActionType.SETUP_ERROR](state: EditUserState, action: Action<any>) {
    return { ...state, setupNeeded: false };
  },
  [ActionType.ACCOUNT_DEACTIVATION_ERROR](
    state: EditUserState,
    action: Action<any>,
  ) {
    return { ...state, cancellationError: action.payload.error };
  },
  [ActionType.LOGOUT](state: EditUserState, action: Action<any>) {
    return new EditUserState();
  },
});
