import {
  createStyles,
  Grid,
  Input,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import * as intl from "react-intl-universal";
import { ErrorView, SectionLabel } from "../components";
import PasswordInput from "../components/PasswordInput";
import GenderSelect from "../components/GenderSelect";
import { useEffect, useState } from "react";
import BirthdateInput from "./BirthdateInput";
import AddIcon from "../assets/icons/add_icon.svg";
import RemoveIcon from "../assets/icons/remove_icon.svg";
import { addAlpha } from "../utils/utils";
import clsx from "clsx";
import EditPasswordDialog from "./EditPasswordDialog";
import { useDispatch, useSelector } from "react-redux";
import * as UserActions from "../actions/user";
import { RootState } from "../reducers";
import { Child, LoginMethod } from "../model/model";
import EditEmailDialog from "./EditEmailDialog";
import { useUser } from "../hooks/useUser";
import { Error } from "../model/model";
import LoadingButton from "./LoadingButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    section: {
      paddingTop: "0px",
      paddingBottom: "12px",
    },
    saveButton: {
      paddingTop: 8,
      paddingBottom: 0,
    },
    login: {
      fontSize: theme.fontSizes.smallFont,
    },
    checkboxLabel: {
      fontSize: theme.fontSizes.smallFont,
    },
    changePassword: {
      color: theme.linkButton,
      textDecoration: "underline",
      fontSize: theme.fontSizes.verySmallFont,
      marginTop: 5,
      cursor: "pointer",
    },
    addChild: {
      textSize: theme.fontSizes.mediumFont,
      color: addAlpha(theme.palette.secondary.contrastText, 0.3),
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
    },
  }),
);

export interface Props {
  buttonLabel: string;
  buttonEnabled: (state: ProfilePageFormState) => boolean;
  onButtonClick: (state: ProfilePageFormState) => void;
  buttonProgress?: boolean;
  readonly?: boolean;
  defaultValue?: ProfilePageFormState;
  className?: any;
  error?: Error;
}

export interface ProfilePageFormState {
  birthdate: Date;
  children: Child[];
  gender: string;
}

export default function ProfilePageForm(props: Props) {
  const {
    buttonLabel,
    buttonEnabled,
    onButtonClick,
    readonly,
    defaultValue,
    className,
    error,
    buttonProgress,
  } = props;

  const [state, setState] = useState<ProfilePageFormState>({
    birthdate: defaultValue.birthdate,
    gender: defaultValue.gender,
    children:
      defaultValue.children && defaultValue.children.length > 0
        ? defaultValue.children
        : [{ child_id: undefined, birthday: undefined }],
  });

  const [showChangePasswordDialog, setShowChangePasswordDialog] =
    useState(false);

  const [showChangeEmailDialog, setShowChangeEmailDialog] = useState(false);

  const theme = useTheme();
  const classes = useStyles(theme);

  const user = useUser();
  const loginMethod = user.login_method;

  const handleChange = (name: any) => (event: any) => {
    // @ts-ignore
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleBirthdateChange = (birthdate: Date) => {
    setState({
      ...state,
      birthdate,
    });
  };

  const handleChildBirthdateChange = (birthdate: Date, index: number) => {
    const children = state.children;
    children[index].birthday = birthdate;

    setState({
      ...state,
      children: children,
    });
  };

  const onAddChildClicked = () => {
    setState({
      ...state,
      children: [
        ...state.children,
        { child_id: undefined, birthday: undefined },
      ],
    });
  };

  const onRemoveChildClicked = (index: number) => {
    if (index == 0 && state.children.length == 1) {
      setState({
        ...state,
        children: [{ child_id: undefined, birthday: undefined }],
      });
      return;
    }

    setState({
      ...state,
      children: state.children.filter((child, i) => i != index),
    });
  };

  const dispatch = useDispatch();

  const onCommitEditPasswordDialog = (
    oldPassword: string,
    newPassword: string,
  ) => {
    dispatch(UserActions.updatePassword(oldPassword, newPassword));
  };

  const onCommitEditEmailDialog = (email: string) => {
    dispatch(UserActions.updateEmail(email));
    setShowChangeEmailDialog(false);
  };

  const editUserState = useSelector((rootState: RootState) => {
    return rootState.editUserState;
  });

  useEffect(() => {
    if (
      editUserState.passwordChanged &&
      editUserState.updatePasswordError === null &&
      showChangePasswordDialog
    ) {
      setShowChangePasswordDialog(false);
    }
  }, [
    editUserState.passwordChanged,
    editUserState.updatePasswordError,
    showChangePasswordDialog,
  ]);

  const getResultChildren = () => {
    return state.children.filter((child) => child.birthday);
  };

  const handleSaveButtonClick = () => {
    const saveState = { ...state };
    saveState.children = getResultChildren();
    onButtonClick(saveState);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container alignItems="stretch" justify="center" direction="column">
        {state.children.map((child, index) => (
          <Grid
            container
            className={classes.section}
            direction="column"
            key={index + ""}>
            <SectionLabel>
              {intl.get(
                state.children.length <= 1
                  ? "profile.child_number.single"
                  : "profile.child_number." + (index + 1),
              )}
            </SectionLabel>
            <BirthdateInput
              value={child.birthday}
              readonly={readonly}
              variant={"month"}
              onChange={(value) => {
                handleChildBirthdateChange(value, index);
              }}
              endAdornment={
                <>
                  {(state.children.length > 1 || child.birthday) && (
                    <img
                      src={RemoveIcon}
                      onClick={(event) => {
                        event.stopPropagation();
                        onRemoveChildClicked(index);
                      }}
                    />
                  )}
                </>
              }
            />
          </Grid>
        ))}

        {!readonly && state.children.length < 10 && (
          <Grid item className={classes.section}>
            <div className={classes.addChild} onClick={onAddChildClicked}>
              <img src={AddIcon} style={{ paddingRight: 11 }} />
              {intl.get("profile.add_child")}
            </div>
          </Grid>
        )}

        <Grid container className={classes.section} direction="column">
          <SectionLabel>{intl.get("birthdate.label")}</SectionLabel>
          <BirthdateInput
            value={state.birthdate}
            readonly={readonly}
            variant={"year"}
            onChange={(date) => handleBirthdateChange(date)}
          />
        </Grid>

        <Grid container className={classes.section} direction="column">
          <SectionLabel>{intl.get("gender.label")}</SectionLabel>
          <GenderSelect
            id="gender"
            value={state.gender}
            readonly={readonly}
            onChange={handleChange("gender")}
          />
        </Grid>

        {loginMethod != LoginMethod.Guest && (
          <Grid container className={classes.section} direction="column">
            <SectionLabel>{intl.get("email.label")}</SectionLabel>
            <Input
              id="email"
              value={user.email}
              readOnly={readonly}
              placeholder={intl.get("email.placeholder")}
            />
            {loginMethod == LoginMethod.Email && (
              <div
                className={classes.changePassword}
                onClick={() => setShowChangeEmailDialog(true)}>
                {intl.get("profile.change_email_button")}
              </div>
            )}
          </Grid>
        )}
        {loginMethod == LoginMethod.Email && (
          <Grid item container className={classes.section} direction="column">
            <SectionLabel>{intl.get("password.label")}</SectionLabel>
            <PasswordInput
              id="password-input"
              error={false}
              readonly={true}
              value={"xxxxxxxx"}
              placeholder={intl.get("password.placeholder")}
              onChange={() => {}}
            />
            <div
              className={classes.changePassword}
              onClick={() => setShowChangePasswordDialog(true)}>
              {intl.get("profile.change_password")}
            </div>
          </Grid>
        )}

        {error && <ErrorView error={error} />}

        {readonly != true && (
          <Grid item className={classes.saveButton}>
            <LoadingButton
              onClick={handleSaveButtonClick}
              disabled={
                !buttonEnabled({ ...state, children: getResultChildren() })
              }
              buttonLabel={buttonLabel}
              loading={buttonProgress}
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      <EditPasswordDialog
        onCancel={() => setShowChangePasswordDialog(false)}
        onSave={onCommitEditPasswordDialog}
        show={showChangePasswordDialog}
        error={editUserState.updatePasswordError}
      />

      <EditEmailDialog
        onCancel={() => setShowChangeEmailDialog(false)}
        onSave={onCommitEditEmailDialog}
        show={showChangeEmailDialog}
        user={user}
      />
    </div>
  );
}
