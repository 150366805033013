import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import React, { useEffect } from "react";
import * as EventActions from "../../actions/events";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";
import {
  getCanonicalUrl,
  parseQueryStringToDictionary,
} from "../../utils/utils";
import BaseEventGridPage from "../base/BaseEventGridPage";
import useSortQuery from "../../hooks/useSortQuery";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export default function SearchPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const query = parseQueryStringToDictionary(history.location.search);
  const keywords = query.keywords || "";
  const location = query.location || "";

  const startDate =
    query.startDate && query.startDate.length > 0
      ? new Date(query.startDate)
      : undefined;

  const endDate =
    query.endDate && query.endDate.length > 0
      ? new Date(query.endDate)
      : undefined;

  const [selectedSort, setSelectedSort] = useSortQuery();

  useEffect(() => {
    dispatch(
      EventActions.searchEvents(
        {
          text: keywords,
          location_text: location,
          start: startDate,
          end: endDate,
        },
        selectedSort,
      ),
    );
  }, [dispatch, history.location.search, selectedSort]);

  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });
  // Track page view
  React.useEffect(() => {
    trackPageView({ customDimensions: analyticsState.custom_dimensions });
  }, []);

  const events = useSelector((state: RootState) => {
    return state.searchState.events;
  });

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl()} />
        <title>
          {intl.get(`seo.search.title`) +
            " | " +
            intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
        </title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      <BaseEventGridPage
        title={intl.get("search.title")}
        events={events}
        sort={selectedSort}
        onSortChanged={setSelectedSort}
      />
    </>
  );
}
