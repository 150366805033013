import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const Heart = props => (
  <SvgIcon viewBox="0 0 30 30" fillRule="evenodd" clipRule="evenodd" {...props}>
    <path
      d="M15 0c8.229 0 15 6.771 15 15s-6.771 15-15 15S0 23.229 0 15 6.771 0 15 0z"
      fill="#3c3c3b"
      fillOpacity={0.09}
      fillRule="nonzero"
    />
    <path
      d="M14.68 22.238c-2.861-1.693-8.18-4.84-8.18-9.873 0-2.667 2.195-4.861 4.862-4.861 1.339 0 2.62.553 3.538 1.527l.354.375.354-.375a4.864 4.864 0 013.538-1.527c2.667 0 4.862 2.194 4.862 4.861 0 5.033-5.319 8.18-8.177 9.871l-.33.204a.497.497 0 01-.5 0l-.321-.202z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="currentColor"
    />
  </SvgIcon>
);

export default Heart;
