import { Dispatch } from "redux";
import { ActionType, GeoLocation, ManualLocation } from "../model/model";

export const setGeoLocation =
  (setType: boolean, location: Partial<GeoLocation>) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.SET_GEOLOCATION,
      payload: setType
        ? {
            selectedType: "geolocation",
            geoLocation: location,
          }
        : {
            geoLocation: location,
          },
    });
  };

export const updateGeoLocation =
  (update: Partial<GeoLocation>) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.UPDATE_GEOLOCATION,
      payload: update,
    });
  };

export const setManualLocation =
  (setType: boolean, location: ManualLocation) =>
  async (dispatch: Dispatch<any>) => {
    localStorage.setItem("manualLocation", JSON.stringify(location));
    dispatch({
      type: ActionType.SET_MANUAL_LOCATION,
      payload: setType
        ? {
            selectedType: "manual",
            manualLocation: location,
          }
        : {
            manualLocation: location,
          },
    });
  };
