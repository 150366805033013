import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core";
import * as React from "react";
import * as BCTheme from "./theme";
import { getParamValue, getThemeConfigurationParam } from "./utils/utils";
import { ThemeStyleOverrides } from "./themeStyleOverrides";
import { Helmet } from "react-helmet";
import { createBcThemeOptions } from "./themeBase";

function withRoot(Component: React.ComponentType) {
  function WithRoot(props: object) {
    const themeConfiguration = getThemeConfigurationParam();
    let theme: any;
    if (themeConfiguration) {
      const appTheme = BCTheme.themes.bluecherries;
      appTheme.misc.originUrl = themeConfiguration.parsedConfiguration.blogURL;
      appTheme.misc.externalPartnerName =
        themeConfiguration.parsedConfiguration.blogName;
      appTheme.misc.landingPageIconHeight = 100;
      appTheme.misc.topBarIconHeight = 100;
      if (themeConfiguration.parsedConfiguration.imprintURL) {
        appTheme.misc.externalImprintLink =
          themeConfiguration.parsedConfiguration.imprintURL;
      }
      if (themeConfiguration.parsedConfiguration.privacyURL) {
        appTheme.misc.externalPrivacyLink =
          themeConfiguration.parsedConfiguration.privacyURL;
      }
      appTheme.styles.headerStyle = "centerBanner";
      if (
        themeConfiguration.parsedConfiguration.cherriesLogoVariant === "white"
      ) {
        appTheme.styles.logoStyle = "negative";
      }
      appTheme.images.banner = themeConfiguration.parsedConfiguration.logoURL;
      appTheme.images.landing = themeConfiguration.parsedConfiguration.logoURL;
      appTheme.colors.primary =
        themeConfiguration.parsedConfiguration.primaryColor;
      appTheme.colors.primaryContrast =
        themeConfiguration.parsedConfiguration.primaryContrastColor;
      appTheme.colors.primaryLight =
        themeConfiguration.parsedConfiguration.primaryLightColor;
      appTheme.colors.heroBackground =
        themeConfiguration.parsedConfiguration.primaryLightColor;
      if (themeConfiguration.parsedConfiguration.navigationBarColor) {
        appTheme.colors.topBarBackground =
          themeConfiguration.parsedConfiguration.navigationBarColor;
      }
      if (themeConfiguration.parsedConfiguration.topBarBackgroundColor) {
        appTheme.colors.topBarBackground =
          themeConfiguration.parsedConfiguration.topBarBackgroundColor;
      }
      if (themeConfiguration.parsedConfiguration.secondaryBackgroundColor) {
        appTheme.colors.secondaryBackground =
          themeConfiguration.parsedConfiguration.secondaryBackgroundColor;
      }
      if (themeConfiguration.parsedConfiguration.topBarContrastColor) {
        appTheme.colors.topBarContrast =
          themeConfiguration.parsedConfiguration.topBarContrastColor;
      }
      if (themeConfiguration.parsedConfiguration.backgroundColor) {
        appTheme.colors.background =
          themeConfiguration.parsedConfiguration.backgroundColor;
      }
      if (themeConfiguration.parsedConfiguration.primaryLightContrastColor) {
        appTheme.colors.primaryLightContrast =
          themeConfiguration.parsedConfiguration.primaryLightContrastColor;
      }
      if (themeConfiguration.parsedConfiguration.primaryDarkColor) {
        appTheme.colors.primaryDark =
          themeConfiguration.parsedConfiguration.primaryDarkColor;
      }
      if (themeConfiguration.parsedConfiguration.browserThemeColor) {
        appTheme.colors.browserThemeColor =
          themeConfiguration.parsedConfiguration.browserThemeColor;
      }
      if (themeConfiguration.parsedConfiguration.bodyTextColor) {
        appTheme.colors.bodyText =
          themeConfiguration.parsedConfiguration.bodyTextColor;
      }
      if (themeConfiguration.parsedConfiguration.highlightColor) {
        appTheme.colors.indicatorProfile =
          themeConfiguration.parsedConfiguration.highlightColor;
        appTheme.colors.indicatorFavorite =
          themeConfiguration.parsedConfiguration.highlightColor;
      }
      if (themeConfiguration.parsedConfiguration.indicatorFavoriteColor) {
        appTheme.colors.indicatorFavorite =
          themeConfiguration.parsedConfiguration.indicatorFavoriteColor;
      }
      if (themeConfiguration.parsedConfiguration.indicatorProfileColor) {
        appTheme.colors.indicatorProfile =
          themeConfiguration.parsedConfiguration.indicatorProfileColor;
      }

      const themeOptions = createBcThemeOptions(appTheme);

      theme = responsiveFontSizes(
        //@ts-ignore
        createMuiTheme({
          ...themeOptions,
          ...ThemeStyleOverrides(themeOptions),
        }),
      );
    } else {
      let selectedTheme = getParamValue("theme");
      if (!selectedTheme || selectedTheme.length == 0) {
        selectedTheme = "bluecherries";
      }

      if (selectedTheme) {
        const appTheme =
          BCTheme.themes[selectedTheme.toLowerCase()] ||
          BCTheme.themes.bluecherries;
        const themeOptions = createBcThemeOptions(appTheme);

        theme = responsiveFontSizes(
          //@ts-ignore
          createMuiTheme({
            ...themeOptions,
            ...ThemeStyleOverrides(themeOptions),
          }),
        );
      }
    }

    return (
      <MuiThemeProvider theme={theme}>
        {}
        <CssBaseline />
        <Helmet>
          <meta
            name="theme-color"
            content={theme.browserThemeColor}
            data-react-helmet="true"
          />
          <meta name="robots" content="index,follow" data-react-helmet="true" />
        </Helmet>
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
