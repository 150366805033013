import createReducer from "./createReducer";
import { Action, ActionType, CategoryState } from "../model/model";

export const categoryState = createReducer(new CategoryState(), {
  [ActionType.GET_CATEGORY_EVENTS](state: CategoryState, action: Action<any>) {
    if (action.payload.result) {
      return {
        ...state,
        events: action.payload.result,
        loading: false,
        failed: false,
        loaded: true,
        error: null,
      };
    }
    if (action.payload.loading) {
      return {
        ...state,
        loading: true,
        events: [],
        loaded: false,
      };
    }
    if (action.payload.failed) {
      return {
        ...state,
        loading: false,
        failed: true,
        loaded: false,
        error: action.payload.error,
      };
    }
    return { ...state };
  },
});
