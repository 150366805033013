import createReducer from "./createReducer";
import { Action, ActionType, FavoritesState } from "../model/model";

export const favoritesState = createReducer(new FavoritesState(), {
  [ActionType.GET_FAVORITES](state: FavoritesState, action: Action<any>) {
    if (action.payload.result) {
      return {
        ...state,
        favorites: action.payload.result,
        loading: action.payload.loading,
        failed: action.payload.failed,
        error: action.payload.error,
      };
    }
    return state;
  },
  [ActionType.ADD_TO_FAVORITES](state: FavoritesState, action: Action<any>) {
    if (action.payload.result) {
      return {
        ...state,
        favorites: action.payload.result,
      };
    }
    return state;
  },
  [ActionType.REMOVE_FROM_FAVORITES](
    state: FavoritesState,
    action: Action<any>,
  ) {
    if (action.payload.result) {
      return {
        ...state,
        favorites: action.payload.result,
      };
    }
    return state;
  },
});
