import axios from "axios";

export const trackAction = async (
  actionType: string,
  eventId: string,
  latitude: any,
  longitude: any,
) => {
  try {
    if (eventId == null || eventId === undefined) {
      return;
    }
    await axios.post(
      `/api/b2c/private/user/events/${eventId}/track`,
      {
        action: actionType,
        lat: latitude,
        long: longitude,
      },
      { withCredentials: true },
    );
  } catch (error) {}
};
