import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const EventDiscountCheckmark = props => (
  <SvgIcon data-name="Komponente 33 – 2" width={16} height={16} {...props}>
      <path d="M7.74519025,15.5 C11.9776953,15.5 15.5,11.9851307 15.5,7.75000098 C15.5,3.51487128 11.9681033,0 7.73559826,0 C3.51268517,0 0,3.51487128 0,7.75000098 C0,11.9851307 3.52229681,15.5 7.74519025,15.5 Z" id="Path" fill="#6E99FF"></path>
      <path d="M6.9049025,11.4473314 C6.60718939,11.4473314 6.36710326,11.3320972 6.13661506,11.0247861 L4.35037576,8.78716838 C4.21592595,8.62392475 4.14870105,8.43184797 4.14870105,8.2397712 C4.14870105,7.84603937 4.43679655,7.52911072 4.84014598,7.52911072 C5.08023212,7.52911072 5.27230889,7.60595323 5.48358155,7.89404873 L6.86647141,9.73791499 L9.9299839,4.78252688 C10.0836493,4.51362726 10.3045198,4.37917745 10.535008,4.37917745 C10.9191419,4.37917745 11.2648644,4.62888119 11.2648644,5.04182856 C11.2648644,5.23388567 11.1592281,5.43556039 11.0439938,5.60842162 L7.62516091,11.0247861 C7.44268207,11.3128816 7.192998,11.4473314 6.9049025,11.4473314 Z" id="Path" fill="#FFFFFF"></path>
  </SvgIcon>
);

export default EventDiscountCheckmark;
