import createReducer from "./createReducer";
import { Action, ActionType, SeoState } from "../model/model";

export const seoState = createReducer(new SeoState(), {
  [ActionType.LOAD_SEO_PAGES](state: SeoState, action: Action<any>) {
    if (action.payload.result) {
      return { ...state, seo_pages: action.payload.result.seo_pages };
    }
    if (action.payload.loading) {
      return { ...state, seo_pages: [] };
    }
    if (action.payload.failed) {
      return { ...state, seo_pages: [] };
    }
    return { ...state };
  },
  [ActionType.GET_SEO_EVENTS](state: SeoState, action: Action<any>) {
    if (action.payload.result) {
      return {
        ...state,
        events: action.payload.result,
        seo_page: action.payload.seo_page ? action.payload.seo_page : null,
        loading: false,
        failed: false,
        loaded: true,
        error: null,
      };
    }
    if (action.payload.loading) {
      return {
        ...state,
        loading: true,
        events: state.events, // keep previous events until we have new ones
        seo_page: null,
        loaded: false,
      };
    }
    if (action.payload.failed) {
      return {
        ...state,
        loading: false,
        failed: true,
        loaded: false,
        seo_page: null,
        error: action.payload.error,
      };
    }
    return { ...state };
  },
});
