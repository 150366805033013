import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const SearchLocation = props => (
  <SvgIcon width={13.184} height={16} {...props}>
    <path
      data-name="Pfad 114"
      d="M6.555 9.52a2.786 2.786 0 002.791-2.791 2.872 2.872 0 00-2.791-2.891 2.786 2.786 0 00-2.792 2.791A2.942 2.942 0 006.555 9.52zM1.869 1.944a6.626 6.626 0 019.371 9.371L6.555 16l-4.686-4.685a6.807 6.807 0 010-9.371z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default SearchLocation;
