import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import * as React from "react";
import * as intl from "react-intl-universal";
import { compose } from "redux";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { Error } from "../model/model";
import SectionLabel from "./SectionLabel";
import ErrorView from "./ErrorView";
import PasswordInput from "./PasswordInput";

const styles = (theme: Theme) =>
  createStyles({
    dialogBackdrop: {
      background: "rgba(255,255,255,0.8)",
    },
    dialogContent: {
      margin: 0,
      paddingTop: 0,
      overflow: "hidden",
    },
    section: {
      paddingTop: "20px",
      paddingBottom: "20px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "0",
      },
    },
    textField: {
      margin: 0,
    },
    optionalTopPaddingSection: {
      marginTop: "24px",
    },
  });

export interface BaseProps {
  onSave: any;
  onCancel: any;
  show: boolean;
  error?: Error | null;
}

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles>,
    BaseProps {
  fullScreen: boolean;
}

export interface State {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
  validChange: boolean;
}

class EditPasswordDialog extends React.Component<Props, State> {
  state = {
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
    validChange: false,
  };

  handleChange = (name: string) => (event: any) => {
    let newState = { ...this.state, [name]: event.target.value };
    // @ts-ignore
    this.setState({
      [name]: event.target.value,
    });

    if (
      newState.oldPassword &&
      newState.oldPassword.length > 0 &&
      newState.newPassword &&
      newState.newPassword.length > 0 &&
      newState.newPassword === newState.newPasswordRepeat
    ) {
      newState = { ...newState, validChange: true };
    } else {
      newState = { ...newState, validChange: false };
    }

    this.setState(newState);
  };

  handleCommit = (event: any) => {
    const oldPwd = this.state.oldPassword;
    const newPwd = this.state.newPassword;
    this.props.onSave(oldPwd, newPwd);
    this.setState({
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      validChange: false,
    });
  };

  handleClose = (event: any) => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      validChange: false,
    });
    this.props.onCancel();
  };

  render() {
    const { classes } = this.props;
    const { fullScreen } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        BackdropProps={{ className: classes.dialogBackdrop }}
        scroll="paper"
        open={this.props.show}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle id="form-dialog-title">
          {intl.get("profile.change_password_dialog.title")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {this.props.error && <ErrorView error={this.props.error} />}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container className={this.props.classes.section}>
                <Grid item xs={12} container direction="column">
                  <SectionLabel>
                    {intl.get(
                      "profile.change_password_dialog.header_old_password",
                    )}
                  </SectionLabel>
                  <PasswordInput
                    id="old"
                    className={classes.textField}
                    value={this.state.oldPassword}
                    placeholder={intl.get(
                      "profile.change_password_dialog.placeholder",
                    )}
                    onChange={this.handleChange("oldPassword")}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  className={classes.optionalTopPaddingSection}>
                  <SectionLabel>
                    {intl.get(
                      "profile.change_password_dialog.header_new_password",
                    )}
                  </SectionLabel>

                  <PasswordInput
                    id="old"
                    className={classes.textField}
                    value={this.state.newPassword}
                    placeholder={intl.get(
                      "profile.change_password_dialog.placeholder",
                    )}
                    onChange={this.handleChange("newPassword")}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction="column"
                  className={classes.optionalTopPaddingSection}>
                  <SectionLabel>
                    {intl.get(
                      "profile.change_password_dialog.header_new_password_repeat",
                    )}
                  </SectionLabel>

                  <PasswordInput
                    id="old"
                    className={classes.textField}
                    value={this.state.newPasswordRepeat}
                    placeholder={intl.get(
                      "profile.change_password_dialog.placeholder",
                    )}
                    onChange={this.handleChange("newPasswordRepeat")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.validChange}
            onClick={this.handleCommit}>
            {intl.get("profile.change_password_dialog.change")}
          </Button>
          <Button color="secondary" onClick={this.handleClose}>
            {intl.get("profile.change_password_dialog.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withMobileDialog(),
  withStyles(styles),
)(EditPasswordDialog) as React.ComponentType<BaseProps>;
