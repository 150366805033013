import Grid from "@material-ui/core/Grid";
import * as React from "react";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import intl from "react-intl-universal";
import { AuthenticationHelper } from "../utils/authenticationHelper";
import DecoratedTypography from "./DecoratedTypography";

interface Props {
  title: string;
  subTitle: string;
  onUseAsGuestClicked?: () => void;
  showActions?: boolean;
  children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1366,
      marginRight: "auto",
      marginLeft: "auto",
    },
    contentSection: {
      paddingLeft: 35,
      paddingRight: 35,
      paddingBottom: 65,
      paddingTop: 50,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    titleSection: {
      paddingTop: 50,
      paddingLeft: 55,
      paddingRight: 35,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 36,
      },
    },
    title: {},
    subTitle: {
      fontWeight: "normal",
      paddingRight: 80,
      [theme.breakpoints.down("xs")]: {
        paddingRight: 6,
      },
    },
    useAsGuest: {
      fontSize: theme.fontSizes.smallFont,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 38,
    },
    actionContainer: {
      marginTop: 30,
      [theme.breakpoints.down("xs")]: {
        marginTop: 25,
        display: "flex",
      },
    },
    registerButton: {
      minWidth: 168,
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        marginRight: 15,
        flex: 1,
      },
    },
    loginButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
  }),
);

export default function AuthPageLayout(props: Props) {
  const { title, subTitle, showActions, onUseAsGuestClicked, children } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction={"row"}
      justify={"center"}
      className={classes.root}>
      <Grid item xs={12} md={6} className={classes.titleSection}>
        <Grid container direction={"column"}>
          <Grid item>
            <DecoratedTypography
              text={title}
              typographyVariant={"h2"}
              className={classes.title}
            />
          </Grid>
          <Grid item>
            <Typography variant={"h3"} className={classes.subTitle}>
              {subTitle}
            </Typography>
          </Grid>
          {showActions && (
            <Grid item className={classes.actionContainer}>
              <Button
                color={"primary"}
                className={classes.registerButton}
                onClick={() => {
                  AuthenticationHelper.redirectToSignup();
                }}>
                {intl.get("login.register")}
              </Button>
              <Button
                color={"primary"}
                className={classes.loginButton}
                onClick={() => {
                  AuthenticationHelper.redirectToLogin();
                }}>
                {intl.get("login.button")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        md={6}
        className={classes.contentSection}
        direction={"column"}>
        {onUseAsGuestClicked && (
          <Grid item>
            <Box
              className={classes.useAsGuest}
              display="flex"
              onClick={() => onUseAsGuestClicked()}
              justifyContent="flex-end">
              {intl.get("login.use_as_guest")}
            </Box>
          </Grid>
        )}
        <Grid item>{children}</Grid>
      </Grid>
    </Grid>
  );
}
