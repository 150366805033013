import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import useSWR from "swr";
import axios from "axios";
import { useAppTheme } from "../hooks/useAppTheme";
import React from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    link: {
      color: theme.bodyTextColor,
      textDecoration: "underline",
      cursor: "pointer",
      maxWidth: "100%",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  }),
);

const fetcher = () => {
  return axios
    .get(`/api/events/sitemap`, {
      withCredentials: false,
    })
    .then((res) => res.data);
};

export default function SitemapPage() {
  const { data, error } = useSWR("/api/events/sitemap", fetcher);

  const classes = useStyles();
  const appTheme = useAppTheme();

  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });
  // Track page view
  React.useEffect(() => {
    trackPageView({ customDimensions: analyticsState.custom_dimensions });
  }, []);

  if (error) {
    return (
      <Container>
        <div>Sitemap konnte nicht geladen werden</div>
      </Container>
    );
  }

  return (
    <Container className={classes.root}>
      {data &&
        data[0].events.map((event) => (
          <p>
            <a
              className={classes.link}
              href={`/events/${event.company_slug}/${event.normalized_title}${appTheme.query}`}>
              {event.title}
            </a>
          </p>
        ))}
    </Container>
  );
}
