import * as React from "react";
import { applyMiddleware, createStore, Store } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import App from "./App";
import rootReducer, { RootState } from "./reducers";
import { ActionType, TrackingActionType } from "./model/model";
import { trackAction } from "./utils/tracking";

// @ts-ignore
const tracking = (_store) => (next) => (action) => {
  const { payload } = action;
  let latitude: any = null;
  let longitude: any = null;

  if (
    _store.getState().userLocationState.userLocation &&
    _store.getState().userLocationState.userLocation.coords
  ) {
    latitude = _store.getState().userLocationState.userLocation.coords.latitude;
    longitude =
      _store.getState().userLocationState.userLocation.coords.longitude;
  }

  if (action.type === ActionType.LOAD_EVENT && payload.id) {
    trackAction(TrackingActionType.opened, payload.id, latitude, longitude);
  }

  if (action.type === ActionType.SELECT_EVENT && payload.id) {
    trackAction(TrackingActionType.opened, payload.id, latitude, longitude);
  }

  if (action.type === ActionType.TRACK_ACTION) {
    trackAction(payload.actionType, payload.eventId, latitude, longitude);
  }

  next(action);
};

let middleware;

if (process.env.NODE_ENV !== "production") {
  const logger = (createLogger as any)();
  middleware = applyMiddleware(logger, thunk, tracking);
} else {
  middleware = applyMiddleware(thunk, tracking);
}

if (process.env.NODE_ENV === "development") {
  middleware = composeWithDevTools(middleware);
}

let previouslySetLocationJSON: string | null = null;
try {
  previouslySetLocationJSON = localStorage.getItem("appLocation_v2");
} catch (e) {}

const preloadedState = previouslySetLocationJSON
  ? {
      userLocationState: JSON.parse(previouslySetLocationJSON),
    }
  : {};

const store = createStore(
  rootReducer,
  preloadedState,
  middleware,
) as Store<RootState>;

class ReduxRoot extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

export default ReduxRoot;
