import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const EventDiscountCheckmark = props => (
  <SvgIcon data-name="Komponente 33 – 2" width={22} height={22} {...props}>
      <path d="M10.7433284,21.5 C16.6142225,21.5 21.5,16.6245361 21.5,10.7500014 C21.5,4.87546662 16.6009175,0 10.7300234,0 C4.87243426,0 0,4.87546662 0,10.7500014 C0,16.6245361 4.88576655,21.5 10.7433284,21.5 Z" id="Path" fill="#6E99FF"></path>
      <path d="M9.57776798,15.8785565 C9.16481109,15.8785565 8.83178839,15.7187154 8.51207895,15.2924453 L6.03439219,12.1886529 C5.84789729,11.9622182 5.75464984,11.6957891 5.75464984,11.42936 C5.75464984,10.8832159 6.15426618,10.4436052 6.71375087,10.4436052 C7.04677358,10.4436052 7.31320266,10.5501932 7.60625828,10.9498095 L9.52446034,13.5074305 L13.7738486,6.63382761 C13.9869974,6.26083781 14.2933662,6.07434291 14.6130757,6.07434291 C15.1459066,6.07434291 15.6254571,6.42070617 15.6254571,6.99350413 C15.6254571,7.25990593 15.4789293,7.53964828 15.3190882,7.77942354 L10.5768361,15.2924453 C10.3237203,15.6920616 9.97738432,15.8785565 9.57776798,15.8785565 Z" id="Path" fill="#FFFFFF"></path>
  </SvgIcon>
);

export default EventDiscountCheckmark;
