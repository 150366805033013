import { useEffect, useRef } from "react";
import { AuthenticationHelper } from "../utils/authenticationHelper";
import { isMobile } from "react-device-detect";

export default function useMobileAdSlot(name: string) {
  const hasLoadedAds = useRef(true);

  useEffect(() => {
    if (
      AuthenticationHelper.isRunningInBlogiFrame() ||
      AuthenticationHelper.isRunningInLegacyBlogiFrame()
    ) {
      return;
    }
    if (hasLoadedAds.current) {
      return;
    }

    if (isMobile) {
      // @ts-ignore
      if (typeof window.gbcallslot4479 !== undefined) {
        // @ts-ignore
        gbcallslot4479(name, "");
        // @ts-ignore
        if (window.apntag) {
          // @ts-ignore
          window.apntag.refresh([name]);
        }
      }
    }
    hasLoadedAds.current = true;
  });
}
