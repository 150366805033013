import React from "react";
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";
import { getCanonicalUrl } from "../../utils/utils";
import SearchForm from "../../components/SearchForm";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

export default function MobileSearchPage() {
  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });

  // Track page view
  React.useEffect(() => {
    trackPageView({ customDimensions: analyticsState.custom_dimensions });
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl()} />
        <title>
          {intl.get(`seo.search.title`) +
            " | " +
            intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
        </title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      <SearchForm />
    </>
  );
}
