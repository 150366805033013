import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import React from "react";
import MobileToolbar from "../../components/MobileToolbar";
import { AuthenticationHelper } from "../../utils/authenticationHelper";
import { useUser } from "../../hooks/useUser";
import { LoginMethod } from "../../model/model";
import clsx from "clsx";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {},
    listItem: {
      marginLeft: 15,
      marginRight: 15,
      paddingTop: 20,
      paddingBottom: 20,
      borderTop: "1px solid " + theme.palette.divider,
      "&.logout": {
        paddingTop: 40,
      },
    },
    link: {
      textDecoration: "none",
      fontSize: theme.fontSizes.mediumFont,
      color: theme.bodyTextColor,
    },
    logoutButton: {
      color: theme.palette.primary.main,
      fontSize: theme.fontSizes.smallFont,
      cursor: "pointer",
    },
  }),
);

export default function MobileSettingsPage() {
  const history = useHistory();

  const theme = useTheme();
  const classes = useStyles(theme);

  const user = useUser();

  const onBackClicked = () => {
    history.goBack();
  };

  const onLogoutClicked = () => {
    AuthenticationHelper.redirectToLogout();
  };

  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });
  // Track page view
  React.useEffect(() => {
    trackPageView({ customDimensions: analyticsState.custom_dimensions });
  }, []);

  return (
    <Grid container direction={"column"}>
      <Grid item className={classes.toolbar}>
        <MobileToolbar
          title={intl.get("settings.toolbar_title")}
          onBackClicked={onBackClicked}
        />
      </Grid>
      <Grid item className={classes.listItem}>
        <a
          className={classes.link}
          href="https://family-cherries.com"
          target="_blank"
          rel="noopener noreferrer">
          {intl.get("settings.contact")}
        </a>
      </Grid>

      {theme.externalPrivacyLink.length === 0 && (
        <Grid item className={classes.listItem}>
          <a
            className={classes.link}
            href="https://family-cherries.com/datenschutz"
            target="_blank"
            rel="noopener noreferrer">
            {intl.get("settings.privacy")}
          </a>
        </Grid>
      )}
      {theme.externalPrivacyLink.length > 0 && (
        <>
          <Grid item className={classes.listItem}>
            <a
              className={classes.link}
              href="https://family-cherries.com/datenschutz"
              target="_blank"
              rel="noopener noreferrer">
              {intl.get("settings.privacy")} family cherries
            </a>
          </Grid>
          <Grid item className={classes.listItem}>
            <a
              className={classes.link}
              href={theme.externalPrivacyLink}
              target="_blank"
              rel="noopener noreferrer">
              {intl.get("settings.privacy")} {theme.externalPartnerName}
            </a>
          </Grid>
        </>
      )}
      {theme.externalImprintLink.length === 0 && (
        <Grid item className={classes.listItem}>
          <a
            className={classes.link}
            href="https://family-cherries.com/impressum"
            target="_blank"
            rel="noopener noreferrer">
            {intl.get("settings.imprint")}
          </a>
        </Grid>
      )}
      {theme.externalImprintLink.length > 0 && (
        <>
          <Grid item className={classes.listItem}>
            <a
              className={classes.link}
              href="https://family-cherries.com/impressum"
              target="_blank"
              rel="noopener noreferrer">
              {intl.get("settings.imprint")} family cherries
            </a>
          </Grid>
          <Grid item className={classes.listItem}>
            <a
              className={classes.link}
              href={theme.externalImprintLink}
              target="_blank"
              rel="noopener noreferrer">
              {intl.get("settings.imprint")} {theme.externalPartnerName}
            </a>
          </Grid>
        </>
      )}
      <Grid item className={classes.listItem}>
        <a
          className={classes.link}
          href="https://family-cherries.com/ueber_uns"
          target="_blank"
          rel="noopener noreferrer">
          {intl.get("settings.about")}
        </a>
      </Grid>
      {user.login_method != LoginMethod.Guest && (
        <Grid item className={clsx(classes.listItem, "logout")}>
          <a className={classes.logoutButton} onClick={onLogoutClicked}>
            {intl.get("settings.logout")}
          </a>
        </Grid>
      )}
    </Grid>
  );
}
