import { useHistory } from "react-router";
import { useAppTheme } from "./useAppTheme";

export default function useBrowserBack() {
  const history = useHistory();
  const canGoBack = history.length > 1;
  const appTheme = useAppTheme();

  const backAction = (fallbackUrl?: string) => {
    if (fallbackUrl && !canGoBack) {
      history.push(fallbackUrl + appTheme.query);
    } else {
      history.goBack();
    }

    return canGoBack;
  };

  return backAction;
}
