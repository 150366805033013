import { ISection } from "../model/section";
import { Grid } from "@material-ui/core";
import React from "react";
import { CategorySection } from "../pages/user/EventsPage";
import useDesktopAdSlot from "../hooks/useDesktopAdSlot";

export function CategorySectionGridItem({
  section,
  onOpen,
  classes,
}: {
  section: ISection;
  onOpen: (event: any) => void;
  classes: any;
}) {
  return (
    <Grid item className={classes.categorySection} key={section.category}>
      <CategorySection
        section={section}
        classes={classes}
        onSelected={onOpen}
      />
    </Grid>
  );
}

export function CategorySectionGridItemWithAd({
  section,
  onOpen,
  classes,
}: {
  section: ISection;
  onOpen: (event: any) => void;
  classes: any;
}) {
  useDesktopAdSlot("div-ad-gds-4478-1");

  return (
    <>
      <Grid item className={classes.categorySection} key={section.category}>
        <CategorySection
          section={section}
          classes={classes}
          onSelected={onOpen}
        />
      </Grid>
      <div
        id="div-ad-gds-4478-1"
        style={{
          marginTop: 16,
          marginBottom: 16,
          maxWidth: 300,
          maxHeight: 600,
          marginLeft: "auto",
          marginRight: "auto",
        }}></div>
    </>
  );
}
