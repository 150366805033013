import * as Themes from "./themes";
import { FontWeightProperty } from "csstype";

export const themes = {
  bluecherries: Themes.BlueCherries,
  diedanners: Themes.DieDanners,
  mothersfinest: Themes.MothersFinest,
  diekleinebotin: Themes.DieKleineBotin,
  stillesbunt: Themes.StillesBunt,
  einerschreitimmer: Themes.EinerSchreitImmer,
  happymomdiary: Themes.HappyMomDiary,
  stadtmama: Themes.Stadtmama,
  margute: Themes.Margute,
  gluckeundso: Themes.GluckeUndSo,
  dielautennachbarn: Themes.DieLautenNachbarn,
  bineloveslife: Themes.BineLovesLife,
  extraprogramm: Themes.ExtraProgramm,
  mamiconnection: Themes.MamiConnection,
  familiii: Themes.Familiii,
  babyexpress: Themes.BabyExpress,
  reisetippscc: Themes.Reisetippscc,
  borderherz: Themes.Borderherz,
  mamaleben: Themes.Mamaleben,
};

// override Theme so we can add custom variables to Theme (https://material-ui.com/guides/typescript/)
declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    headerStyle: "bluecherries" | "centerBanner";
    logoStyle: "default" | "negative";
    authpageStyle: "default" | "flat";
    toolbarStyle: "default" | "elevated";
    externalPrivacyLink: string;
    externalImprintLink: string;
    externalPartnerName: string;
    bodyTextColor: string;
    banner: {
      backgroundColor: string;
      default: {
        maxWidth: number;
      };
      md: {
        maxWidth: number;
      };
      sm: {
        maxWidth: number;
      };
      xs: {
        maxWidth: number;
      };
    };
    linkButton: string;
    maxWidth: number;
    maxWidthLarge: number;
    separator: string;
    separatorDark: string;
    fontFamily: string;
    headerFontFamily: string;
    headerFontWeight: FontWeightProperty;
    importantBodyFontWeight: FontWeightProperty;
    fontSizes: {
      hugeFont: string;
      veryLargeFont: string;
      largeFont: string;
      biggerFont: string;
      mediumFont: string;
      smallFont: string;
      verySmallFont: string;
    };
    sectionHeader: {
      color: string;
    };
    profileChildContainer: {
      background: string;
    };
    authPages: {
      background: string;
      cardBackground: string;
    };
    landingPage: {
      titleColor: string;
      iconHeight: number;
      imageWidth: string;
      maxImageWidth: number;
      topImage: string;
    };
    originUrl: string;
    topBar: {
      backgroundColor: string;
      contrastText: string;
      icon: string;
      iconHeight: number;
    };
    bottomBar: {
      backgroundColor: string;
      contrastText: string;
    };
    bottomNavigation: {
      backgroundColor: string;
    };
    carousel: {
      itemSpacing: number;
      imageSize: number;
      horizontalContentPadding: number;
      heroBackground: string;
    };
    indicators: {
      profile: string;
      favorite: string;
    };
    eventPage: {
      horizontalContentPadding: number;
    };
    searchPopOver: {
      width: number;
      background: string;
    };
    heroEvent: {
      background: string;
      text: string;
    };
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    headerStyle: "bluecherries" | "centerBanner";
    authpageStyle: "default" | "flat";
    logoStyle: "default" | "negative";
    toolbarStyle: "default" | "elevated";
    externalPrivacyLink: string;
    externalImprintLink: string;
    externalPartnerName: string;
    browserThemeColor: string;
    bodyTextColor: string;
    banner: {
      backgroundColor: string;
      default: {
        maxWidth: number;
      };
      md: {
        maxWidth: number;
      };
      sm: {
        maxWidth: number;
      };
      xs: {
        maxWidth: number;
      };
    };
    linkButton: string;
    maxWidth: number;
    maxWidthLarge: number;
    separator: string;
    separatorDark: string;
    fontFamily: string;
    headerFontFamily: string;
    headerFontWeight: FontWeightProperty;
    importantBodyFontWeight: FontWeightProperty;
    fontSizes: {
      hugeFont: string;
      veryLargeFont: string;
      largeFont: string;
      biggerFont: string;
      mediumFont: string;
      smallFont: string;
      verySmallFont: string;
    };
    sectionHeader: {
      color: string;
    };
    profileChildContainer: {
      background: string;
    };
    authPages: {
      background: string;
      cardBackground: string;
    };
    originUrl: string;
    landingPage: {
      titleColor: string;
      imageWidth: string;
      maxImageWidth: number;
      topImage: string;
      iconHeight: number;
    };
    topBar: {
      backgroundColor: string;
      contrastText: string;
      icon: string;
      iconHeight: number;
    };
    bottomBar: {
      backgroundColor: string;
      contrastText: string;
    };
    bottomNavigation: {
      backgroundColor: string;
    };
    carousel: {
      itemSpacing: number;
      imageSize: number;
      horizontalContentPadding: number;
      heroBackground: string;
    };
    indicators: {
      profile: string;
      favorite: string;
    };
    eventPage: {
      horizontalContentPadding: number;
    };
    searchPopOver: {
      width: number;
      background: string;
    };
    heroEvent: {
      background: string;
      text: string;
    };
  }
}
