import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topBarHeight: {
      height: `calc(116px + env(safe-area-inset-top) / 2)`,
      [theme.breakpoints.down("md")]: {
        height: `calc(73px + env(safe-area-inset-top) / 2)`,
      },
    },
    topBarHeightSmall: {
      height: `calc(48px + env(safe-area-inset-top) / 2)`,
    },
    topBarHeightCollapsed: {
      height: `calc(73px + env(safe-area-inset-top) / 2)`,
    },
    top: {
      paddingTop: `env(safe-area-inset-top)`,
      transition: "100ms",
      backgroundColor: theme.topBar.backgroundColor,
      marginBottom: theme.toolbarStyle === "elevated" ? 15 : 0,
      paddingLeft: 55,
      paddingRight: 55,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
      "&.small": {
        paddingLeft: 5,
        paddingRight: 5,
      },
      boxShadow:
        theme.toolbarStyle === "elevated"
          ? "0px 5px 8px 0 rgb(22 22 22 / 8%)"
          : undefined,
    },
  }),
);

interface Props {
  size: "small" | "default";
  children?: any;
  style?: any;
  collapseOnScroll?: boolean;
}

const scrollCollapseThreshold = 0;

export default function TopBarContainer(props: Props) {
  const { size, children, style, collapseOnScroll } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (!collapseOnScroll || isMobile || size === "small") {
      return;
    }
    const onScroll = () =>
      setCollapsed(window.scrollY > scrollCollapseThreshold);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [collapseOnScroll, size]);

  const heightClass =
    size === "small"
      ? classes.topBarHeightSmall
      : collapsed
      ? classes.topBarHeightCollapsed
      : classes.topBarHeight;

  return (
    <Grid
      className={clsx(classes.top, heightClass, size)}
      style={style}
      container
      justify="space-between">
      {children}
    </Grid>
  );
}
