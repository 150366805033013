import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const EventDistance = props => (
  <SvgIcon data-name="Komponente 34 – 12" width={16} height={16} {...props}>
    <path
      data-name="Pfad 114"
      d="M8.205 9.012a2.638 2.638 0 002.642-2.642 2.719 2.719 0 00-2.642-2.737 2.638 2.638 0 00-2.643 2.642 2.785 2.785 0 002.643 2.737zM3.769 1.84a6.272 6.272 0 018.87 8.87l-4.434 4.44-4.436-4.44a6.443 6.443 0 010-8.87z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default EventDistance;
