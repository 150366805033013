import {
  Badge,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { LoginMethod } from "../model/model";
import * as React from "react";
import { useAppTheme } from "../hooks/useAppTheme";
import { ColorSvg } from "./ColorSvg";
import FamilyCherriesLogoNegativ from "../assets/icons/FamilyCherriesLogoNegativ";
import { useState } from "react";
import { useHistory } from "react-router";
import { useUser } from "../hooks/useUser";
import useBotDetection from "../hooks/useBotDetection";
import Search from "../assets/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import NavigationProfile from "../assets/icons/NavigationProfile";
import { AuthenticationHelper } from "../utils/authenticationHelper";
import intl from "react-intl-universal";
import SearchPopover from "./SearchPopover";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import clsx from "clsx";
import TopBarContainer from "./TopBarContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "none",
    },
    logoContainer: {
      display: "flex",
      flexDirection: "row",
      zIndex: 5,
      cursor: "pointer",
      flexGrow: 1,
      height: "100%",
    },
    topIconSvg: {
      height: "100%",
      flexGrow: 1,
      paddingTop: 8,
      paddingBottom: 8,
      [theme.breakpoints.down("md")]: {
        maxHeight: 40,
      },
      maxHeight: 56,
      marginTop: "auto",
      marginBottom: "auto",
    },
    topIconPng: {
      height: "100%",
      paddingTop: 8,
      paddingBottom: 8,
      [theme.breakpoints.down("md")]: {
        maxHeight: 40,
      },
      maxHeight: 56,
      marginTop: "auto",
      marginBottom: "auto",
    },
    hidden: {
      visibility: "hidden",
    },
    growingContainer: {
      flexGrow: 1,
      flexShrink: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    nonGrowingContainer: {
      flexGrow: 0,
      flexShrink: 1,
    },
    absoluteCenteredContainer: {
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    flexCenter: {
      alignItems: "center",
      display: "flex",
    },
    profileBadge: {
      height: 8,
      minWidth: 8,
    },
    actionIcon: {
      width: 24,
      height: 24,
    },
  }),
);

interface Props {
  size: "small" | "default";
  padding?: "small" | "default";
}

export default function TopBar(props: Props) {
  const { size } = props;

  const authentication = useSelector((state: RootState) => {
    return state.authentication;
  });

  const theme = useTheme();
  const classes = useStyles(theme);
  const appTheme = useAppTheme();
  const history = useHistory();

  return (
    <TopBarContainer size={size} collapseOnScroll>
      <Grid
        item
        className={clsx(classes.logoContainer)}
        onClick={() => {
          history.push("/" + appTheme.query);
        }}>
        <TopBarLogo
          classes={classes}
          type={theme.logoStyle}
          color={theme.topBar.contrastText}
        />
      </Grid>
      <Grid item className={classes.flexCenter}>
        <TopBarContent
          showReducedContent={isMobile || !authentication.authenticated}
          showRegisterLink={
            authentication.user?.login_method === LoginMethod.Guest
          }
          classes={classes}
        />
      </Grid>
    </TopBarContainer>
  );
}

interface LogoProps {
  type: "negative" | "default";
  color?: string;
  classes: any;
}

function TopBarLogo(props: LogoProps) {
  const { classes, type, color } = props;

  if (type === "negative") {
    return (
      <ColorSvg
        svg={FamilyCherriesLogoNegativ}
        className={classes.topIconSvg}
        color={color}
        preserveAspectRatio={"xMinYMin meet"}
      />
    );
  } else {
    return (
      <img src={"/family-cherries-logo.png"} className={classes.topIconPng} />
    );
  }
}

interface TopBarContentProps {
  showReducedContent: boolean;
  showRegisterLink: boolean;
  classes: any;
}

function TopBarContent(props: TopBarContentProps) {
  const { showReducedContent, showRegisterLink, classes } = props;

  const [searchAnchorEl, setSearchAnchorEl] = useState(undefined);

  const history = useHistory();
  const user = useUser();
  const isBot = useBotDetection();
  const appTheme = useAppTheme();
  const theme = useTheme();

  if (isBot) {
    return <></>;
  }

  if (showReducedContent) {
    return (
      <>
        <IconButton
          style={{ marginRight: 0, color: theme.topBar.contrastText }}
          onClick={() => {
            history.push("/settings" + appTheme.query);
          }}>
          <InfoIcon />
        </IconButton>
      </>
    );
  }

  const hasSetProfile = user?.b2c_profile?.completed === true;

  return (
    <>
      <IconButton
        style={{ marginRight: 12 }}
        onClick={(event) => {
          setSearchAnchorEl(event.target);
        }}>
        <ColorSvg
          svg={Search}
          color={
            theme.headerStyle === "centerBanner"
              ? theme.palette.primary.contrastText
              : theme.palette.primary.main
          }
          className={classes.actionIcon}
        />
      </IconButton>

      <IconButton
        style={{ marginRight: 28 }}
        onClick={() => {
          history.push("/profile" + appTheme.query);
        }}>
        <StyledBadge
          color={"error"}
          invisible={hasSetProfile === true}
          variant={"dot"}>
          <ColorSvg
            svg={NavigationProfile}
            color={
              theme.headerStyle === "centerBanner"
                ? theme.palette.primary.contrastText
                : theme.palette.primary.main
            }
            className={classes.actionIcon}
          />
        </StyledBadge>
      </IconButton>

      {showRegisterLink && (
        <Button
          size={"small"}
          onClick={() => {
            AuthenticationHelper.redirectToSignup();
          }}>
          {intl.get("header.guest_register_link")}
        </Button>
      )}
      <SearchPopover
        anchorElement={searchAnchorEl}
        onClose={() => {
          setSearchAnchorEl(undefined);
        }}
      />
    </>
  );
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 7,
    top: 0,
    height: 9,
    minWidth: 9,
    backgroundColor: theme.indicators.profile,
  },
}))(Badge);
