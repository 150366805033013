import isbot from "isbot";
import { useEffect, useState } from "react";
const { Crawler } = require("es6-crawler-detect");

export default function useBotDetection() {
  // careful, don't init with true (location,...)
  const [botDetected, setBotDetected] = useState(false);

  useEffect(() => {
    setBotDetected(isBot());
  }, []);

  return botDetected;
}

export function isBot() {
  const crawler = new Crawler().isCrawler(navigator.userAgent) === true;
  const bot = isbot(navigator.userAgent) === true;
  return bot || crawler;
}
