import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import * as intl from "react-intl-universal";
import withWidth from "@material-ui/core/withWidth";
import { RootState } from "../../reducers";
import {
  Authentication,
  EditUserState,
  EventState,
  LoginMethod,
  User,
} from "../../model/model";
import * as UserActions from "../../actions/user";
import * as AuthActions from "../../actions/auth";
import { Helmet } from "react-helmet";
import { AuthenticationHelper } from "../../utils/authenticationHelper";
import { getCanonicalUrl, getParamValue } from "../../utils/utils";
import AuthPageLayout from "../../components/AuthPageLayout";
import ProfilePageForm, {
  ProfilePageFormState,
} from "../../components/ProfilePageForm";
import clsx from "clsx";
import { Alert } from "@material-ui/lab";
import { PageTracker } from "../../utils/pageTracker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.authPages.background,
      flexGrow: 1,
    },
    formRoot: {
      backgroundColor: theme.authPages.cardBackground,
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      paddingLeft: theme.authpageStyle === "flat" ? 0 : 15,
      paddingRight: theme.authpageStyle === "flat" ? 0 : 15,
      paddingBottom: 20,
    },
    profileRoot: {
      maxWidth: "800px",
      marginLeft: 25,
      marginRight: 25,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    profileHeader: {
      paddingBottom: 12,
      paddingTop: theme.authpageStyle === "flat" ? 0 : 24,
      paddingRight: theme.authpageStyle === "flat" ? 0 : 15,
      paddingLeft: theme.authpageStyle === "flat" ? 0 : 15,
      display: "flex",
      flexDirection: "row",
      backgroundColor: theme.authPages.cardBackground,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    profileHeaderLabel: {
      paddingTop: 0,
    },
    startButton: {
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        marginLeft: 15,
        marginRight: 15,
      },
    },
    endButton: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        marginLeft: 15,
        marginRight: 15,
      },
    },
    section: {
      paddingTop: "20px",
      paddingBottom: "20px",
      borderBottom: "1px solid #e3e3e3",
    },
    sectionBorderless: {
      paddingBottom: 20,
    },
    titleContainer: {
      paddingTop: "40px",
      paddingBottom: "20px",
    },
    link: {
      color: theme.palette.secondary.main,
    },
    editButton: {
      marginLeft: "auto",
      marginTop: 20,
      width: 20,
      height: 20,
      cursor: "pointer",
    },
    logoutButton: {
      marginTop: 20,
      color: theme.linkButton,
      fontSize: theme.fontSizes.smallFont,
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 39,
      },
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {
  eventState: EventState;
  editUserState: EditUserState;
  authentication: Authentication;
  userActions: typeof UserActions;
  authActions: typeof AuthActions;
}

export interface State {
  accountDeletionConfirmationVisible: boolean;
  cancellationError: string | null;
  profileEditable: boolean;
  profileSaveSuccessVisible: boolean;
  profileSaveErrorVisible: boolean;
  accountActivationSuccessVisible: boolean;
}

class ProfilePage extends React.Component<Props, State> {
  state = {
    accountDeletionConfirmationVisible: false,
    cancellationError: null,
    profileEditable: true,
    profileSaveSuccessVisible: false,
    profileSaveErrorVisible: false,
    accountActivationSuccessVisible: false,
  };

  componentDidUpdate() {
    if (this.props.editUserState.cancellationError) {
      if (!this.state.cancellationError) {
        this.setState({
          cancellationError: this.props.editUserState.cancellationError,
        });
        this.props.userActions.clearAccountCancellationError();
      }
    }
  }

  componentDidMount() {
    this.props.userActions.getCategories();
    if (getParamValue("activation_success")) {
      this.setState({ accountActivationSuccessVisible: true });
    }
  }

  save = (user: User) => {
    this.props.userActions.updateUser(
      user,
      {
        birth_year: user.b2c_profile.birth_year,
        gender: user.b2c_profile.gender,
      },
      true,
      (success: boolean) => {
        if (success) {
          this.setState({
            profileSaveSuccessVisible: true,
          });
        } else {
          this.setState({
            profileSaveErrorVisible: true,
          });
        }
        window.setTimeout(() => {
          this.setState({
            profileSaveSuccessVisible: false,
            profileSaveErrorVisible: false,
          });
        }, 3000);
      },
    );
  };

  onSaveButtonClicked(formState: ProfilePageFormState) {
    this.props.editUserState.loading = true;

    const user = {
      ...this.props.authentication.user,
    };
    user.children = formState.children;
    user.b2c_profile.gender = formState.gender;
    user.b2c_profile.birth_year = formState.birthdate
      ? formState.birthdate.getFullYear()
      : undefined;
    this.save(user);
  }

  confirmAccountCancellation() {
    this.setState({ accountDeletionConfirmationVisible: false });
    AuthenticationHelper.redirectToDeactivation();
  }

  isRegisteredUser() {
    return this.props.authentication.user.login_method !== LoginMethod.Guest;
  }

  renderProfileFields() {
    const { classes } = this.props;

    let birthday: Date = undefined;

    if (this.props.authentication.user.b2c_profile.birth_year > 0) {
      birthday = new Date();
      birthday.setFullYear(
        this.props.authentication.user.b2c_profile.birth_year,
      );
    }

    const saveButtonEnabled = (state: ProfilePageFormState) => {
      let same =
        state.children.length ===
        this.props.authentication.user.children.length;

      if (same) {
        state.children.forEach((child, index) => {
          let existing =
            this.props.authentication.user.children[index].birthday;
          if (typeof existing == "string") {
            existing = new Date(existing);
          }

          if (child.birthday == existing) {
            return;
          }

          if ((child.birthday && !existing) || (!child.birthday && existing)) {
            same = false;
          } else {
            same =
              same && child.birthday.toISOString() == existing.toISOString();
          }
        });
      }
      // Always enable save button
      same = false;

      return (
        state.gender != this.props.authentication.user.b2c_profile.gender ||
        (state.birthdate ? state.birthdate.getFullYear() : undefined) !=
          this.props.authentication.user.b2c_profile.birth_year ||
        !same
      );
    };

    return (
      <Grid
        className={this.props.classes.profileRoot}
        item
        container
        direction="column">
        <Grid className={clsx(this.props.classes.profileHeader, "flat")}>
          <Typography
            variant={"h3"}
            className={this.props.classes.profileHeaderLabel}>
            {intl.get("profile.header_form")}
          </Typography>
        </Grid>

        <Grid item style={{ padding: 0 }}>
          <ProfilePageForm
            className={classes.formRoot}
            buttonLabel={intl.get("profile.button_save")}
            buttonEnabled={saveButtonEnabled}
            buttonProgress={
              this.props.editUserState.loading ||
              this.props.editUserState.saving
            }
            onButtonClick={(data) => this.onSaveButtonClicked(data)}
            readonly={!this.state.profileEditable}
            error={this.props.editUserState.error}
            defaultValue={{
              birthdate: birthday,
              children: this.props.authentication.user.children.map((child) => {
                const newChild = { ...child };
                // don't know why birthday comes as string here?...
                if (typeof newChild.birthday == "string") {
                  newChild.birthday = new Date(child.birthday);
                }
                return newChild;
              }),
              gender: this.props.authentication.user.b2c_profile.gender,
            }}
          />
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.profileSaveSuccessVisible}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ profileSaveSuccessVisible: false });
          }}>
          <Alert
            onClose={() => {
              this.setState({ profileSaveSuccessVisible: false });
            }}
            severity="success">
            Profiländerungen wurden gespeichert!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.accountActivationSuccessVisible}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ accountActivationSuccessVisible: false });
          }}>
          <Alert
            onClose={() => {
              this.setState({ accountActivationSuccessVisible: false });
            }}
            severity="success">
            Deine E-Mail-Adresse ist nun bestätigt!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.profileSaveErrorVisible}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ profileSaveErrorVisible: false });
          }}>
          <Alert
            onClose={() => {
              this.setState({ profileSaveErrorVisible: false });
            }}
            severity="error">
            Fehler beim Speichern der Profiländerungen!
          </Alert>
        </Snackbar>

        {this.isRegisteredUser() ? (
          <Grid
            container
            justify="space-between"
            className={this.props.classes.sectionBorderless}>
            <Grid
              item
              container
              xs={12}
              sm={4}
              className={this.props.classes.startButton}>
              <div
                className={this.props.classes.logoutButton}
                onClick={() => {
                  AuthenticationHelper.redirectToLogout();
                }}>
                {intl.get("profile.logout")}
              </div>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={8}
              className={this.props.classes.endButton}>
              <div
                className={classes.logoutButton}
                onClick={() => {
                  this.setState({ accountDeletionConfirmationVisible: true });
                }}>
                {intl.get("profile.cancel_account")}
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item container xs={12} justify="flex-start">
              <div
                className={classes.logoutButton}
                onClick={() => {
                  AuthenticationHelper.redirectToLogout();
                }}>
                {intl.get("profile.logout_guest_user")}
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <PageTracker />
        <Helmet>
          <link rel="canonical" href={getCanonicalUrl()} />
          <title>
            {intl.get(`seo.profile.title`) +
              " | " +
              intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
          </title>
          <meta name="robots" content="noindex" data-react-helmet="true" />
        </Helmet>

        <AuthPageLayout
          title={intl.get("profile.title")}
          subTitle={intl.get(
            this.isRegisteredUser()
              ? "profile.subtitle_logged_in"
              : "profile.subtitle_logged_out",
          )}
          showActions={!this.isRegisteredUser()}>
          <Grid container justify="center">
            {this.renderProfileFields()}
          </Grid>
        </AuthPageLayout>

        <Dialog
          open={this.state.accountDeletionConfirmationVisible}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {intl.get("profile.cancel_account_confirmation.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.get("profile.cancel_account_confirmation.message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  accountDeletionConfirmationVisible: false,
                });
              }}
              color="primary">
              {intl.get("profile.cancel_account_confirmation.cancel_action")}
            </Button>
            <Button
              onClick={() => {
                this.confirmAccountCancellation();
              }}
              color="primary"
              autoFocus>
              {intl.get("profile.cancel_account_confirmation.confirm_action")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.cancellationError != null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {intl.get("profile.cancel_account_error.title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.cancellationError}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ cancellationError: null });
              }}
              color="primary"
              autoFocus>
              {intl.get("profile.cancel_account_error.confirm_action")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    eventState: state.eventState,
    authentication: state.authentication,
    editUserState: state.editUserState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions as any, dispatch),
    authActions: bindActionCreators(AuthActions as any, dispatch),
  };
}

export default compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ProfilePage);
