import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IEvent } from "../model/event";
import * as EventActions from "../actions/events";
import useFavorites from "../hooks/useFavorites";
import { ColorSvg } from "./ColorSvg";
import Heart from "../assets/icons/Heart";
import HeartOutlined from "../assets/icons/HeartOutlined";

const useStyles = makeStyles((theme) => ({
  likeIcon: {
    cursor: "pointer",
    color: theme.indicators.favorite,
    width: 30,
    height: 30,
  },
}));

interface Props {
  event: IEvent;
}

export default function EventFavoriteButton(props: Props) {
  const { event } = props;
  const classes = useStyles();

  const favorites = useFavorites();
  const dispatch = useDispatch();

  const checkIsFavorite = () => {
    return (
      favorites != null &&
      favorites.find((favorite) => favorite.id === event.id) != null
    );
  };

  const [isFavorite, setIsFavorite] = useState<boolean>(checkIsFavorite());

  useEffect(() => {
    setIsFavorite(checkIsFavorite());
  }, [favorites]);

  const onClick = (clickEvent) => {
    clickEvent.stopPropagation();
    if (isFavorite) {
      setIsFavorite(false);
      dispatch(EventActions.removeFromFavorites(event.id));
    } else {
      setIsFavorite(true);
      dispatch(EventActions.addToFavorites(event.id));
    }
  };

  return (
    <ColorSvg
      svg={isFavorite ? Heart : HeartOutlined}
      className={classes.likeIcon}
      onClick={onClick}
    />
  );
}
