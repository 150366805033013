import { AvailabilityRange, DedicatedTime, Hour, OpeningHour } from "./model";
import intl from "react-intl-universal";

export interface IEventLocation {
  address: string;
  distance: number;
}

export interface IEvent {
  id: string;
  title: string;
  summary: string;
  description: string;
  main_media_url: string;
  main_media_url_type: string;
  price_in_cents: number;
  is_starting_price: boolean;
  price_currency: string;
  price_on_request: boolean;
  phone: string;
  website_url: string;
  booking_url: string;
  booking_url_type: string;
  is_registration_necessary: boolean;
  media_items: any;
  availability: AvailabilityRange[];
  age_from: number;
  age_to: number;
  cherries_count: number;
  email: string;
  address: string;
  longitude: string;
  latitude: string;
  recurring_rule: string | null;
  is_today_open: boolean;
  type: string;
  dedicated_times: [DedicatedTime];
  company_slug: string;
  normalized_title: string;
  distance: number;
  is_discount_active: boolean;
  discount_description_short: string;
  discount_description_long: string;
  location_independent: boolean;
  additional_locations: IEventLocation[];
}

export class Event {
  static getAgeString(event: IEvent): string {
    return intl.formatMessage(
      {
        id:
          event.age_to > 0 && event.age_to < 99
            ? "eventTags.age_from_to"
            : "eventTags.age_from",
      },
      { age_to: event.age_to, age_from: event.age_from },
    );
  }

  static getDistanceString(distance: number): string {
    if (distance < 1000) {
      const formattedDistance = Math.round(
        // round to 100m
        Math.round(distance / 100) * 100,
      );
      return intl.formatMessage(
        { id: "eventTags.distance_close" },
        { distance: formattedDistance },
      );
    } else if (distance < 5000) {
      // round to .5 km
      const formattedDistance = Math.round((distance / 1000) * 2) / 2;
      return intl.formatMessage(
        { id: "eventTags.distance_far" },
        { distance: formattedDistance },
      );
    } else {
      // round to 1km
      const formattedDistance = Math.round(distance / 1000);
      return intl.formatMessage(
        { id: "eventTags.distance_far" },
        { distance: formattedDistance },
      );
    }
  }

  static getDistanceStringWithAdditionalLocations(
    distance: number,
    location_count: number,
  ): string {
    let text = "";
    if (distance < 1000) {
      const formattedDistance = Math.round(
        // round to 100m
        Math.round(distance / 100) * 100,
      );
      text = intl.formatMessage(
        { id: "eventTags.distance_close" },
        { distance: formattedDistance },
      );
    } else if (distance < 5000) {
      // round to .5 km
      const formattedDistance = Math.round((distance / 1000) * 2) / 2;
      text = intl.formatMessage(
        { id: "eventTags.distance_far" },
        { distance: formattedDistance },
      );
    } else {
      // round to 1km
      const formattedDistance = Math.round(distance / 1000);
      text = intl.formatMessage(
        { id: "eventTags.distance_far" },
        { distance: formattedDistance },
      );
    }
    if (location_count > 0) {
      return `${text} ${intl.get("eventTags.additional_locations", {
        count: location_count,
      })}`;
    }
    return text;
  }

  static getPriceString(event: IEvent): string {
    if (event.price_in_cents) {
      //TODO: handle different locales and their default formatting
      const priceString =
        event.price_in_cents % 100 != 0 && event.price_in_cents % 10 == 0
          ? event.price_in_cents / 100 + "0"
          : event.price_in_cents / 100 + "";

      const isDecimalValue =
        event.price_in_cents % 100 !== 0 || event.price_in_cents % 10 !== 0;

      const formattedCurrency = intl.get(
        `event.currency.${event.price_currency}`,
      );

      const formattedPrice = intl.formatMessage(
        {
          id: event.is_starting_price
            ? "event.price_format_starting_price"
            : "event.price_format",
        },
        {
          price: priceString,
          currency:
            formattedCurrency && formattedCurrency.length > 0
              ? formattedCurrency
              : event.price_currency,
        },
      );
      const value = isDecimalValue ? formattedPrice : `${formattedPrice},-`;
      if (event.price_on_request) {
        return `${value} / ${intl.get("event.price_on_request")}`;
      } else {
        return value;
      }
    }

    if (event.price_on_request === true) {
      return intl.get("event.price_on_request");
    }

    return "";
  }

  static hasActiveOpeningHourRange(event: IEvent): boolean {
    for (const ar of event.availability) {
      if (ar.active_range) {
        return true;
      }
    }
    return false;
  }

  static getOpeningHoursStringForCurrentRange(event: IEvent, day: string) {
    let range: AvailabilityRange;
    for (const ar of event.availability) {
      if (ar.active_range) {
        range = ar;
      }
    }
    if (range) {
      const dayHours: OpeningHour[] = range.hours[day];
      if (dayHours) {
        const value: string[] = [];
        for (const hour of dayHours) {
          value.push(this.getOpeningHoursString(hour));
        }
        return value.join("<br/>");
      }
    }
    return "";
  }

  static getOpeningHoursString(openingHours: OpeningHour) {
    const forceMinutes =
      (openingHours.start && openingHours.start.mm != "00") ||
      (openingHours.end && openingHours.end.mm != "00");
    const start = Event.getTimeShort(openingHours.start, forceMinutes);
    const end = Event.getTimeShort(openingHours.end, forceMinutes);

    if (!start && !end) {
      return "-";
    }

    return intl.formatMessage(
      { id: "event.opening_hours_format" },
      { start, end },
    );
  }

  static getShortenedDiscountDescription(event: IEvent): string {
    if (
      event.discount_description_long &&
      event.discount_description_long.length > 0
    ) {
      return event.discount_description_long.slice(0, 60);
    }
    return "";
  }

  static hasShortenedDiscountDescription(event: IEvent): boolean {
    if (
      event.discount_description_long &&
      event.discount_description_long.length > 0
    ) {
      return event.discount_description_long.length > 60;
    }
    return false;
  }

  private static getTimeShort(time: Hour, forceMinutes: boolean) {
    if (!time) {
      return "";
    }

    if (!forceMinutes && time.mm == "00") {
      return time.HH;
    }

    return time.HH + ":" + time.mm;
  }
}
