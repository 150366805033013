import axios from "axios";
import { Dispatch } from "redux";
import {
  ActionType,
  mapErrorFromException,
  PartialB2CProfile,
  User,
} from "../model/model";

export const getCategories = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionType.GET_CATEGORIES, payload: { loading: true } });

  try {
    const res = await axios.get("/api/categories", { withCredentials: true });

    dispatch({
      type: ActionType.GET_CATEGORIES,
      payload: { data: true, categories: res.data.categories },
    });
  } catch (error) {
    if ((error as any).response) {
      dispatch({
        type: ActionType.GET_CATEGORIES,
        payload: { failed: true, error },
      });
    }
  }
};

export const updatePassword =
  (oldPassword: string, newPassword: string) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionType.UPDATE_PASSWORD, payload: { saving: true } });

    try {
      await axios.put(
        "/api/private/users/me/password",
        {
          old_password: oldPassword,
          new_password: newPassword,
        },
        { withCredentials: true },
      );

      dispatch({ type: ActionType.UPDATE_PASSWORD, payload: {} });
    } catch (error) {
      dispatch({
        type: ActionType.UPDATE_PASSWORD,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const resetUpdatePassword = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: ActionType.RESET_UPDATE_PASSWORD, payload: {} });
};

export const updateEmail =
  (email: string) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: ActionType.UPDATE_USER, payload: { saving: true } });

    try {
      const response = await axios.put(
        "/api/private/users/me/email",
        { email },
        { withCredentials: true },
      );

      const newUser = response.data;
      try {
        localStorage.setItem("user", JSON.stringify(newUser));
      } catch (error) {}

      dispatch({ type: ActionType.UPDATE_USER, payload: { user: newUser } });
    } catch (error) {
      dispatch({
        type: ActionType.UPDATE_USER,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const updateUser =
  (
    user: User,
    profile: PartialB2CProfile,
    resetEvents: boolean,
    completion: any,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.UPDATE_USER, payload: { saving: true } });
    if (resetEvents) {
      dispatch({ type: ActionType.RESET_EVENTS });
    }

    try {
      const res = await axios.put("/api/private/users/me", user, {
        withCredentials: true,
      });
      const profileRes = await axios.put(
        "/api/b2c/private/users/profile",
        {
          birth_year: profile.birth_year,
          gender: profile.gender,
          completed: true,
        },
        { withCredentials: true },
      );
      dispatch({
        type: ActionType.UPDATE_USER,
        payload: { user: res.data, profile: profileRes.data },
      });
      completion(true);
    } catch (error) {
      dispatch({
        type: ActionType.UPDATE_USER,
        payload: { error: mapErrorFromException(error) },
      });
      completion(false);
    }
  };

export const updateUserDataOnly =
  (user: User) => async (dispatch: Dispatch) => {
    dispatch({ type: ActionType.UPDATE_USER, payload: { saving: true } });
    dispatch({ type: ActionType.RESET_EVENTS });

    try {
      const res = await axios.put("/api/private/users/me", user, {
        withCredentials: true,
      });
      const profileRes = await axios.put(
        "/api/b2c/private/users/profile",
        {
          completed: true,
        },
        { withCredentials: true },
      );
      dispatch({
        type: ActionType.UPDATE_USER,
        payload: { user: res.data, profile: profileRes.data },
      });
    } catch (error) {
      dispatch({
        type: ActionType.UPDATE_USER,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const clearAccountCancellationError = () => {
  return {
    type: ActionType.ACCOUNT_DEACTIVATION_ERROR,
    payload: { error: null },
  };
};
