import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import * as React from "react";
import * as intl from "react-intl-universal";
import { connect } from "react-redux";
import { compose } from "redux";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import * as EmailValidator from "email-validator";
import { RootState } from "../reducers";
import { Authentication, Error, User } from "../model/model";
import SectionLabel from "./SectionLabel";
import ErrorView from "./ErrorView";

const styles = (theme: Theme) =>
  createStyles({
    dialogBackdrop: {
      background: "rgba(255,255,255,0.8)",
    },
    dialogContent: {
      margin: 0,
      paddingTop: 0,
      overflow: "hidden",
    },
    section: {
      paddingTop: "20px",
      paddingBottom: "20px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "0",
      },
    },
    textField: {
      margin: 0,
    },
  });

export interface BaseProps {
  onSave: any;
  onCancel: any;
  show: boolean;
  error?: Error | null;
  user: User;
}

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles>,
    BaseProps {
  fullScreen: boolean;
  authentication: Authentication;
}

class EditEmailDialog extends React.Component<Props> {
  state = {
    email: "",
    validEmail: false,
  };

  componentDidMount() {
    this.setState({ ...this.state, email: this.props.user.email });
  }

  handleChange = (email: any) => {
    this.setState({ email, validEmail: this.validateEmail(email) });
  };

  handleCommit = (event: any) => {
    this.props.onSave(this.state.email);
  };

  validateEmail = (email: any) => {
    if (email.length > 0) {
      return EmailValidator.validate(email);
    }
    return email.length > 0;
  };

  render() {
    const { classes } = this.props;
    const { fullScreen } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        BackdropProps={{ className: classes.dialogBackdrop }}
        scroll="paper"
        open={this.props.show}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="xs">
        <DialogTitle id="form-dialog-title">
          {intl.get("profile.change_email.title")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {this.props.error && <ErrorView error={this.props.error} />}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid
                container
                className={this.props.classes.section}
                spacing={0}>
                <Grid item xs={12} container direction="column">
                  <SectionLabel>
                    {intl.get("profile.change_email.header_email")}
                  </SectionLabel>

                  <TextField
                    id="old"
                    className={classes.textField}
                    value={this.state.email}
                    onChange={(event) => {
                      this.handleChange(event.target.value);
                    }}
                    margin="normal"
                    type="email"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.validEmail}
            onClick={this.handleCommit}>
            {intl.get("profile.change_email.save")}
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              this.props.onCancel();
            }}>
            {intl.get("profile.change_email.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default compose(
  withMobileDialog(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(EditEmailDialog) as React.ComponentType<BaseProps>;
