import { MobileStepper, useTheme, withStyles } from "@material-ui/core";
import React from "react";

interface Props {
  index: number;
  count: number;
}

export default function PagingDots(props: Props) {
  const { index, count } = props;

  const theme = useTheme();

  return (
    <StyledDots
      steps={count}
      activeStep={index}
      color={theme.palette.primary.main}
      backButton={undefined}
      nextButton={undefined}
      position={"static"}
    />
  );
}

const StyledDots = withStyles({
  root: {
    backgroundColor: "transparent",
  },
  dot: (props: any) => ({
    backgroundColor: props.color,
    transition: "opacity 300ms ease",
    opacity: 0.3,
    marginLeft: 5,
    marginRight: 5,
    width: 6,
    height: 6,
  }),
  dotActive: (props: any) => ({
    opacity: 1,
  }),
})(MobileStepper);
