import * as React from "react";
import * as ReactDOM from "react-dom";
import ReduxRoot from "./ReduxRoot";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { isBot } from "./hooks/useBotDetection";

const matomoInstance = createInstance({
  urlBase: "https://matomo.blue-cherries.com",
  siteId: Number.parseInt(process.env.REACT_APP_MATOMO_SITE_ID!),
  disabled:
    Number.parseInt(process.env.REACT_APP_MATOMO_SITE_ID!) === -1 || isBot(),
  linkTracking: false,
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={matomoInstance}>
      <ReduxRoot />
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
