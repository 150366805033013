import { getParamValue } from "./utils";
import { Error } from "../model/model";
import * as AuthActions from "../actions/auth";
import axios from "axios";

import { Base64 } from "js-base64";
const sha256 = require("js-sha256");

export class AuthenticationHelper {
  static generateRandomString(length: number): string {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  static generateCodeChallenge(code_verifier: string): string {
    // @ts-ignore
    return this.base64URL(sha256(code_verifier));
  }

  static base64URL(string: string): string {
    return Base64.encode(string, true);
  }

  static redirectToLoginAfterActivation() {
    this.redirectToAuthentication("login", "replace");
  }

  static redirectToAutoAuthentication() {
    this.redirectToAuthentication("auto", "replace");
  }

  static redirectToLogin() {
    this.redirectToAuthentication("login", "assign");
  }

  static redirectToSignup() {
    this.redirectToAuthentication("signup", "assign");
  }

  static redirectToLogout() {
    this.redirectToAuthentication("logout", "replace");
  }

  static redirectToDeactivation() {
    this.redirectToAuthentication("deactivate", "replace");
  }

  static checkInitialParameters() {
    if (getParamValue("code_verifier") !== null) {
      window.localStorage.setItem(
        "code_verifier",
        getParamValue("code_verifier")!,
      );
    }
    if (getParamValue("redirect_uri") !== null) {
      window.localStorage.setItem(
        "redirect_uri",
        getParamValue("redirect_uri")!,
      );
    }
  }

  static redirectToAuthentication(mode: string, historyHandling: string) {
    if (getParamValue("iframe") !== null) {
      const theme = getParamValue("theme");
      const mobile = getParamValue("mobile");
      const redirectUri = window.location.href;

      const codeVerifier = this.generateRandomString(128);

      const eventData = {
        event: "bcredirect",
        redirect_uri: redirectUri,
        auth_uri: `${process.env.REACT_APP_AUTH_URL}?mode=${mode}${
          theme !== null ? `&theme=${theme}` : ""
        }${mobile !== null ? `&mobile=${mobile}` : ""}`,
        challenge: this.generateCodeChallenge(codeVerifier),
        code_verifier: codeVerifier,
        handling: historyHandling,
        cookies: "false",
      };
      try {
        if (localStorage.getItem("access-token") !== null) {
          (eventData as any).legacy_access_token =
            localStorage.getItem("access-token");
        }
      } catch (error) {}

      window.parent.postMessage(JSON.stringify(eventData), document.referrer);
    } else {
      const codeVerifier = this.generateRandomString(128);
      window.localStorage.setItem("code_verifier", codeVerifier);
      let redirectUri = window.location.href;

      if (this.isRunningInLegacyBlogiFrame()) {
        const newParams = new URLSearchParams();
        const params = new URLSearchParams(window.location.search);
        params.forEach(function (value, key) {
          if (
            key !== "authorization_code" &&
            key !== "_code_verifier" &&
            key !== "_redirect_uri"
          ) {
            newParams.append(key, value);
          }
        });
        const encodedCodeVerifier = Base64.encode(codeVerifier, true);
        const redirectURIToSend =
          window.location.protocol +
          "//" +
          (window.location.host + "/" + window.location.pathname).replace(
            "//",
            "/",
          );
        const encodedRedirectURI = Base64.encode(redirectURIToSend, true);

        newParams.append("_code_verifier", encodedCodeVerifier);
        newParams.append("_redirect_uri", encodedRedirectURI);
        const path = (
          window.location.host +
          "/" +
          window.location.pathname +
          (newParams.toString().length > 0 ? "?" + newParams.toString() : "")
        )
          .replace("///", "/")
          .replace("//", "/");
        redirectUri = window.location.protocol + "//" + path;
        window.localStorage.setItem("redirect_uri", redirectUri);
      }

      const challenge = this.generateCodeChallenge(codeVerifier);
      const theme = getParamValue("theme");
      const mobile = getParamValue("mobile");
      // if iFrame -> send event
      const legacyAccessToken = localStorage.getItem("access-token");

      if (historyHandling === "replace") {
        const activationError = this.activationResponseErrorToHandle();
        if (activationError) {
          window.location.replace(
            `${
              process.env.REACT_APP_AUTH_URL
            }?mode=${mode}&error=${activationError}&redirect_uri=${encodeURIComponent(
              redirectUri,
            )}&code_challenge=${challenge}${
              theme !== null ? `&theme=${theme}` : ""
            }${mobile !== null ? `&mobile=${mobile}` : ""}${
              legacyAccessToken !== null
                ? `&legacy_access_token=${legacyAccessToken}`
                : ""
            }`,
          );
        } else {
          window.location.replace(
            `${
              process.env.REACT_APP_AUTH_URL
            }?mode=${mode}&redirect_uri=${encodeURIComponent(
              redirectUri,
            )}&code_challenge=${challenge}${
              theme !== null ? `&theme=${theme}` : ""
            }${mobile !== null ? `&mobile=${mobile}` : ""}${
              legacyAccessToken !== null
                ? `&legacy_access_token=${legacyAccessToken}`
                : ""
            }`,
          );
        }
      } else {
        window.location.assign(
          `${
            process.env.REACT_APP_AUTH_URL
          }?mode=${mode}&redirect_uri=${encodeURIComponent(
            redirectUri,
          )}&code_challenge=${challenge}${
            theme !== null ? `&theme=${theme}` : ""
          }${mobile !== null ? `&mobile=${mobile}` : ""}${
            legacyAccessToken !== null
              ? `&legacy_access_token=${legacyAccessToken}`
              : ""
          }`,
        );
      }
    }
  }

  static hasLegacyAccessTokenToMigrate(): boolean {
    try {
      return (
        localStorage.getItem("access-token") !== null &&
        getParamValue("access_token") === null
      );
    } catch (error) {
      return false;
    }
  }

  static activationResponseErrorToHandle(): string {
    return getParamValue("activation_error");
  }

  static hasAuthorizationCodeResponseToHandle(): boolean {
    return (
      getParamValue("authorization_code") !== null &&
      this.isRunningInBlogiFrame() === false
    );
  }

  static handleAuthorizationCodeResponse() {
    const codeVerifier = window.localStorage.getItem("code_verifier")!;
    let redirectUri = window.location.href;
    if (getParamValue("iframe") !== null) {
      redirectUri = window.localStorage.getItem("redirect_uri")!;
    }

    if (getParamValue("authorization_code") !== null) {
      window.localStorage.setItem(
        "authorization_code",
        getParamValue("authorization_code")!,
      );
      window.localStorage.setItem("redirect_uri", redirectUri);
      window.localStorage.setItem("code_verifier", codeVerifier);

      const newParams = new URLSearchParams();
      const params = new URLSearchParams(window.location.search);
      params.forEach(function (value, key) {
        if (
          key !== "authorization_code" &&
          key !== "redirect_uri" &&
          key !== "code_verifier" &&
          key !== "_code_verifier" &&
          key !== "code_challenge"
        ) {
          newParams.append(key, value);
        }
      });

      let path =
        window.location.host +
        "/" +
        window.location.pathname +
        (newParams.toString().length > 0 ? "?" + newParams.toString() : "");
      path = path.replace("//", "/");
      window.location.replace(window.location.protocol + "//" + path);
    }
  }

  static isRunningInBlogiFrame(): boolean {
    if (getParamValue("iframe") !== null) {
      return true;
    }
    if (getParamValue("theme") !== null && getParamValue("iframe") === null) {
      return true;
    }
    if (
      getParamValue("configuration") !== null &&
      getParamValue("iframe") === null
    ) {
      return true;
    }
    return false;
  }

  static isRunningInMobileApp(): boolean {
    // @ts-ignore
    if (window.CherriesBridge) {
      return true;
    }
    // @ts-ignore
    const wk = window.webkit;
    if (wk && wk.messageHandlers && wk.messageHandlers.cherries) {
      return true;
    }

    return getParamValue("mobile") !== null;
  }

  static isRunningInLegacyBlogiFrame(): boolean {
    if (getParamValue("theme") !== null && getParamValue("iframe") === null) {
      return true;
    }
    return false;
  }

  static hasAuthorizationCodeToExchange(): boolean {
    try {
      return (
        window.localStorage.getItem("authorization_code") !== null ||
        (getParamValue("authorization_code") !== null &&
          this.isRunningInBlogiFrame())
      );
    } catch (error) {
      return false;
    }
  }

  static exchangeAuthorizationCode(
    actions: typeof AuthActions,
    onFinish: (success: boolean) => void,
  ) {
    let authorizationCode = window.localStorage.getItem("authorization_code")!;
    let redirectUri = window.localStorage.getItem("redirect_uri")!;
    let codeVerifier = window.localStorage.getItem("code_verifier")!;
    if (this.isRunningInBlogiFrame()) {
      authorizationCode = getParamValue("authorization_code")!;
      if (getParamValue("redirect_uri") !== null) {
        redirectUri = getParamValue("redirect_uri")!;
      }
      if (getParamValue("code_verifier") !== null) {
        codeVerifier = getParamValue("code_verifier")!;
      }
      if (getParamValue("_code_verifier") !== null) {
        codeVerifier = Base64.decode(getParamValue("_code_verifier")!);
      }
      if (getParamValue("_redirect_uri") !== null) {
        redirectUri = Base64.decode(getParamValue("_redirect_uri")!);
      }
    }
    if (!redirectUri) {
      redirectUri = window.location.href;
    }

    const runningInLegacyiFrame = this.isRunningInLegacyBlogiFrame();
    const iFrameParam = getParamValue("iframe");

    actions.exchangeAuthorizationCode(
      authorizationCode,
      redirectUri,
      codeVerifier,
      (token: string | null) => {
        if (runningInLegacyiFrame) {
          // We have been passed a theme (most likely in blog) but not the new parameter iframe (which indicates the new wordpress plugin)
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        }

        onFinish(true);

        window.localStorage.removeItem("authorization_code");
        if (runningInLegacyiFrame === false) {
          window.localStorage.removeItem("redirect_uri");
          window.localStorage.removeItem("code_verifier");
          window.localStorage.removeItem("access-token");
        } else {
          if (token !== null) {
            window.localStorage.setItem("access-token", token);
          }
        }

        if (iFrameParam !== null) {
          const newParams = new URLSearchParams();
          const params = new URLSearchParams(window.location.search);
          params.forEach(function (value, key) {
            if (
              key !== "authorization_code" &&
              key !== "redirect_uri" &&
              key !== "code_verifier"
            ) {
              newParams.append(key, value);
            }
          });

          let path =
            window.location.host +
            "/" +
            window.location.pathname +
            "?" +
            newParams.toString();
          path = path.replace("//", "/");
          window.location.replace(window.location.protocol + "//" + path);
        }
      },
      (error: Error | null) => {
        onFinish(false);
        window.localStorage.removeItem("authorization_code");
        window.localStorage.removeItem("redirect_uri");
        window.localStorage.removeItem("code_verifier");
        window.localStorage.removeItem("access-token");
      },
    );
  }
}
