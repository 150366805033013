import {
  Action,
  ActionType,
  AnalyticsState,
  prepareCustomDimensionsForUser,
} from "../model/model";

import createReducer from "./createReducer";

export const analyticsState = createReducer(
  {
    custom_dimensions: [],
  },
  {
    [ActionType.FETCH_USER](state: AnalyticsState, action: Action<any>) {
      if (action.payload) {
        console.log(`Received new user payload after fetch`);
        return {
          custom_dimensions: prepareCustomDimensionsForUser(action.payload),
        };
      }
      return state;
    },
    [ActionType.UPDATE_USER](state: AnalyticsState, action: Action<any>) {
      if (action.payload.user) {
        console.log(`Received new user payload after update`);
        return {
          custom_dimensions: prepareCustomDimensionsForUser(
            action.payload.user,
          ),
        };
      }
      return state;
    },
  },
);
