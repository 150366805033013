import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import React, { useEffect } from "react";
import * as EventActions from "../../actions/events";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import { Helmet } from "react-helmet";
import { getCanonicalUrl } from "../../utils/utils";
import BaseEventGridPage from "../base/BaseEventGridPage";
import LoadingView from "../../components/LoadingView";
import useSortQuery from "../../hooks/useSortQuery";
import { useAppTheme } from "../../hooks/useAppTheme";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export default function SeoPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const appTheme = useAppTheme();
  const seoPageId = history.location.pathname.substring(
    history.location.pathname.lastIndexOf("/") + 1,
  );

  const [selectedSort, setSelectedSort] = useSortQuery();

  useEffect(() => {
    dispatch(EventActions.getSeoEvents(seoPageId, selectedSort));
  }, [dispatch, selectedSort, seoPageId]);

  const { trackPageView } = useMatomo();
  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });
  // Track page view
  React.useEffect(() => {
    trackPageView({ customDimensions: analyticsState.custom_dimensions });
  }, []);

  const seoState = useSelector((state: RootState) => {
    return state.seoState;
  });

  // redirect if not found
  if (seoState.failed) {
    history.push("/events" + appTheme.query);
  }

  const events = seoState.events;
  const seoPage = seoState.seo_page;

  if (seoState.loading) {
    return <LoadingView message={intl.get("events.loading")} />;
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCanonicalUrl()} />
        <title>
          {(seoPage ? seoPage.title : intl.get(`seo.categories.title`)) +
            " | " +
            intl.get(`app.name.${process.env.REACT_APP_FLAVOR}`)}
        </title>
        <meta
          name="description"
          content={
            seoPage && seoPage.meta_description.length > 0
              ? seoPage.meta_description
              : intl.get("app.default_description")
          }
          data-react-helmet="true"
        />
      </Helmet>

      <BaseEventGridPage
        title={seoPage ? seoPage.headline : ""}
        subtitle={seoPage ? seoPage.description : ""}
        footer={seoPage ? seoPage.bottom_description : ""}
        events={events}
        sort={selectedSort}
        onSortChanged={setSelectedSort}
      />
    </>
  );
}
