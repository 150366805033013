import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const ScrollArrowActive = props => (
  <SvgIcon width={10.828} height={18.828} {...props}>
    <path
      d="M1.414 17.414l8-8-8-8"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
    />
  </SvgIcon>
);

export default ScrollArrowActive;
