import { Action, ActionType, Authentication } from "../model/model";

import createReducer from "./createReducer";

export const authentication = createReducer(
  {
    needsToValidate: true,
    user: null,
    loading: false,
    authenticated: false,
    waitingForActivation: false,
  },
  {
    [ActionType.FETCHING_USER](state: Authentication, action: Action<any>) {
      return { authenticated: false, loading: true, needsToValidate: false };
    },
    [ActionType.FETCH_USER](state: Authentication, action: Action<any>) {
      return {
        user: action.payload,
        authenticated: true,
        loading: false,
        needsToValidate: false,
      };
    },
    [ActionType.SETUP_NEEDED](state: Authentication, action: Action<any>) {
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: action.payload,
      };
    },
    [ActionType.LOGOUT](state: Authentication, action: Action<any>) {
      return {
        user: null,
        authenticated: false,
        loading: false,
        needsToValidate: false,
      };
    },
    [ActionType.UPDATE_USER](state: Authentication, action: Action<any>) {
      if (action.payload.error) {
        return { ...state, error: action.payload.error };
      }
      if (action.payload.user != null) {
        Object.assign(state.user, action.payload.user);
      }

      if (action.payload.profile != null) {
        Object.assign(state.user.b2c_profile, action.payload.profile);
      }
      return { ...state, error: null };
    },
  },
);
