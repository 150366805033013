import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "column",
    marginTop: "auto",
    marginBottom: "auto",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    flexDirection: "column",
    marginTop: "auto",
    marginBottom: "auto",
  },
  title: {},
  message: {
    marginTop: 2,
  },
  button: {
    marginTop: 24,
    marginBottom: 196,
  },
}));

interface Props {
  onRetryClicked: () => void;
  message: string;
}

export default function ErrorRetryView(props: Props) {
  const classes = useStyles();

  const { onRetryClicked, message } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography variant={"h3"} className={classes.title}>
          {intl.get("error.generic_title")}
        </Typography>
        <Typography variant={"subtitle1"} className={classes.message}>
          {message}
        </Typography>
        <Button onClick={onRetryClicked} className={classes.button}>
          {intl.get("error.button_retry")}
        </Button>
      </div>
    </div>
  );
}
