import React, { useEffect, useRef, useState } from "react";
import { resizedImageURL, resizedImageURLAsWebp } from "../model/model";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { Event, IEvent } from "../model/event";
import clsx from "clsx";
import EventFavoriteButton from "./EventFavoriteButton";
import EventTag from "./EventTag";
import { MuiBreakpoint, useMuiBreakpoint } from "../hooks/useMuiBreakpoint";
import EventDistance from "../assets/icons/EventDistance";
import EventAge from "../assets/icons/EventAge";
import intl from "react-intl-universal";
import EventDiscountCheckmark from "../assets/icons/EventDiscountCheckmark";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    backgroundColor: "transparent",
    borderRadius: 10,
    display: "grid",
    color: "unset",
    textDecoration: "unset",
  },
  shadow: {
    boxShadow: "0px 5px 10px rgba(0,0,0,0.16)",
  },
  eventNameHeroContainer: {
    // needs extra container bc line-clamp doesn't work with paddings
    marginTop: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 17,
    paddingBottom: 17,
    paddingLeft: 23,
    paddingRight: 23,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.heroEvent.background,
  },
  eventNameHero: {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    color: theme.heroEvent.text,
    width: "100%",
    maxWidth: "100%",
    maxLines: 2,
    lineClamp: 2,
    paddingBottom: "0.08em", // without this, letters get cut. don't add more padding, line-clamp doesn't support that
    "-webkit-line-clamp": 2,
    "line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  eventImageBox: {
    gridRow: 1,
    gridColumn: 1,
    display: "grid",
  },
  eventImage: {
    gridRow: 1,
    gridColumn: 1,
    borderRadius: 10,
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  likeIcon: {
    gridRow: 1,
    gridColumn: 1,
    marginLeft: "auto",
    marginTop: 20,
    marginRight: 20,
    zIndex: 99,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      marginRight: 10,
    },
  },
  eventName: {
    marginTop: 30,
    paddingRight: 19,
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxLines: 2,
    lineClamp: 2,
    "-webkit-line-clamp": 2,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    paddingBottom: 17,
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      paddingBottom: 5,
    },
    lineHeight: "1.8rem",
    minHeight: "3.6rem",
    height: "3.6rem",
  },
  eventSummary: {
    fontSize: theme.fontSizes.mediumFont,
    paddingTop: 2,
    paddingRight: 19,
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "1.8rem",
    minHeight: "3.6rem",
    height: "3.6rem",
    maxLines: 2,
    lineClamp: 2,
    "-webkit-line-clamp": 2,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
  },
  eventTags: {
    marginTop: 32,
    paddingRight: 19,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
  },
  eventTagDistance: {
    marginRight: 4,
  },
  eventTagDiscount: {
    marginRight: 4,
  },
  [theme.breakpoints.down("sm")]: {
    marginRight: 4,
  },
}));

interface EventCardProps {
  event: IEvent;
  variant: "hero" | "grid" | "carousel";
  dropShadow?: boolean;
  style?: any;
  onSelected: (event: IEvent) => void;
  hideFavoriteIcon?: boolean;
  hideTags?: boolean;
  onImageLoaded?: (event: IEvent) => void;
  noLazyLoading?: boolean;
  largeImage: boolean;
}

const EventCard = (props: EventCardProps) => {
  const classes = useStyles();

  const {
    event,
    onSelected,
    variant,
    dropShadow,
    style,
    hideFavoriteIcon,
    hideTags,
    onImageLoaded,
    noLazyLoading,
    largeImage,
  } = props;

  const muiBreakpoint = useMuiBreakpoint();
  const isSmall = muiBreakpoint <= MuiBreakpoint.sm;

  const cardSizeMap = {
    hero: { width: isSmall ? 344 : 380, height: isSmall ? 344 : 380 },
    grid: { width: isSmall ? 165 : 280, height: isSmall ? 165 : 280 },
    carousel: { width: isSmall ? 146 : 280, height: isSmall ? 146 : 280 },
  };

  const cardWidth = cardSizeMap[variant].width;
  const cardHeight = cardSizeMap[variant].height;

  const rootStyle = {
    width: cardWidth,
  };

  const cardSizeStyle = {
    width: "100%",
    height: cardHeight,
    maxHeight: "90vw",
  };

  const webpImageURL = resizedImageURLAsWebp(
    event.main_media_url,
    !isMobile,
    window.devicePixelRatio,
    largeImage,
  );

  const imageUrl = resizedImageURL(
    event.main_media_url,
    !isMobile,
    window.devicePixelRatio,
    largeImage,
  );

  const imgRef = useRef();

  const [imageLoading, setImageLoading] = useState(true);
  const [imageCached, setImageCached] = useState(false);

  const handleDoneLoadingImage = () => {
    setImageLoading(false);

    if (onImageLoaded) {
      onImageLoaded(event);
    }
  };

  /*
  useEffect(() => {
    // @ts-ignore
    if (imgRef?.current?.complete === true) {
      setImageCached(true);
    }
  }, [imgRef]);*/

  useEffect(() => {
    const imgEle = document.createElement("img");
    imgEle.src = webpImageURL || imageUrl;
    const cached = imgEle.complete;

    // @ts-ignore
    if (cached === true) {
      setImageCached(true);
    }
  }, [webpImageURL, imageUrl]);

  const shouldLoadLazy = !imageCached && !noLazyLoading;

  return (
    <a
      style={{ ...style, ...rootStyle }}
      href={`/events/${event.company_slug}/${event.normalized_title}`}
      onClick={(ev) => {
        ev.preventDefault();
        onSelected(event);
        return false;
      }}
      className={
        dropShadow == true ? clsx(classes.root, classes.shadow) : classes.root
      }>
      <Box className={classes.eventImageBox} style={{ ...rootStyle }}>
        {shouldLoadLazy && (
          <img
            className={classes.eventImage}
            style={{
              ...cardSizeStyle,
              objectFit: "cover",
              zIndex: 98,
              opacity: imageLoading ? 1 : 0,
              transition: "opacity 200ms",
            }}
            draggable="false"
            src={"/image_loading_placeholder.svg"}
            alt=""
          />
        )}
        <picture draggable="false" style={{ gridColumn: 1, gridRow: 1 }}>
          {webpImageURL != null && (
            <source
              style={cardSizeStyle}
              type="image/webp"
              draggable="false"
              srcSet={webpImageURL}
            />
          )}
          <img
            ref={imgRef}
            className={classes.eventImage}
            style={
              imageLoading
                ? { ...cardSizeStyle }
                : {
                    ...cardSizeStyle,
                    objectFit: "cover",
                  }
            }
            loading={shouldLoadLazy ? "lazy" : undefined}
            draggable="false"
            onLoad={() => handleDoneLoadingImage()}
            src={resizedImageURL(
              event.main_media_url,
              !isMobile,
              window.devicePixelRatio,
              largeImage,
            )}
            alt=""
          />
        </picture>
      </Box>

      {variant == "hero" && (
        <div
          style={{
            gridRow: 1,
            gridColumn: 1,
            zIndex: 99,
            marginTop: "auto",
            width: cardWidth,
            maxWidth: cardWidth,
            display: "block",
          }}>
          <div className={classes.eventNameHeroContainer}>
            <Typography variant={"h3"} className={classes.eventNameHero}>
              {event.title}
            </Typography>
          </div>
        </div>
      )}

      {hideFavoriteIcon != true && (
        <div className={classes.likeIcon}>
          <EventFavoriteButton event={event} />
        </div>
      )}

      {variant != "hero" && (
        <Typography
          variant={"h3"}
          className={classes.eventName}
          style={{ width: cardSizeStyle.width }}>
          {event.title}
        </Typography>
      )}

      {variant != "hero" && (
        <div className={classes.eventSummary} style={{ maxWidth: cardWidth }}>
          {event.summary}
        </div>
      )}

      {variant != "hero" && !hideTags && (
        <div className={classes.eventTags} style={{ maxWidth: cardWidth }}>
          {event.distance && (
            <EventTag
              className={classes.eventTagDistance}
              text={Event.getDistanceStringWithAdditionalLocations(
                event.distance,
                event.additional_locations.length,
              )}
              image={EventDistance}
              imageStyle={{ marginTop: 2 }}
            />
          )}
          {event.location_independent && (
            <EventTag
              className={classes.eventTagDistance}
              text={intl.get("eventTags.independent_locations", {
                count: event.additional_locations.length,
              })}
              image={EventDistance}
              imageStyle={{ marginTop: 2 }}
            />
          )}
          {event.is_discount_active && (
            <EventTag
              className={classes.eventTagDiscount}
              text={intl.get(`event.discount_available_short`)}
              image={EventDiscountCheckmark}
              imageStyle={{ marginTop: 2 }}
            />
          )}
          <EventTag
            text={Event.getAgeString(event)}
            image={EventAge}
            imageStyle={{ marginTop: 1 }}
          />
        </div>
      )}
    </a>
  );
};

export default EventCard;
