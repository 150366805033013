import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const HeartOutlined = props => (
  <SvgIcon viewBox="0 0 30 31" fillRule="evenodd" clipRule="evenodd" {...props}>
    <circle cx={15} cy={15} r={15} fill="none" />
    <path
      d="M14.226 23.22C11.175 21.414 5.5 18.057 5.5 12.689v-.002c0-2.845 2.341-5.186 5.186-5.186 1.43 0 2.797.591 3.777 1.632l.377.4.377-.4a5.188 5.188 0 013.777-1.632c2.845 0 5.186 2.341 5.186 5.186v.002c0 5.368-5.674 8.725-8.722 10.531l-.353.21a.554.554 0 01-.53 0l-.349-.21z"
      fill="none"
      stroke="#fff"
      strokeWidth={2}
    />
  </SvgIcon>
);

export default HeartOutlined;
