import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const EventAge = props => (
  <SvgIcon data-name="Komponente 33 – 2" width={16} height={16} {...props}>
    <path
      data-name="Vereinigungsmenge 1"
      d="M0 16v-2c0-2.2 3.6-4 8-4s8 1.8 8 4v2zM4 4a4 4 0 114 4 4 4 0 01-4-4z"
    />
  </SvgIcon>
);

export default EventAge;
