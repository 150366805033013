import {
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { ColorSvg } from "./ColorSvg";
import NavigationSearch from "../assets/icons/NavigationSearch";
import NavigationProfile from "../assets/icons/NavigationProfile";
import NavigationHome from "../assets/icons/NavigationHome";
import { useAppTheme } from "../hooks/useAppTheme";

interface Props {
  style: any;
}

export default function MobileBottomNavigation(props: Props) {
  const { style } = props;

  const theme = useTheme();
  const history = useHistory();

  const appTheme = useAppTheme();

  const [selectedIndex, setSelectedIndex] = useState(
    history.location.pathname.startsWith("/search")
      ? 1
      : history.location.pathname.startsWith("/profile")
      ? 2
      : 0,
  );

  const onHomeClicked = () => {
    goToPage("/events");
  };

  const onSearchClicked = () => {
    goToPage("/search");
  };

  const onProfileClicked = () => {
    goToPage("/profile");
  };

  const goToPage = (path: string) => {
    if (history.location.pathname === path) {
      return;
    }
    history.push(path + appTheme.query);
  };

  return (
    <BottomNavigation
      style={{
        ...style,
        backgroundColor: theme.topBar.backgroundColor,
        boxShadow: "1px -1px 6px rgba(0,0,0,0.12)",
      }}
      showLabels
      value={selectedIndex}
      onChange={(event, newValue) => {
        setSelectedIndex(newValue);
      }}>
      <BottomNavigationAction
        icon={
          <ColorSvg
            svg={NavigationHome}
            color={theme.topBar.contrastText}
            style={{ opacity: selectedIndex == 0 ? 1 : 0.5 }}
          />
        }
        onClick={onHomeClicked}
      />
      <BottomNavigationAction
        icon={
          <ColorSvg
            svg={NavigationSearch}
            color={theme.topBar.contrastText}
            style={{ opacity: selectedIndex == 1 ? 1 : 0.5 }}
          />
        }
        onClick={onSearchClicked}
      />
      <BottomNavigationAction
        icon={
          <ColorSvg
            svg={NavigationProfile}
            color={theme.topBar.contrastText}
            style={{ opacity: selectedIndex == 2 ? 1 : 0.5 }}
          />
        }
        onClick={onProfileClicked}
      />
    </BottomNavigation>
  );
}
