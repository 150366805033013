import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const NavigationHome = props => (
  <SvgIcon width={21} height={22.33} {...props}>
    <path
      data-name="Pfad 132"
      d="M9.121 1.076L.731 7.893a.626.626 0 00-.231.485v12.828a.625.625 0 00.625.625H6.75a.625.625 0 00.625-.625v-8.125A.625.625 0 018 12.456h5a.625.625 0 01.625.625v8.125a.625.625 0 00.625.625h5.625a.625.625 0 00.625-.625V8.378a.626.626 0 00-.231-.485l-8.39-6.817a2.189 2.189 0 00-2.758 0z"
      fill="currentColor"
      stroke="currentColor"
    />
  </SvgIcon>
);

export default NavigationHome;
