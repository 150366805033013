import { Event, IEvent } from "../../model/event";
import {
  createBookingUrlLink,
  EventType,
  getTargetForBookingUrl,
  TrackingActionType,
} from "../../model/model";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import intl from "react-intl-universal";
import { commaAddressLineSplit } from "../../utils/utils";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import * as TrackingAction from "../../actions/tracking";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import EventOpeningHours from "./EventOpeningHours";
import { sanitizeLinkUrl } from "../../utils/urlUtils";
import EventDedicatedTimes from "./EventDedicatedTimes";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import EventDiscountCheckmarkLarge from "../../assets/icons/EventDiscountCheckmarkLarge";
import { useState } from "react";
import EventDistance from "../../assets/icons/EventDistance";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoBoxSection: {
      paddingTop: 20,
      paddingBottom: 20,
      borderBottom: theme.separator,
      "&.last": {
        borderBottom: "none",
      },
      fontSize: theme.fontSizes.smallFont,
    },
    infoBoxHeader: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.smallFont,
    },
    eventPreregistration: {
      fontSize: theme.fontSizes.mediumFont,
      paddingTop: 20,
    },
    rruleText: {
      marginTop: 15,
      flexWrap: "nowrap",
      display: "flex",
      alignItems: "flex-start",
      alignContent: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "column",
    },
    rruleTextRow: {
      flexWrap: "nowrap",
      display: "flex",
      alignItems: "flex-end",
      alignContent: "flex-end",
      justifyContent: "flex-end",
    },
    rruleTextNormal: {
      fontWeight: "normal",
      display: "inline",
      marginLeft: 5,
    },
    rruleTextBold: {
      fontWeight: theme.importantBodyFontWeight,
      display: "inline",
      marginLeft: 5,
    },
    link: {
      color: theme.bodyTextColor,
      textDecoration: "underline",
      cursor: "pointer",
      maxWidth: "100%",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    location_link: {
      color: theme.bodyTextColor,
      textDecoration: "underline",
      cursor: "pointer",
      maxWidth: "100%",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: "10px",
    },
  }),
);

interface Props {
  event: IEvent;
  use2ColumnLayout?: boolean;
}

export default function EventInfos(props: Props) {
  const [showExtendedDiscountInfo, setShowExtendedDiscountInfo] =
    useState(false);

  const [showAllAdditionalLocations, setShowAllAdditionalLocations] =
    useState(false);

  const { event, use2ColumnLayout } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();

  const gridItemSize = use2ColumnLayout ? 6 : 12;

  const { trackEvent } = useMatomo();

  return (
    <>
      {event.address && (
        <Grid container className={classes.infoBoxSection}>
          <Grid item xs={gridItemSize} className={classes.infoBoxHeader}>
            {intl.get("event.address")}
          </Grid>
          <Grid item xs={gridItemSize}>
            <a
              onClick={() => {
                trackEvent({
                  category: "Extern",
                  action: "Address",
                  name: `https://www.google.com/maps/search/?api=1&query=${event.address}`,
                });
              }}
              href={`https://www.google.com/maps/search/?api=1&query=${event.address}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}>
              {commaAddressLineSplit(event.address, 30, true).map(
                (addressLine, index) => {
                  return (
                    <div className={classes.link} key={"" + index}>
                      {addressLine.trim()}
                      <br />
                    </div>
                  );
                },
              )}
            </a>
          </Grid>
        </Grid>
      )}
      {event.location_independent && (
        <Grid container className={classes.infoBoxSection}>
          <Grid item xs={gridItemSize} className={classes.infoBoxHeader}>
            {intl.get("event.header_location_independent_locations")}
          </Grid>
          <Grid item xs={gridItemSize}>
            {event.additional_locations.map((value, location_index) => {
              return (
                <a
                  onClick={() => {
                    trackEvent({
                      category: "Extern",
                      action: "Address",
                      name: `https://www.google.com/maps/search/?api=1&query=${value.address}`,
                    });
                  }}
                  href={`https://www.google.com/maps/search/?api=1&query=${value.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={
                    location_index === event.additional_locations.length - 1
                      ? classes.link
                      : classes.location_link
                  }>
                  {commaAddressLineSplit(value.address, 30, true).map(
                    (addressLine, index) => {
                      return (
                        <div className={classes.link} key={"" + index}>
                          {addressLine.trim()}
                          <br />
                        </div>
                      );
                    },
                  )}
                </a>
              );
            })}
          </Grid>
        </Grid>
      )}
      {!event.location_independent && event.additional_locations.length > 0 && (
        <Grid
          container
          className={classes.infoBoxSection}
          style={{ paddingBottom: "14px" }}>
          {!showAllAdditionalLocations && (
            <Grid
              container
              direction={"row"}
              spacing={0}
              xs={gridItemSize}
              className={classes.infoBoxHeader}
              style={{ color: "#6E99FF" }}>
              <Grid item>
                <EventDistance />
              </Grid>
              <Grid
                item
                onClick={(event1) => setShowAllAdditionalLocations(true)}
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: 4,
                  cursor: "pointer",
                }}>
                {intl.get("event.header_additional_locations_expand", {
                  count: event.additional_locations.length,
                })}
              </Grid>
            </Grid>
          )}

          {showAllAdditionalLocations && (
            <Grid
              container
              direction={"row"}
              spacing={0}
              xs={gridItemSize}
              className={classes.infoBoxHeader}
              style={{ color: "#6E99FF" }}>
              <Grid item>
                <EventDistance />
              </Grid>
              <Grid
                item
                onClick={(event1) => setShowAllAdditionalLocations(false)}
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: 4,
                  cursor: "pointer",
                }}>
                {intl.get("event.header_additional_locations_collapse")}
              </Grid>
            </Grid>
          )}

          {showAllAdditionalLocations && (
            <>
              <Grid item xs={gridItemSize} className={classes.infoBoxHeader}>
                {intl.get("event.header_additional_locations")}
              </Grid>

              <Grid item xs={gridItemSize}>
                {event.additional_locations.map((value, location_index) => {
                  return (
                    <a
                      onClick={() => {
                        trackEvent({
                          category: "Extern",
                          action: "Address",
                          name: `https://www.google.com/maps/search/?api=1&query=${value.address}`,
                        });
                      }}
                      href={`https://www.google.com/maps/search/?api=1&query=${value.address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        location_index === event.additional_locations.length - 1
                          ? classes.link
                          : classes.location_link
                      }>
                      {commaAddressLineSplit(value.address, 30, true).map(
                        (addressLine, index) => {
                          return (
                            <div className={classes.link} key={"" + index}>
                              {addressLine.trim()}
                              <br />
                            </div>
                          );
                        },
                      )}
                    </a>
                  );
                })}
              </Grid>
            </>
          )}
        </Grid>
      )}

      <Grid container className={classes.infoBoxSection}>
        <Grid item xs={gridItemSize} className={classes.infoBoxHeader}>
          {event.type === EventType.Recurring
            ? intl.get("event.openingHours")
            : intl.get("event.dedicatedTimes")}
        </Grid>
        {event.type === EventType.Recurring && (
          <EventOpeningHours
            event={event}
            useShortDayNames={use2ColumnLayout}
          />
        )}
        {event.type === EventType.Dedicated && (
          <EventDedicatedTimes event={event} />
        )}
      </Grid>

      {Event.getPriceString(event) !== "" && (
        <Grid container className={classes.infoBoxSection}>
          <Grid item xs={gridItemSize} className={classes.infoBoxHeader}>
            {intl.get("event.pricing")}
          </Grid>
          {Event.getPriceString(event)}
          <br />
          {event.is_registration_necessary && (
            <Grid
              item
              xs={gridItemSize}
              className={classes.eventPreregistration}>
              {intl.get("event.preregistration_necessary")}
            </Grid>
          )}
        </Grid>
      )}
      {event.is_discount_active && (
        <Grid container className={classes.infoBoxSection}>
          <Grid
            container
            direction={"row"}
            spacing={1}
            xs={gridItemSize}
            className={classes.infoBoxHeader}
            style={{ color: "#6E99FF" }}>
            <Grid item>
              <EventDiscountCheckmarkLarge />
            </Grid>
            <Grid item>{intl.get("event.discount_available")}</Grid>
          </Grid>
          <Typography
            variant={"body2"}
            style={{ fontSize: theme.fontSizes.smallFont, marginTop: 4 }}
            dangerouslySetInnerHTML={{
              __html: intl.get(`event.discount_description_short`, {
                code: event.discount_description_short,
              }),
            }}
          />
          {event.discount_description_long &&
            event.discount_description_long.length > 0 && (
              <Grid item>
                <Typography
                  variant={"body2"}
                  style={{ fontSize: theme.fontSizes.smallFont, marginTop: 4 }}>
                  {showExtendedDiscountInfo
                    ? event.discount_description_long
                    : Event.getShortenedDiscountDescription(event)}
                </Typography>
                {Event.hasShortenedDiscountDescription(event) &&
                  !showExtendedDiscountInfo && (
                    <Typography
                      variant={"body2"}
                      style={{
                        fontSize: theme.fontSizes.smallFont,
                        marginTop: 4,
                        cursor: "pointer",
                      }}
                      onClick={(event1) => setShowExtendedDiscountInfo(true)}>
                      <u>
                        {intl.get(`event.discount_description_long_expand`)}
                      </u>
                    </Typography>
                  )}
              </Grid>
            )}
        </Grid>
      )}

      {event.booking_url != null && event.booking_url.length > 0 && (
        <Grid container className={classes.infoBoxSection}>
          <Grid item className={classes.infoBoxHeader} xs={gridItemSize}>
            {intl.get("event.booking")}
          </Grid>

          <Grid item style={{ marginTop: 6 }} xs={gridItemSize}>
            <a
              style={{
                color: "unset",
                textDecoration: "unset",
              }}
              href={createBookingUrlLink(
                event.booking_url,
                event.booking_url_type,
              )}
              onClick={(ev) => {
                ev.preventDefault();
                trackEvent({
                  category: "Extern",
                  action: "Booking",
                  name: createBookingUrlLink(
                    event.booking_url,
                    event.booking_url_type,
                  ),
                });
                const win = window.open(
                  createBookingUrlLink(
                    event.booking_url,
                    event.booking_url_type,
                  ),
                  getTargetForBookingUrl(event.booking_url_type),
                  "noopener,noreferrer",
                );
                if (win) {
                  win.focus();
                }
                dispatch(
                  TrackingAction.trackAction(
                    TrackingActionType.booked,
                    event.id,
                  ),
                );
              }}>
              <Button size={"small"}>{intl.get("event.book_me")}</Button>
            </a>
          </Grid>
        </Grid>
      )}

      <Grid container className={clsx(classes.infoBoxSection, "last")}>
        <Grid item className={classes.infoBoxHeader} xs={gridItemSize}>
          {intl.get("event.contact")}
        </Grid>

        <Grid item className={classes.infoBoxHeader} xs={gridItemSize}>
          <a
            href={sanitizeLinkUrl(event.website_url)}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
            onClick={() => {
              trackEvent({
                category: "Extern",
                action: "Website",
                name: sanitizeLinkUrl(event.website_url),
              });
              dispatch(
                TrackingAction.trackAction(TrackingActionType.booked, event.id),
              );
            }}>
            {event.website_url}
          </a>
          <br />
          <a
            href={`tel:${event.phone
              .replaceAll("/", "")
              .replaceAll("-", "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(" ", "")}`}
            className={classes.link}
            style={{ textDecoration: "none" }}
            onClick={() => {
              trackEvent({
                category: "Extern",
                action: "Phone",
                name: `tel:${event.phone
                  .replaceAll("/", "")
                  .replaceAll("-", "")
                  .replaceAll("(", "")
                  .replaceAll(")", "")
                  .replaceAll(" ", "")}`,
              });
              dispatch(
                TrackingAction.trackAction(TrackingActionType.booked, event.id),
              );
            }}>
            {event.phone}
          </a>
        </Grid>
      </Grid>
    </>
  );
}
