import * as React from "react";

interface FadeInProps {
  delay: number;
  transitionDuration: number;
}

class FadeIn extends React.Component<FadeInProps> {
  state = {
    maxIsVisible: 0,
  };

  interval: any;

  get delay() {
    return this.props.delay || 50;
  }

  get transitionDuration() {
    return this.props.transitionDuration || 500;
  }

  componentDidMount() {
    const count = React.Children.count(this.props.children);
    let i = 0;
    this.interval = setInterval(() => {
      i++;
      if (i > count) {
        clearInterval(this.interval);
      }

      this.setState({ maxIsVisible: i });
    }, this.delay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { transitionDuration } = this;
    return (
      <div>
        {React.Children.map(this.props.children, (child, i) => {
          return (
            <div
              style={{
                transition: `opacity ${transitionDuration}ms, top ${transitionDuration}ms`,
                opacity: this.state.maxIsVisible > i ? 1 : 0,
              }}>
              {child}
            </div>
          );
        })}
      </div>
    );
  }
}

export default FadeIn as React.ComponentType<any>;
