import {
  Container,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import LocationBar from "../components/LocationBar";
import React from "react";
import { useAppLocation } from "../hooks/useAppLocation";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import DecoratedTypography from "../components/DecoratedTypography";
import intl from "react-intl-universal";
import useBotDetection from "../hooks/useBotDetection";

interface Props {}

export default function LocationOnboardingDialog({}: Props) {
  const { hasHadValidLocation } = useAppLocation();
  const isBot = useBotDetection();

  const authenticated = useSelector((state: RootState) => {
    return state.authentication.authenticated;
  });

  return (
    <Dialog open={authenticated && !hasHadValidLocation && !isBot} fullWidth>
      <DialogContent>
        <Container>
          <DecoratedTypography
            text={intl.get("location_onboarding.title")}
            typographyVariant={"h2"}
            decoratorSize={"small"}
          />

          <Typography
            variant={"body2"}
            style={{ marginTop: 0, marginBottom: 16 }}>
            {intl.get("location_onboarding.message")}
          </Typography>
          <LocationBar
            hideTitle
            showLoadingProgress
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 0,
            }}
            key={"locationbar_onboarding"}
          />
        </Container>
      </DialogContent>
    </Dialog>
  );
}
