import createReducer from "./createReducer";
import { Action, ActionType, EventDetailState } from "../model/model";

export const eventDetailState = createReducer(
  { selectedEvent: null, loading: false },
  {
    [ActionType.SELECT_EVENT](state: EventDetailState, action: Action<any>) {
      if (action.payload == null) {
        return null;
      }
      return { ...state, selectedEvent: action.payload };
    },
    [ActionType.LOAD_EVENT](state: EventDetailState, action: Action<any>) {
      if (action.payload.result) {
        return {
          ...state,
          selectedEvent: action.payload.result,
          loading: false,
          failed: false,
          shouldRedirect: action.payload.shouldRedirect,
        };
      }
      if (action.payload.loading) {
        return { ...state, loading: true };
      }
      if (action.payload.failed) {
        return {
          ...state,
          selectedEvent: null,
          loading: false,
          failed: true,
          errorNotFound: action.payload.errorNotFound,
        };
      }
      return { ...state };
    },
    [ActionType.RESET_EVENTS](state: EventDetailState, action: Action<any>) {
      return {
        ...state,
        selectedEvent: undefined,
        loading: false,
        failed: false,
        errorNotFound: false,
        shouldRedirect: false,
      };
    },
  },
);
