import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const NavigationSearch = props => (
  <SvgIcon width={23.673} height={23.673} {...props}>
    <g
      transform="translate(-209.151 -15.358)"
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={3.5}>
      <circle
        data-name="Ellipse 234"
        cx={8.509}
        cy={8.509}
        r={8.509}
        transform="translate(210.901 17.108)"
      />
      <path
        data-name="Linie 27"
        strokeLinecap="round"
        d="M230.349 36.556l-4.862-4.862"
      />
    </g>
  </SvgIcon>
);

export default NavigationSearch;
