import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Theme, useTheme } from "@material-ui/core";
import { ColorSvg } from "./ColorSvg";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    text: {
      paddingLeft: 7,
      fontSize: theme.fontSizes.smallFont,
      color: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    icon: {
      width: 16,
      height: 16,
    },
  }),
);

interface TagProps {
  image: any;
  text: string;
  style?: any;
  className?: any;
  imageStyle?: any;
}

export default function EventTag(props: TagProps) {
  const { image, text, style, className, imageStyle } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={clsx(classes.root, className)} style={style}>
      <ColorSvg
        svg={image}
        className={classes.icon}
        color={theme.palette.primary.main}
        style={{ ...imageStyle }}
      />
      <div className={classes.text}>{text}</div>
    </div>
  );
}
