import createReducer from "./createReducer";
import { Action, ActionType, SearchState } from "../model/model";

export const searchState = createReducer(new SearchState(), {
  [ActionType.SEARCH_EVENTS](state: SearchState, action: Action<any>) {
    if (action.payload.result) {
      return {
        ...state,
        events: action.payload.result,
        loading: false,
        failed: false,
        loaded: true,
        error: null,
      };
    }
    if (action.payload.loading) {
      return {
        ...state,
        loading: true,
        events: [],
        loaded: false,
      };
    }
    if (action.payload.failed) {
      return {
        ...state,
        loading: false,
        failed: true,
        loaded: false,
        error: action.payload.error,
      };
    }
    return { ...state };
  },
});
