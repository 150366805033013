import createReducer from "./createReducer";
import { Action, ActionType, UserLocationState } from "../model/model";

const persistAndReturn = (data: any) => {
  localStorage.setItem("appLocation_v2", JSON.stringify(data));
  return data;
};

export const userLocationState = createReducer(new UserLocationState(), {
  [ActionType.SET_GEOLOCATION](
    state: UserLocationState,
    action: Action<UserLocationState>,
  ) {
    return persistAndReturn({
      ...state,
      selectedType: action.payload.selectedType || state.selectedType,
      geoLocation: { ...state.geoLocation, ...action.payload.geoLocation },
    });
  },
  [ActionType.UPDATE_GEOLOCATION](
    state: UserLocationState,
    action: Action<UserLocationState>,
  ) {
    return persistAndReturn({
      ...state,
      geoLocation: { ...state.geoLocation, ...action.payload },
    });
  },
  [ActionType.SET_MANUAL_LOCATION](
    state: UserLocationState,
    action: Action<UserLocationState>,
  ) {
    return persistAndReturn({
      ...state,
      selectedType: action.payload.selectedType || state.selectedType,
      manualLocation: {
        ...state.manualLocation,
        ...action.payload.manualLocation,
      },
    });
  },
});
