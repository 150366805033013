import axios from "axios";
import * as AuthActions from "./actions/auth";
import { AuthenticationHelper } from "./utils/authenticationHelper";
import { UserLocationState } from "./model/model";

export default {
  setupAxiosInterceptors: (
    getLocationState: () => UserLocationState,
    history: any,
    authActions: typeof AuthActions,
  ) => {
    axios.interceptors.request.use(
      function (config) {
        const locationState = getLocationState();

        if (config.params?.withLocation) {
          if (locationState?.selectedType) {
            config.params = {
              ...config.params,
              latitude:
                locationState.selectedType === "geolocation"
                  ? locationState.geoLocation.latitude
                  : locationState.manualLocation.latitude,
              longitude:
                locationState.selectedType === "geolocation"
                  ? locationState.geoLocation.longitude
                  : locationState.manualLocation.longitude,
            };
          }
          delete config.params.withLocation;
        }

        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        if (error.response.status === 401) {
          AuthenticationHelper.redirectToAutoAuthentication();
        }

        return Promise.reject(error);
      },
    );
  },
};
