import { Dispatch } from "redux";
import { ActionType } from "../model/model";

export const trackAction =
  (actionType: string, eventId: string) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.TRACK_ACTION,
      payload: { actionType, eventId },
    });
  };
