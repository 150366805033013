import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import intl from "react-intl-universal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flexGrow: 1,
    justifyContent: "center",
  },
  progress: {},
  message: {
    width: "100vw",
    textAlign: "center",
    marginTop: 18,
    color: theme.bodyTextColor,
  },
}));

interface Props {
  message?: string;
  size?: "standard" | "small";
}

export default function LoadingView(props: Props) {
  const { message, size } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        className={classes.progress}
        size={size == "small" ? 25 : undefined}
      />
      {message && (
        <Typography variant={"subtitle2"} className={classes.message}>
          {message || intl.get("loading.message")}
        </Typography>
      )}
    </div>
  );
}
