import { IEvent } from "../../model/event";
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useTheme,
} from "@material-ui/core";
import { useLocale } from "../../hooks/useLocale";
import React from "react";
import { format, parseISO } from "date-fns";
const moment = require("moment");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dedicatedTimeCell: {
      textAlign: "left",
      borderBottom: 0,
      padding: 0,
      minWidth: 30,
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: theme.fontSizes.smallFont,
    },
    dedicatedTimeLabelCell: {
      textAlign: "left",
      borderBottom: 0,
      padding: 0,
      minWidth: 30,
      paddingTop: 2,
      paddingBottom: 2,
      fontSize: theme.fontSizes.smallFont,
    },
  }),
);

interface Props {
  event: IEvent;
}
export default function EventDedicatedTimes(props: Props) {
  const { event } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const locale = useLocale();

  const futuredDedicatedTimes = event.dedicated_times
    .filter(
      (value) =>
        moment(value.start_date).isAfter(moment()) ||
        moment(value.end_date).isAfter(moment()),
    )
    .sort((a, b) => {
      const dateA = moment(a.start_date);
      const dateB = moment(b.start_date);
      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });

  const dedicatedTimeRows = futuredDedicatedTimes.map((value) => {
    return (
      <TableRow>
        <TableCell className={classes.dedicatedTimeCell}>
          {format(parseISO(value.start_date), "d. MMMM yyyy", {
            locale: locale.dateFns,
          })}
        </TableCell>
        <TableCell className={classes.dedicatedTimeCell}>
          {format(parseISO(value.start_date), "HH:mm", {
            locale: locale.dateFns,
          }) +
            " - " +
            format(parseISO(value.end_date), "HH:mm", {
              locale: locale.dateFns,
            }).replace("00:00", "24:00")}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Grid item xs>
      <Table>
        <TableBody>{dedicatedTimeRows}</TableBody>
      </Table>
    </Grid>
  );
}
