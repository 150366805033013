import { ThemeOptions } from "@material-ui/core";

export interface BcThemeOptions {
  colors: {
    primary: string;
    primaryDark: string;
    primaryLight: string;
    primaryLightContrast: string;
    primaryContrast: string;
    bodyText: string;
    indicatorProfile?: string;
    indicatorFavorite?: string;
    browserThemeColor?: string;
    topBarBackground?: string;
    topBarContrast?: string;
    background: string;
    secondaryBackground: string;
    heroBackground?: string;
    heroText?: string;
  };
  styles: {
    headerStyle: "bluecherries" | "centerBanner";
    authPageStyle: "default" | "flat";
    toolbarStyle?: "default" | "elevated";
    logoStyle?: "default" | "negative";
  };
  images: {
    banner: string;
    landing: string;
  };
  misc: {
    originUrl: string;
    landingPageIconHeight: number;
    topBarIconHeight: number;
    externalPrivacyLink?: string;
    externalImprintLink?: string;
    externalPartnerName?: string;
  };
}

export function createBcThemeOptions(options: BcThemeOptions): ThemeOptions {
  return {
    headerStyle: options.styles.headerStyle,
    authpageStyle: options.styles.authPageStyle,
    toolbarStyle: options.styles.toolbarStyle || "default",
    logoStyle: options.styles.logoStyle || "default",
    externalPrivacyLink: options.misc.externalPrivacyLink ?? "",
    externalImprintLink: options.misc.externalImprintLink ?? "",
    externalPartnerName: options.misc.externalPartnerName ?? "",
    browserThemeColor:
      options.colors.browserThemeColor || options.colors.primary,
    bodyTextColor: options.colors.bodyText,
    banner: {
      default: { maxWidth: 0 },
      md: { maxWidth: 0 },
      sm: { maxWidth: 0 },
      xs: { maxWidth: 0 },
      backgroundColor: "#fff",
    },
    palette: {
      primary: {
        main: options.colors.primary,
        contrastText: options.colors.primaryContrast,
        dark: options.colors.primaryDark,
        light: options.colors.primaryLight,
      },
      background: {
        default: options.colors.background,
      },
      secondary: {
        main: "#f2f2f2",
        dark: "#3c3c3b",
        light: options.colors.secondaryBackground || "#f6f6f6",
        contrastText: "#3c3c3b",
      },
      error: {
        main: "rgb(229,52,33)",
      },
    },
    overrides: {
      MuiTab: {
        textColorPrimary: {
          color: "#ababab",
        },
      },
    },
    linkButton: "#c4c4c3",
    typography: {
      fontFamily: "Manrope",
    },
    fontFamily: "Manrope",
    headerFontFamily: "Manrope",
    headerFontWeight: "bold",
    importantBodyFontWeight: "normal",
    fontSizes: {
      // base font size: 16px
      hugeFont: "2.5rem", // 40px
      veryLargeFont: "1.875rem", // 30px
      largeFont: "1.375rem", // 22px
      biggerFont: "1.125rem", // 18px
      mediumFont: "1rem", // 16px
      smallFont: "0.875rem", // 14px
      verySmallFont: "0.75rem", // 12px
    },
    indicators: {
      profile: options.colors.indicatorProfile || "#FFCEE0",
      favorite: options.colors.indicatorFavorite || "#FFCEE0",
    },
    maxWidthLarge: 1366,
    maxWidth: 1366,
    separator: "1px solid rgb(226,227,228)",
    separatorDark: "1px solid rgb(166,167,168)",
    sectionHeader: {
      color: "#3C3C3B",
    },
    originUrl: options.misc.originUrl,
    topBar: {
      backgroundColor:
        options.colors.topBarBackground || options.colors.primary,
      contrastText:
        options.colors.topBarContrast || options.colors.primaryContrast,
      icon: options.images.banner,
      iconHeight: options.misc.topBarIconHeight,
    },
    bottomBar: {
      backgroundColor: options.colors.primaryLight,
      contrastText: options.colors.primaryLightContrast,
    },
    bottomNavigation: {
      backgroundColor: "#f2f2f2",
    },
    carousel: {
      itemSpacing: 16,
      imageSize: 280,
      horizontalContentPadding: 55,
      heroBackground: options.colors.secondaryBackground,
    },
    eventPage: {
      horizontalContentPadding: 55,
    },
    searchPopOver: {
      width: 375,
      background: "#f6f6f6",
    },
    profileChildContainer: {
      background: "#6fceec20",
    },
    authPages: {
      background: options.colors.secondaryBackground,
      cardBackground:
        options.styles.authPageStyle === "flat"
          ? options.colors.secondaryBackground
          : "#fff",
    },
    landingPage: {
      titleColor: "rgb(115,115, 115)",
      topImage: options.images.landing,
      iconHeight: options.misc.landingPageIconHeight,
      imageWidth: "100%",
      maxImageWidth: 450,
    },
    heroEvent: {
      background: options.colors.heroBackground || options.colors.primaryLight,
      text: options.colors.heroText || options.colors.primaryLightContrast,
    },
  };
}
