import { combineReducers } from "redux";
import * as authReducer from "./auth";
import * as eventReducer from "./event";
import * as categoryReducer from "./category";
import * as favoritesReducer from "./favorites";
import * as seoReducer from "./seo";
import * as searchReducer from "./search";
import * as userLocationReducer from "./userlocation";
import * as analyticsReducer from "./analytics";
import {
  ActionType,
  Authentication,
  EditUserState,
  EventDetailState,
  EventState,
  CategoryState,
  UserLocationState,
  SearchState,
  SeoState,
  FavoritesState,
  AnalyticsState,
} from "../model/model";
import * as eventDetail from "./eventDetail";
import * as profileState from "./editUser";

export interface RootState {
  authentication: Authentication;
  eventState: EventState;
  categoryState: CategoryState;
  favoritesState: FavoritesState;
  seoState: SeoState;
  searchState: SearchState;
  userLocationState: UserLocationState;
  eventDetailState: EventDetailState;
  editUserState: EditUserState;
  analyticsState: AnalyticsState;
}

// @ts-ignore
const appReducer = combineReducers<RootState>({
  ...authReducer,
  ...eventReducer,
  ...categoryReducer,
  ...favoritesReducer,
  ...seoReducer,
  ...searchReducer,
  ...eventDetail,
  ...profileState,
  ...userLocationReducer,
  ...analyticsReducer,
});

export default (state: any, action: any) => {
  if (action.type === ActionType.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};
